import styles from "./styles.module.scss";
import { observer } from "mobx-react";
import PublicLayout from "layouts/public/public";
import React, { useEffect, useState } from "react";
import Logo from "components/base/logo/logo";
import InputPassword from "components/base/input-password/input-password";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { generatePath, useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { passwordConfirmationValidator, passwordValidator } from "helpers/validator.helper";
import { useQuery } from "hooks/useQuery";
import Alert from "components/base/alert/alert";
import { useAppStore } from "store";
import { Routes } from "routes";
import { ICheckInvitationCodeModel } from "models/dto/ZoomiLxp/Models/Invitation/ICheckInvitationCodeModel";
import { IConfirmInvitationRequestModel } from "models/dto/ZoomiLxp/Models/Invitation/IConfirmInvitationRequestModel";
import Button from "components/base/button/button";
import { showLoginConfirmationModal } from "../login/login_popup";
import { showErrorMessage, showSuccessMessage } from "helpers/error.handling.helper";
import { passwordRequirements } from "constants/messages";

interface FormValues {
	password: string;
	passwordConfirmation: string;
}

const InvitationPage: React.FC = observer(() => {
	const { accountStore, commonStore } = useAppStore();
	const query = useQuery();
	const history = useHistory();
	const invitationCourseId = query.get("course");
	const [showPassword, setShowPassword] = useState(false);

	if (invitationCourseId && invitationCourseId.length) {
		accountStore.inviteCourseId = invitationCourseId;
	}

	useEffect(() => {
		const data: ICheckInvitationCodeModel = {
			code: query.get("code") ?? "",
			email: query.get("email") ?? "",
			needPassword: true,
		};

		accountStore
			.checkInvitationCode(data)
			.then((result) => {
				const isValid = result.isValid;
				if (!isValid && !accountStore.isAuthenticated) {
					setTimeout(() => history.push(Routes.Root), 1500);
					showErrorMessage(commonStore.alertEventBus, "The link is invalid. The link is valid only for 24 hours.");
				}
				setShowPassword(result.needSetPassword);
			})
			.catch((e) => {
				showErrorMessage(commonStore.alertEventBus, e);
			});
	}, [accountStore, history, commonStore.alertEventBus, query]);

	const SignupSchema = Yup.object().shape({
		password: passwordValidator,
		passwordConfirmation: passwordConfirmationValidator,
	});

	const initialValues: FormValues = {
		password: "",
		passwordConfirmation: "",
	};

	const getRedirectPath = () => {
		if (accountStore.is2FAEnabled) {
			return Routes.Check2FA;
		}
		if (invitationCourseId && invitationCourseId.length) {
			return generatePath(Routes.CoursePage, { id: invitationCourseId });
		}
		return Routes.Home;
	};

	const handleSubmit = async (values: FormValues) => {
		try {
			const data: IConfirmInvitationRequestModel = {
				code: query.get("code") ?? "",
				email: query.get("email") ?? "",
				// @ts-ignore
				password: Boolean(values?.password) ? values.password : null,
				// @ts-ignore
				passwordConfirmation: Boolean(values?.passwordConfirmation) ? values.passwordConfirmation : null,
			};

			const confirmationAction = async () =>
				await accountStore
					.signIn({
						username: data.email,
						password: data.password,
						rememberMe: true,
						isPPAccepted: true,
						isTermsAccepted: true,
					})
					.then(() => {
						setTimeout(() => history.push(getRedirectPath()), 700);
					});

			await accountStore
				.confirmInvitation(data)
				.then(() => {
					showSuccessMessage(commonStore.alertEventBus, "Registration was successful.");
				})
				.then(() => {
					showLoginConfirmationModal(confirmationAction, () =>
						history.push(generatePath(Routes.Login, { guid: undefined }))
					);
				});
		} catch (err: any) {
			showErrorMessage(commonStore.alertEventBus, err);
		}
	};

	return (
		<PublicLayout>
			<Alert eventBus={commonStore.alertEventBus} />
			<section className={styles.invitation}>
				<div className={styles.invitation__logo_box}>
					<Logo />
				</div>
				<Formik
					initialValues={initialValues}
					validationSchema={showPassword ? SignupSchema : null}
					onSubmit={handleSubmit}
					validateOnChange={false}
				>
					{({ values, validateForm, setFieldValue, errors, setErrors }) => (
						<Form>
							{showPassword && (
								<div className={styles.invitation__fields_wrap}>
									<div className={styles.invitation__title}>Please set password</div>
									<InputPassword
										label="Password"
										className={styles.invitation__field_inner}
										name="password"
										value={values.password}
										onChange={(e) => setFieldValue("password", e.target.value)}
										isError={Boolean(errors["password"])}
										errorText={errors["password"]}
									/>
									<div className={styles.invitation__password_text}>{passwordRequirements}</div>
									<InputPassword
										label="Confirm Password"
										className={styles.invitation__field_inner_two}
										name="passwordConfirmation"
										value={values.passwordConfirmation}
										onChange={(e) => setFieldValue("passwordConfirmation", e.target.value)}
										isError={Boolean(errors["passwordConfirmation"])}
										errorText={errors["passwordConfirmation"]}
									/>
								</div>
							)}
							<Button
								onClick={async (e) => {
									e.preventDefault();
									const errors = await validateForm();
									isEmpty(errors) ? handleSubmit(values) : setErrors(errors);
								}}
								className={styles.invitation__btn_sign_in}
								label="Accept Invitation"
							/>
						</Form>
					)}
				</Formik>
			</section>
		</PublicLayout>
	);
});

export default InvitationPage;
