import styles from "./styles.module.scss";
import classNames from "classnames";
import InputField from "components/base/input-field/input-field";
import Button from "components/base/button/button";
import AdvSearchCourseFilters from "../components/adv-search-filter/adv-search-course-filters";
import { useAppStore } from "store";
import AdvSearchInfluencerFilters from "../components/adv-search-filter/adv-search-influencer-filters";
import AdvSearchThreadFilters from "../components/adv-search-filter/adv-search-thread-filters";
import { showErrorMessage } from "helpers/error.handling.helper";
import update from "immutability-helper";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { SearchType } from "pages/private/adv-search-page/data";
import { useGetData } from "hooks/useGetData";
import { getSearchInitParams } from "helpers/filter.helper";

interface AdvSearchFormProps {
	searchType: SearchType;
	className?: string;
}

const cx = classNames.bind(styles);

const AdvSearchForm = (props: AdvSearchFormProps) => {
	const { searchType, className } = props;
	const { searchStore, commonStore } = useAppStore();
	const { getData } = useGetData(searchType);

	useEffect(() => {
		searchStore.params = getSearchInitParams();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSearch = async () => {
		try {
			await searchStore.getAdvSearchInitData(getData);
		} catch (err: unknown) {
			showErrorMessage(commonStore.alertEventBus, err);
		}
	};

	const getFilters = () => {
		if (searchType === SearchType.CoursesSearch) return <AdvSearchCourseFilters />;
		else if (searchType === SearchType.InfluensersSearch) return <AdvSearchInfluencerFilters />;
		else if (searchType === SearchType.ThreadsSearch) return <AdvSearchThreadFilters />;
		return <></>;
	};

	return (
		<div className={cx(styles.adv_search_form, className)}>
			<div className={styles.adv_search_form__row}>
				<InputField
					inputType="text"
					label="Search"
					className={styles.adv_search_form__search_field}
					name="search"
					value={searchStore.params?.queryString ?? ""}
					onKeyDown={async (event: React.KeyboardEvent) => {
						if (event.key === "Enter") {
							event.preventDefault();
							await handleSearch();
						}
					}}
					onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) =>
						(searchStore.params = update(searchStore.params, { queryString: { $set: e.target.value ?? "" } }))
					}
				/>
				<Button label="Search" onClick={handleSearch} className={styles.adv_search_form__search_btn} />
			</div>
			{getFilters()}
		</div>
	);
};

export default observer(AdvSearchForm);
