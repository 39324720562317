import styles from "../styles.module.scss";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import { useMemo, useState } from "react";
import isNil from "lodash/isNil";
import { IChapterRef } from "models/dto/ZoomiLxp/Models/Courses/IChapterRef";
import ChapterTile from "components/partial/chapters/chapter-tile/chapter-tile";
import { getThumbnail } from "helpers/image.helper";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "routes";
import { ICourseItemModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseItemModel";
import classNames from "classnames";
import { mobileCheck, tabletCheck } from "helpers/device.helper";
import { ReactComponent as CheckIcon } from "assets/icons/ic_check_round.svg";
export interface IChapterItemModel extends IChapterRef {
	completed?: boolean;
}

export const MyDeskChaptersSection = (props: { courseId: number }) => {
	const { coursesStore } = useAppStore();
	const history = useHistory();
	const { courseId } = props;
	const currentChapterId = coursesStore.curActiveContent.chapter ? coursesStore.curActiveContent.chapter.id : 0;
	const [previewOptions, setPreviewOptions] = useState<{ chapterId: number; isShowPreview: boolean }>({
		chapterId: currentChapterId,
		isShowPreview: false,
	});
	const isMobile = useMemo(() => mobileCheck(), []);
	const isTablet = useMemo(() => tabletCheck, []);
	const isAdaptive = isMobile || isTablet;

	const goToChapter = (chapterId: number) => {
		const chapterItems: ICourseItemModel[] = coursesStore.activeCourseItems.filter((item: ICourseItemModel) =>
			chapterId ? item.chapter?.id === chapterId : !item.chapter
		);
		const firstItem = chapterItems[0];

		if (firstItem) {
			setPreviewOptions({
				chapterId: chapterId,
				isShowPreview: false,
			});

			history.push(
				generatePath(Routes.MyDeskContent, {
					courseId: isNaN(courseId) || isNil(courseId) ? coursesStore.currentCourse.id : courseId,
					courseItemId: firstItem?.courseItemId,
				})
			);
		}
	};

	const getChaptersPreviewList = () => {
		return coursesStore.activeChaptersGroup?.map((chapter: IChapterItemModel) => {
			const showTile = previewOptions.isShowPreview && previewOptions.chapterId === chapter.id;
			const isOtherChapter = currentChapterId !== chapter.id;
			return (
				<div key={chapter.id}>
					<div className={styles.chapters_list__chapter_wrapper}>
						<img
							className={classNames(styles.chapters_list__chapter_preview_image, {
								[styles.chapters_list__inactive_chapter]: isOtherChapter,
								[styles.chapters_list__completed_chapter]: chapter.completed,
							})}
							src={getThumbnail(chapter.thumbnail)}
							alt="Chapter preview"
							onMouseEnter={() =>
								!isAdaptive &&
								setPreviewOptions({
									chapterId: chapter.id,
									isShowPreview: true,
								})
							}
							onMouseLeave={() =>
								setPreviewOptions({
									chapterId: chapter.id,
									isShowPreview: false,
								})
							}
							onClick={() => isOtherChapter && goToChapter(chapter.id)}
						/>
						{chapter.completed && <CheckIcon className={styles.chapters_list__check_icon} />}
					</div>
					{showTile && (
						<ChapterTile
							chapter={chapter}
							key={chapter.id}
							onClick={(id) => console.log(id)}
							isActive={true}
							count={coursesStore.activeChaptersItems?.[chapter.id === 0 ? "null" : chapter.id]?.length ?? 0}
							className={styles.chapters_list__chapter_tile}
						/>
					)}
				</div>
			);
		});
	};

	return !isNil(coursesStore.activeChaptersGroup) && coursesStore.activeChaptersGroup?.length > 1 ? (
		<div className={styles.chapters_list__chapters_section}>{getChaptersPreviewList()}</div>
	) : (
		<></>
	);
};

export default observer(MyDeskChaptersSection);
