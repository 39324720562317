import { observer } from "mobx-react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import LoginPage from "./login/login";
import { Routes } from "routes";
import RegisterPage from "./register/register";
import ResetPassword from "./resetpassword/resetpassword";
import NewPasswordPage from "./newpassword/newpassword";
//@ts-ignore
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import InvitationPage from "./invitation/invitation";
import { isRouteExists } from "helpers/navigation.helper";
import ModalDialog from "components/base/modal-dialog/modal-dialog";
import Wiki from "./wiki/wiki";
import InvalidTenantPage from "./invalid-tenant/invalid-tenant";
import TwoFactorAuthForm from "./2fa-form/2fa-form";

export const PublicView = observer(function PublicView() {
	const history = useHistory<{ redirectUrl: string }>();

	return (
		<>
			<ModalDialog />
			<Switch>
				<Route exact path={Routes.InvalidUrl} component={InvalidTenantPage} />
				<Route exact path={Routes.Login} component={LoginPage} />
				<Redirect exact path={Routes.ConfirmEmail} to={Routes.Login} />
				<Route exact path={Routes.Register} component={RegisterPage} />
				<Route exact path={Routes.ResetPassword} component={ResetPassword} />
				<Route exact path={Routes.CreateNewPassword} component={NewPasswordPage} />
				<Route exact path={Routes.Invitation} component={InvitationPage} />
				<Route exact path={Routes.LinkedIn} component={LinkedInPopUp} />
				<Route exact path={Routes.WikiPage} component={Wiki} />
				<Route exact path={Routes.Check2FA} component={TwoFactorAuthForm} />
				{isRouteExists(history.location.pathname) ? (
					<Redirect
						to={{
							pathname: Routes.Login,
							state: {
								redirectUrl: history.location ? `${history.location?.pathname}${history.location?.search}` : "",
							},
						}}
					/>
				) : (
					<Redirect to={Routes.Login} />
				)}
			</Switch>
		</>
	);
});
