import { useEffect, useState } from "react";
import ButtonSubmenu, { ISubmenuItem } from "components/partial/button-submenu/button-submenu";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import isEmpty from "lodash/isEmpty";
import { useBulkOperationsModal } from "hooks/useBulkOperationsModal";
import Alert, { createAlertEventBus } from "components/base/alert/alert";
import { PermissionConstantsCloning } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsCloning";
import { PermissionConstantsUpdate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsUpdate";
import { BulkOperationType } from "models/dto/ZoomiLxp/Utilities/Enumerations/BulkOperationType";

interface SubmenuBoxProps {
	menuType: "courses" | "users" | "threads";
	className?: string;
}

const BulkOperationsMenu = ({ className, menuType }: SubmenuBoxProps) => {
	const { bulkOperationsStore, usersStore } = useAppStore();
	const [alertEventBus] = useState(() => createAlertEventBus());
	const { openBulkPopup } = useBulkOperationsModal(alertEventBus);

	useEffect(() => {
		const result = !isEmpty(bulkOperationsStore.checkedState);
		if (result !== bulkOperationsStore.isShowBulkOperation) {
			bulkOperationsStore.isShowBulkOperation = result;
		}
	}, [bulkOperationsStore, bulkOperationsStore.checkedState]);

	const getMenuItems = (): ISubmenuItem[] => {
		if (menuType === "courses") {
			return [
				{
					label: "Assign Users",
					onClick: () => {
						bulkOperationsStore.bulkOperationType = BulkOperationType.CourseAssignment;
						openBulkPopup("Assign Users");
					},
					isDisabled: !usersStore.checkOneOfUserPermissions([
						PermissionConstantsUpdate.ManageAssignCoursesForAllUsers,
						PermissionConstantsUpdate.ManageAssignCoursesOwnCompanyUsers,
					]),
				},
				{
					label: "Unassign Users",
					onClick: () => {
						bulkOperationsStore.bulkOperationType = BulkOperationType.CourseUnAssignment;
						openBulkPopup("Unassign Users");
					},
					isDisabled: !usersStore.checkOneOfUserPermissions([
						PermissionConstantsUpdate.ManageAssignCoursesForAllUsers,
						PermissionConstantsUpdate.ManageAssignCoursesOwnCompanyUsers,
					]),
				},
				{
					label: "Assign Groups",
					onClick: () => {
						bulkOperationsStore.bulkOperationType = BulkOperationType.BulkCourseTagAssignment;
						openBulkPopup("Assign Groups To Courses");
					},
					isDisabled: !usersStore.checkOneOfUserPermissions([PermissionConstantsUpdate.ManageGroups]),
				},
				{
					label: "Unassign Groups",
					onClick: () => {
						bulkOperationsStore.bulkOperationType = BulkOperationType.BulkCourseTagUnAssignment;
						openBulkPopup("Unassign Groups From Courses");
					},
					isDisabled: !usersStore.checkOneOfUserPermissions([PermissionConstantsUpdate.ManageGroups]),
				},
				{
					label: "Clone Courses",
					onClick: () => {
						bulkOperationsStore.bulkOperationType = BulkOperationType.CourseCloning;
						openBulkPopup("Cloning Courses");
					},
					isDisabled: !usersStore.checkOneOfUserPermissions([PermissionConstantsCloning.OwnCoursesInsideTenant]),
				},
			];
		}
		if (menuType === "users") {
			return [
				{
					label: "Assign Groups",
					onClick: () => {
						bulkOperationsStore.bulkOperationType = BulkOperationType.BulkGroupAssignment;
						openBulkPopup("Assign Groups To Users");
					},
					isDisabled: !usersStore.checkOneOfUserPermissions([PermissionConstantsUpdate.ManageGroups]),
				},
				{
					label: "Unassign Groups",
					onClick: () => {
						bulkOperationsStore.bulkOperationType = BulkOperationType.BulkGroupUnAssignment;
						openBulkPopup("Unassign Groups From Users");
					},
					isDisabled: !usersStore.checkOneOfUserPermissions([PermissionConstantsUpdate.ManageGroups]),
				},
				{
					label: "Clone Users",
					onClick: () => {
						bulkOperationsStore.bulkOperationType = BulkOperationType.UserCloning;
						openBulkPopup("Cloning users");
					},
					isDisabled: !usersStore.checkOneOfUserPermissions([PermissionConstantsCloning.UsersBetweenTenants]),
				},
			];
		}
		if (menuType === "threads") {
			return [
				{
					label: "Assign Groups",
					onClick: () => {
						bulkOperationsStore.bulkOperationType = BulkOperationType.BulkThreadTagAssignment;
						openBulkPopup("Assign Groups To Threads");
					},
					isDisabled: !usersStore.checkOneOfUserPermissions([PermissionConstantsUpdate.ManageGroups]),
				},
				{
					label: "Unassign Groups",
					onClick: () => {
						bulkOperationsStore.bulkOperationType = BulkOperationType.BulkThreadTagUnAssignment;
						openBulkPopup("Unassign Groups From Threads");
					},
					isDisabled: !usersStore.checkOneOfUserPermissions([PermissionConstantsUpdate.ManageGroups]),
				},
			];
		}
		return [];
	};

	return (
		<div className={className}>
			<Alert eventBus={alertEventBus} />
			<ButtonSubmenu title={"Bulk actions"} items={getMenuItems()} isShow={bulkOperationsStore.isShowBulkOperation} />
		</div>
	);
};

export default observer(BulkOperationsMenu);
