import { useEffect, useState } from "react";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import styles from "./styles.module.scss";
import FilterMenu from "components/partial/filters/filter-menu/filter-menu";
import SelectFilter from "components/partial/filters/select-filter/select-filter";
import DateFilter from "components/partial/filters/date-filter/date-filter";
import StatusFilter from "components/partial/filters/status-filter/status-filter";
import SearchFilter from "components/partial/search-filter/search-filter";
import {
	getAuthorsOptions,
	getCategoryOptions,
	getSources,
	getSubjectOptions,
	IOptionsItem,
} from "helpers/select.helper";
import isEmpty from "lodash/isEmpty";
import { showErrorMessage } from "helpers/error.handling.helper";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import { getInitParams } from "helpers/filter.helper";
import { useSearchStore } from "hooks/useSearchStore";
import classNames from "classnames";
import Alert from "components/base/alert/alert";
import { SearchStateConstants } from "constants/constants";

interface ICoursesFiltersOptions {
	categories?: IOptionsItem[];
	subjects?: IOptionsItem[];
	sources?: IOptionsItem[];
	authors?: IOptionsItem[];
}

interface IFiltersGroupProps {
	className?: string;
}

const cx = classNames.bind(styles);

const CoursesFiltersGroup = (props: IFiltersGroupProps) => {
	const { className } = props;
	const { coursesStore, commonStore } = useAppStore();
	const [filtersOptions, setFilterOptions] = useState<ICoursesFiltersOptions>({} as ICoursesFiltersOptions);

	const initParams: IQueryParams = getInitParams();

	const { setParams } = useSearchStore(initParams, SearchStateConstants.courses);

	useEffect(() => {
		Promise.allSettled([
			coursesStore.getCategories(),
			coursesStore.getSubjects(),
			coursesStore.getCreators(),
			coursesStore.getSources(),
		]).then((response) => {
			setFilterOptions((prevState) => ({
				...prevState,
				categories: response[0].status === "fulfilled" ? getCategoryOptions(response[0].value) : [],
				subjects: response[1].status === "fulfilled" ? getSubjectOptions(response[1].value) : [],
				authors: response[2].status === "fulfilled" ? getAuthorsOptions(response[2].value) : [],
				sources: response[3].status === "fulfilled" ? getSources(response[3].value) : [],
			}));
			const errors = response
				.filter((result) => result.status === "rejected")
				.map((result) => ("reason" in result ? result.reason : null));
			if (!isEmpty(errors)) showErrorMessage(commonStore.alertEventBus, errors);

			return coursesStore.clearStore();
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={cx(styles.courses_filters_group, className)}>
			<Alert eventBus={commonStore.alertEventBus} />
			<FilterMenu params={coursesStore.coursesSearchParams} setParams={setParams} initParams={initParams}>
				<SelectFilter
					params={coursesStore.coursesSearchParams}
					setParams={setParams}
					label="Creator"
					options={filtersOptions.authors ?? []}
					propertyName="CreatorId"
				/>
				<SelectFilter
					params={coursesStore.coursesSearchParams}
					setParams={setParams}
					label="Category"
					options={filtersOptions.categories ?? []}
					propertyName="CategoryId"
				/>
				<SelectFilter
					params={coursesStore.coursesSearchParams}
					setParams={setParams}
					label="Subject"
					options={filtersOptions.subjects ?? []}
					propertyName="SubjectId"
				/>
				<SelectFilter
					params={coursesStore.coursesSearchParams}
					setParams={setParams}
					label="Source"
					options={filtersOptions.sources ?? []}
					propertyName="Source"
					isStringOptions={true}
				/>
				<DateFilter params={coursesStore.coursesSearchParams} setParams={setParams} />
				<StatusFilter params={coursesStore.coursesSearchParams} setParams={setParams} />
			</FilterMenu>
			<SearchFilter
				params={coursesStore.coursesSearchParams}
				setParams={setParams}
				alertEventBus={commonStore.alertEventBus}
				classNameInput={styles.courses_filters_group__search}
			/>
		</div>
	);
};
export default observer(CoursesFiltersGroup);
