import React from "react";
import styles from "./styles.module.scss";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import PublicLayout from "layouts/public/public";
import Logo from "components/base/logo/logo";
import InputField from "components/base/input-field/input-field";
import { Form, Formik } from "formik";
import { Link, generatePath, useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import Alert from "components/base/alert/alert";
import { showErrorMessage } from "helpers/error.handling.helper";
import Button from "components/base/button/button";
import { Routes } from "routes";
import { validationCodeCheckSchema } from "helpers/validator.helper";

const ANDROID_AUTH_APP_LINK = "https://play.google.com/store/apps/details?id=com.azure.authenticator";
const IOS_AUTH_APP_LINK = "https://apps.apple.com/ru/app/microsoft-authenticator/id983156458";

interface I2FAFormProps {
	authCode: string;
}

const TwoFactorAuthForm: React.FC = observer(() => {
	const { accountStore, commonStore } = useAppStore();
	const isSetup2FA = accountStore.is2FAEnabled && Boolean(accountStore.setup2FACode?.length);
	const history = useHistory();

	const initialValues: I2FAFormProps = {
		authCode: "",
	};

	const getSetupCode = (): string | null => {
		if (accountStore.setup2FACode && accountStore.setup2FACode?.length) {
			return accountStore.setup2FACode.replace(/(?<=^(?:.{4})+)(?!$)/g, " - ");
		}
		return "";
	};

	const redirectHandler = () => {
		if (accountStore.inviteCourseId && accountStore.inviteCourseId.length) {
			setTimeout(() => history.push(generatePath(Routes.CoursePage, { id: accountStore.inviteCourseId })), 2000);
		}
	};

	const handleSubmit = async (values: I2FAFormProps) => {
		accountStore.userAuthCode = Number(values.authCode);
		try {
			if (isSetup2FA) {
				accountStore.setup2FA(Number(values.authCode)).then(() => redirectHandler());
			} else {
				accountStore.check2FA(Number(values.authCode)).then(() => redirectHandler());
			}
		} catch (err) {
			showErrorMessage(commonStore.alertEventBus, err);
		}
	};

	return (
		<PublicLayout>
			<Alert eventBus={commonStore.alertEventBus} />
			<section className={styles.two_factor_auth_form}>
				<div className={styles.two_factor_auth_form__logo_box}>
					<Logo />
				</div>
				{isSetup2FA && (
					<>
						<h2>Enable 2FA</h2>
						<div>
							Download Microsoft authenticatior <Link to={{ pathname: ANDROID_AUTH_APP_LINK }}>Android</Link> or{" "}
							<Link to={{ pathname: IOS_AUTH_APP_LINK }}>IOS</Link> <br /> and enter following code in authentication
							app or scan QR code below
						</div>
						<section className={styles.two_factor_auth_form__2fa_codes_section}>
							<b>{getSetupCode()}</b>
							<img
								className={styles.two_factor_auth_form__qr_code}
								src={`data:image/png;base64,${accountStore.setup2FAQR}`}
								alt="auth setup qr preview"
							/>
						</section>
					</>
				)}

				<Formik
					initialValues={initialValues}
					onSubmit={handleSubmit}
					validateOnChange={false}
					validationSchema={validationCodeCheckSchema}
				>
					{({ values, validateForm, setFieldValue, errors, setErrors }) => (
						<Form className={styles.two_factor_auth_form__auth_code_container}>
							<h2>Enter authorization code</h2>
							<InputField
								label="6-digits code"
								name="authCode"
								value={values.authCode}
								onChange={(e) => setFieldValue("authCode", e.target.value)}
								isError={Boolean(errors.authCode)}
								errorText={errors.authCode}
								autocomplete="one-time-code"
								maxLength={6}
								inputMode="numeric"
							/>

							<Button
								onClick={async (e) => {
									e.preventDefault();
									const errors = await validateForm();
									isEmpty(errors) ? handleSubmit(values) : setErrors(errors);
								}}
								label="Submit"
								className={styles.two_factor_auth_form__button}
							/>
						</Form>
					)}
				</Formik>
			</section>
		</PublicLayout>
	);
});

export default TwoFactorAuthForm;
