import React, { useCallback, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { useAppStore } from "store";
import { Column, usePagination, useTable } from "react-table";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import CmsTable, { CmsTileProps } from "components/partial/cms/cms-table/cms-table";
import { observer } from "mobx-react";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import InfiniteScroll from "react-infinite-scroll-component";
import { Routes } from "routes";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { showErrorMessage } from "helpers/error.handling.helper";
import { uniqueId } from "lodash";
import { Props } from "react-select";

export type ICmsSpreadsheetType = any;
interface ICmsSpreadsheetProps<T extends object> extends Props<T> {
	tableColumns: Column<T>[];
	tileComponent: React.FunctionComponent<CmsTileProps<T>>;
	profileRoute?: Routes;
	basePathOption?: object;
	fetchData: () => Promise<IGetRecordsResponse<T> | undefined>;
	className?: string;
}

const CmsSpreadsheet = ({
	tableColumns,
	tileComponent,
	fetchData,
	className,
	profileRoute,
	basePathOption,
}: ICmsSpreadsheetProps<ICmsSpreadsheetType>) => {
	const { searchStore, commonStore, bulkOperationsStore, usersStore } = useAppStore();
	const calledOnce = useRef(false);
	const getKey = () => uniqueId("id-");

	const table = useTable(
		{
			columns: tableColumns,
			data: searchStore.items,
			initialState: { pageIndex: 0 },
			manualPagination: true,
		},
		usePagination
	);
	const getData = useCallback(async () => await fetchData(), [fetchData]);

	const fetchMoreData = useCallback(async () => {
		if (!calledOnce.current) {
			calledOnce.current = true;
			await searchStore.fetchMoreData(getData);
			calledOnce.current = false;
		}
	}, [getData, searchStore]);

	useEffect(() => {
		if (bulkOperationsStore.isShowSelection) {
			bulkOperationsStore.getIds();
		}
	}, [searchStore.items, bulkOperationsStore]);

	useEffect(() => {
		if (!calledOnce.current) {
			calledOnce.current = true;
			searchStore
				.getTableInitialData(getData)
				.then(() => (calledOnce.current = false))
				.catch((err) => showErrorMessage(commonStore.alertEventBus, err));
		}
	}, [commonStore.alertEventBus, getData, searchStore, searchStore.params, usersStore]);

	useEffect(() => {
		return () => {
			bulkOperationsStore.clearData();
			searchStore.clearResults();
		};
	}, [bulkOperationsStore, searchStore]);

	return (
		<div className={classNames(styles.cms_spreadsheet, className)}>
			<LoadingIndicator
				loading={searchStore.isLoadingSearchData}
				spinnerPosition={"fixed"}
				className={styles.cms_spreadsheet__loading}
				backgroundStyle={"none"}
			>
				{!isEmpty(searchStore.items) ? (
					<InfiniteScroll
						dataLength={searchStore.items?.length ?? 0}
						next={fetchMoreData}
						hasMore={searchStore.hasMore}
						loader={<h4>Loading...</h4>}
					>
						<CmsTable
							table={table}
							data={searchStore.items}
							getKey={getKey}
							className={styles.cms_spreadsheet__table}
							tileComponent={tileComponent}
							ids={bulkOperationsStore.checkedState}
							route={profileRoute}
							basePathOption={basePathOption}
						/>
						<div className={styles.cms_spreadsheet__footer} />
					</InfiniteScroll>
				) : (
					!searchStore.isLoadingSearchData && (
						<div className={styles.cms_spreadsheet__no_data}>There is no data yet</div>
					)
				)}
			</LoadingIndicator>
		</div>
	);
};

export default observer(CmsSpreadsheet);
