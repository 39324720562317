import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { useAppStore } from "store";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import classNames from "classnames";
import { ReactComponent as RemoveIcon } from "assets/icons/ic_close.svg";
import Button from "components/base/button/button";
import Tooltip from "components/base/tooltip/tooltip";
import { ReactComponent as InfoIcon } from "assets/icons/ic_info.svg";
import { ICourseAssignmentModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseAssignmentModel";
import Avatar from "components/base/avatar/avatar";
import Checkbox from "components/base/checkbox/checkbox";
import { IAssignCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IAssignCourseModel";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import { getAvatarTitleFromDisplayName } from "helpers/string.helper";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import InfiniteScroll from "react-infinite-scroll-component";
import { getImage } from "helpers/image.helper";
import findIndex from "lodash/findIndex";
import update from "immutability-helper";
import { emptyQueryParams } from "helpers/params.helper";
import CheckBoxInfo from "./course-card-info";
import SearchFilter from "components/partial/search-filter/search-filter";
import Alert from "components/base/alert/alert";
import { showErrorMessage } from "helpers/error.handling.helper";

interface CourseCardAssignmentsProps {
	courseId: number;
	assignments: ICourseAssignmentModel[];
	onClose: () => void;
	className?: string;
}

const CourseCardAssignments = ({ className, onClose, assignments, courseId }: CourseCardAssignmentsProps) => {
	const { coursesStore, usersStore, commonStore } = useAppStore();
	const initParams = { ...emptyQueryParams, take: 50 };
	const mutableParamsRef = useRef<IQueryParams>(initParams);
	const [params, setParams] = useState<IQueryParams>(initParams);

	//This logic to combine params for infinite scroll and input search, because its different types used for api call.
	useEffect(() => {
		const getSkip = () => {
			if ((params.queryString?.length && mutableParamsRef.current.skip) || !params.queryString?.length) {
				mutableParamsRef.current.skip = 0;
			}
			return mutableParamsRef.current.skip;
		};
		mutableParamsRef.current = { ...mutableParamsRef.current, skip: getSkip(), queryString: params.queryString };
	}, [params.queryString, mutableParamsRef]);

	const getData = useCallback(async () => {
		if (courseId) {
			const response = await coursesStore.getCourseAssignmentsData(courseId, {
				...mutableParamsRef.current,
				queryString: params.queryString,
			});

			return response;
		}

		return null;
	}, [courseId, coursesStore, mutableParamsRef, params]);

	const { items, hasMore, fetchMoreData, setItems } = useInfiniteScroll<ICourseAssignmentModel>(
		getData,
		mutableParamsRef,
		commonStore.alertEventBus
	);

	const handleChange = (assignment: ICourseAssignmentModel, isAssigned: boolean) => {
		if (assignment.isPaid || assignment.isAssignedOutOfScope || assignment.isB2BPaid) return;
		const accountId = assignment.accountId;
		const index = findIndex(items, { accountId });
		if (index !== -1) {
			const newItems = update(items, { [index]: { isApproved: { $set: isAssigned } } });
			setItems(newItems);
		}

		const assignData: IAssignCourseModel = {
			courseId,
			accountId,
			isAssigned,
		};
		const currentUserId = usersStore.currentUserInfo?.id;

		coursesStore
			.assignCourse(assignData)
			.then(() => {
				if (currentUserId === accountId) {
					return coursesStore.getCourseById(courseId);
				}
			})
			.catch((err) => showErrorMessage(commonStore.alertEventBus, err));
	};

	return (
		<div className={classNames(className, styles.course_card_assignments)}>
			<Alert eventBus={commonStore.alertEventBus} />
			{!isEmpty(assignments) ? (
				<div className={styles.course_card_assignments__box}>
					<div className={styles.course_card_assignments__header}>
						<div className={styles.course_card_assignments__label}>
							Assignments
							<Tooltip label={<CheckBoxInfo />} className={styles.course_card_assignments__info_tooltip}>
								<InfoIcon className={styles.course_card_assignments__info_icon} />
							</Tooltip>
							<SearchFilter
								params={mutableParamsRef.current}
								alertEventBus={commonStore.alertEventBus}
								setParams={setParams}
							/>
						</div>
						<Button
							label=""
							onClick={onClose}
							type="text"
							icon={<RemoveIcon className={styles.course_card_assignments__button_icon} />}
							className={styles.course_card_assignments__remove_btn}
						/>
					</div>
					<LoadingIndicator
						loading={coursesStore.isLoading}
						spinnerPosition="center"
						backgroundTheme="dark"
						backgroundStyle="blur"
					>
						<div className={styles.course_card_assignments__body} id="scrollableDiv">
							{!isEmpty(items) ? (
								<InfiniteScroll
									className={styles.inf_scroll}
									dataLength={items.length}
									next={fetchMoreData}
									hasMore={hasMore}
									loader={coursesStore.isLoading ? <h4>Loading...</h4> : <></>}
									scrollableTarget="scrollableDiv"
									endMessage={<h4 className={styles.inf_scroll__end_of_list_message}>End of list</h4>}
								>
									<div className={styles.course_card_assignments__grid}>
										{items.map((assignment: ICourseAssignmentModel) => (
											<div className={styles.course_card_assignments__assignment} key={assignment.accountId}>
												<Tooltip
													label={
														assignment.isB2BPaid
															? "Current user has 'Paid' status in B2B sales"
															: assignment.isAssignedOutOfScope
															? "Assigned by other Manager"
															: "Purchased by user"
													}
													isActive={assignment.isB2BPaid || assignment.isPaid || assignment.isAssignedOutOfScope}
												>
													<Checkbox
														value={assignment.isApproved || assignment.isPaid}
														onChange={(value) => handleChange(assignment, value)}
														theme={
															assignment.isPaid || assignment.isAssignedOutOfScope || assignment.isB2BPaid
																? "locked"
																: "assignments"
														}
														className={classNames(styles.course_card_assignments__checkbox, {
															[styles.course_card_assignments__checkbox_requested]:
																assignment.isRequested || assignment.isApproved,
															[styles.course_card_assignments__checkbox_payed]:
																assignment.isPaid || assignment.isAssignedOutOfScope || assignment.isB2BPaid,
														})}
													/>
												</Tooltip>
												<Avatar
													title={getAvatarTitleFromDisplayName(assignment.displayName)}
													image={getImage(assignment, true)}
													className={styles.course_card_assignments__avatar}
													size={"little"}
												/>
												<span className={styles.course_card_assignments__assignment_name}>
													{assignment?.displayName ?? "-"}
												</span>
											</div>
										))}
									</div>
								</InfiniteScroll>
							) : (
								<div className={styles.course_card_assignments__no_data}>Nothing on your request</div>
							)}
						</div>
					</LoadingIndicator>
				</div>
			) : (
				<div className={styles.course_card_assignments__no_data}>Assignments not found</div>
			)}
		</div>
	);
};

export default observer(CourseCardAssignments);
