import { SettingsApi } from "api/controllers/SettingsApi";
import { CarouselDataType } from "components/partial/carousels-array/carousel-data-provider";
import { getUserFullName } from "helpers/string.helper";
import { Dictionary, isEmpty } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { IInfluencerDataModel } from "models/dto/ZoomiLxp/Models/Influencer/IInfluencerDataModel";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { AppStore } from "store";
import { getBase64DataString } from "helpers/file-download.helper";
import { setColorTheme } from "helpers/settings.helper";
import { ICertificateSettings } from "models/dto/ZoomiLxp/Models/Certificates/ICertificateSettings";

export interface ICarouselData {
	isLoading: boolean;
	isEmpty: boolean;
	labelPrefix?: string;
	label: string;
	getData: (params: IPagingParams) => Promise<any>;
	carouselPagingParams: IGetRecordsResponse<CarouselDataType>;
	courseItems?: IPreviewCourseModel[];
	threadsItems?: IThreadModel[];
	influencersItems?: IInfluencerDataModel[];
	isDarkTheme?: boolean;
	carouselKey: string;
}

export class SettingsStore {
	private rootStore;
	private _isLoading: boolean = false;
	private _colorScheme: Dictionary<string> = {};
	private _privateSettings: Dictionary<Dictionary<string>> = {};
	private _publicSettings: Dictionary<Dictionary<string>> = {
		Auth: {},
		Images: {},
		Pages: {},
		Strings: {},
		Colors: {},
		General: {},
	};
	private _logoUrl: string = "";
	private _currentTenantId: number = 0;

	constructor(rootStore: AppStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this);
	}

	async getPrivateSettings() {
		this.isLoading = true;
		try {
			const response = await SettingsApi.getPrivateSettings();
			runInAction(() => {
				this._privateSettings = response.data.data;
			});
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getPublicSettings() {
		this.isLoading = true;
		try {
			const response = await SettingsApi.getPublicSettings();
			const settings = response.data.data;
			runInAction(() => {
				this.publicSettings = settings;
				this.logoUrl = settings.Images[SiteSettingsKeys.Logo];
				this._currentTenantId = Number(settings.Strings[SiteSettingsKeys.StringsCurrentTenantId]);
				this._colorScheme = settings.Colors;

				const pagesSettings = settings.Pages;
				window.sessionStorage.setItem(
					"settingsStore",
					JSON.stringify({
						batchSize: Number(pagesSettings?.[SiteSettingsKeys.MyDeskSendEventsBatchSize]),
						sendInterval: Number(pagesSettings?.[SiteSettingsKeys.MyDeskSendEventsInterval]) * 1000,
					})
				);
			});
			setColorTheme(settings.Colors);
			return settings;
		} finally {
			this.isLoading = false;
		}
	}

	async saveSettings(settingsData: Dictionary<string>) {
		this.isLoading = true;
		try {
			const response = await SettingsApi.saveSettings(settingsData);
			return response.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getCertificatePreview(data: ICertificateSettings) {
		this.isLoading = true;
		try {
			const response = await SettingsApi.getCertificatePreview(data);
			const base64ImageString = getBase64DataString(response.data);
			return base64ImageString;
		} finally {
			this.isLoading = false;
		}
	}

	get privateSettings() {
		return this._privateSettings;
	}

	set publicSettings(publicSettings: Dictionary<Dictionary<string>>) {
		this._publicSettings = publicSettings;
	}

	get publicSettings() {
		return this._publicSettings;
	}

	get colorScheme() {
		return this._colorScheme;
	}

	get currentTenantId() {
		return this._currentTenantId;
	}

	set logoUrl(logoUrl: string) {
		this._logoUrl = logoUrl;
	}

	get logoUrl() {
		return this._logoUrl;
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	get isLoading() {
		return this._isLoading;
	}

	getCarouselObject = (userId: number, carouselKey: string): ICarouselData => {
		const myDeskCurrentCourseId = this.rootStore.coursesStore.currentCourse?.id;
		const DEFAULT_CAROUSEL_LABEL_PREFIX = "My learning plan";
		const mostWatchedCarousel = {
			isLoading: this.rootStore.coursesStore.loadingGroup.mostWatchedCourses,
			isEmpty: isEmpty(this.rootStore.coursesStore.mostWatched),
			label: this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.HomeCarouselsNameMostWatched],
			labelPrefix: "Data driven",
			courseItems: this.rootStore.coursesStore.mostWatched,
			getData: (params: IPagingParams) => this.rootStore.coursesStore.getMostWatchedCourses(params),
			carouselPagingParams: this.rootStore.coursesStore.coursesCarouselsPagingParams.mostWatchedCourses,
			carouselKey: SiteSettingsKeys.HomeCarouselsNameMostWatched,
		};

		const assignedToYouCarousel = {
			isLoading: this.rootStore.coursesStore.loadingGroup.speciallyForYou,
			isEmpty: isEmpty(this.rootStore.coursesStore.currentAssignedCourses.records),
			label: this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.HomeCarouselsNameAssignedToYou],
			labelPrefix: DEFAULT_CAROUSEL_LABEL_PREFIX,
			courseItems: this.rootStore.coursesStore.currentAssignedCourses.records,
			getData: (params: IPagingParams) => this.rootStore.coursesStore.getAssignedCourses(userId, params),
			carouselPagingParams: this.rootStore.coursesStore.coursesCarouselsPagingParams.assignedCourses,
			carouselKey: SiteSettingsKeys.HomeCarouselsNameAssignedToYou,
		};

		const meetYourGoalsCarousel = {
			isLoading: this.rootStore.coursesStore.loadingGroup.meetGoals,
			isEmpty: isEmpty(this.rootStore.coursesStore.meetGoals),
			label: this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.HomeCarouselsNameMeetYourGoals],
			labelPrefix: DEFAULT_CAROUSEL_LABEL_PREFIX,
			courseItems: this.rootStore.coursesStore.meetGoals,
			getData: (params: IPagingParams) => this.rootStore.coursesStore.meetYourGoals(params),
			carouselPagingParams: this.rootStore.coursesStore.coursesCarouselsPagingParams.meetGoals,
			carouselKey: SiteSettingsKeys.HomeCarouselsNameMeetYourGoals,
		};

		switch (carouselKey) {
			case SiteSettingsKeys.HomeCarouselsOrderAssignedToYou:
				return assignedToYouCarousel;
			case SiteSettingsKeys.HomeCarouselsOrderMeetYourGoals:
				return meetYourGoalsCarousel;
			case SiteSettingsKeys.HomeCarouselsOrderCoursesCuratedForYou:
				return {
					isLoading: this.rootStore.coursesStore.loadingGroup.speciallyForYou,
					isEmpty: isEmpty(this.rootStore.coursesStore.speciallyForYou),
					label:
						this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.HomeCarouselsNameCoursesCuratedForYou],
					labelPrefix: DEFAULT_CAROUSEL_LABEL_PREFIX,
					courseItems: this.rootStore.coursesStore.speciallyForYou,
					getData: (params: IPagingParams) => this.rootStore.coursesStore.getRecommendedCourses(params),
					carouselPagingParams: this.rootStore.coursesStore.coursesCarouselsPagingParams.recommendedCourses,
					carouselKey: SiteSettingsKeys.HomeCarouselsNameCoursesCuratedForYou,
				};
			case SiteSettingsKeys.HomeCarouselsOrderRequiredSkillsFor:
				return {
					isLoading: this.rootStore.usersStore.isLoading,
					isEmpty: isEmpty(this.rootStore.usersStore.coursesForJobTitle),
					label: `${
						this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.HomeCarouselsNameRequiredSkillsFor]
					} ${this.rootStore.usersStore!.currentUserInfo!.jobTitle}`,
					labelPrefix: DEFAULT_CAROUSEL_LABEL_PREFIX,
					courseItems: this.rootStore.usersStore.coursesForJobTitle,
					getData: (params: IPagingParams) => this.rootStore.coursesStore.getCoursesForJobTitle(params),
					carouselPagingParams: this.rootStore.coursesStore.coursesCarouselsPagingParams.coursesForJobTitle,
					carouselKey: SiteSettingsKeys.HomeCarouselsNameRequiredSkillsFor,
				};
			case SiteSettingsKeys.HomeCarouselsOrderPurchasedCourses:
				return {
					isLoading: this.rootStore.coursesStore.loadingGroup.purchasedCurses,
					isEmpty: isEmpty(this.rootStore.coursesStore.purchasedCourses),
					label: this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.HomeCarouselsNamePurchasedCourses],
					labelPrefix: DEFAULT_CAROUSEL_LABEL_PREFIX,
					courseItems: this.rootStore.coursesStore.purchasedCourses,
					getData: (params: IPagingParams) => this.rootStore.coursesStore.getPurchasedCurses(params),
					carouselPagingParams: this.rootStore.coursesStore.coursesCarouselsPagingParams.purchasedCourses,
					carouselKey: SiteSettingsKeys.HomeCarouselsNamePurchasedCourses,
				};
			case SiteSettingsKeys.HomeCarouselsOrderPlaylist:
				return {
					isLoading: this.rootStore.coursesStore.loadingGroup.bookmarkedCourses,
					isEmpty: isEmpty(this.rootStore.coursesStore.playlist),
					label: `${getUserFullName(
						this.rootStore.usersStore!.currentUserInfo!.firstName,
						this.rootStore.usersStore!.currentUserInfo!.lastName
					)}'s
					 ${this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.HomeCarouselsNamePlaylist]}`,
					labelPrefix: "Social engine",
					courseItems: this.rootStore.coursesStore.playlist,
					isDarkTheme: true,
					getData: (params: IPagingParams) => this.rootStore.coursesStore.getBookmarkedCourses(params),
					carouselPagingParams: this.rootStore.coursesStore.coursesCarouselsPagingParams.bookmarkedCourses,
					carouselKey: SiteSettingsKeys.HomeCarouselsNamePlaylist,
				};
			case SiteSettingsKeys.HomeCarouselsOrderMostWatched:
				return mostWatchedCarousel;
			case SiteSettingsKeys.HomeCarouselsOrderMostLiked:
				return {
					isLoading: this.rootStore.coursesStore.loadingGroup.mostLikedCourses,
					isEmpty: isEmpty(this.rootStore.coursesStore.mostLiked),
					label: this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.HomeCarouselsNameMostLiked],
					labelPrefix: "Data driven",
					courseItems: this.rootStore.coursesStore.mostLiked,
					getData: (params: IPagingParams) => this.rootStore.coursesStore.getMostLikedCourses(params),
					carouselPagingParams: this.rootStore.coursesStore.coursesCarouselsPagingParams.mostLikedCourses,
					carouselKey: SiteSettingsKeys.HomeCarouselsNameMostLiked,
				};
			case SiteSettingsKeys.HomeCarouselsOrderHighestRated:
				return {
					isLoading: this.rootStore.coursesStore.loadingGroup.topRatedCourses,
					isEmpty: isEmpty(this.rootStore.coursesStore.mostRated),
					label: this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.HomeCarouselsNameHighestRated],
					labelPrefix: "Data driven",
					courseItems: this.rootStore.coursesStore.mostRated,
					getData: (params: IPagingParams) => this.rootStore.coursesStore.getTopRatedCourses(params),
					carouselPagingParams: this.rootStore.coursesStore.coursesCarouselsPagingParams.topRatedCourses,
					carouselKey: SiteSettingsKeys.HomeCarouselsNameHighestRated,
				};
			case SiteSettingsKeys.HomeCarouselsOrderTrendingCourses:
				return {
					isLoading: this.rootStore.coursesStore.loadingGroup.trendingCourses,
					isEmpty: isEmpty(this.rootStore.coursesStore.trendingCourses),
					label: this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.HomeCarouselsNameTrendingCourses],
					courseItems: this.rootStore.coursesStore.trendingCourses,
					getData: (params: IPagingParams) => this.rootStore.coursesStore.getTrendingCourses(params),
					carouselPagingParams: this.rootStore.coursesStore.coursesCarouselsPagingParams.trendingCourses,
					carouselKey: SiteSettingsKeys.HomeCarouselsNameTrendingCourses,
				};
			case SiteSettingsKeys.PeersCarouselsOrderTrendingThreads:
				return {
					isLoading: this.rootStore.peersStore.loadingGroup.trendingThreads,
					isEmpty: isEmpty(this.rootStore.peersStore.trending),
					label: this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.PeersCarouselsNameTrendingThreads],
					threadsItems: this.rootStore.peersStore.trending,
					getData: (params: IPagingParams) => this.rootStore.peersStore.getTrendingThreads(params),
					carouselPagingParams: this.rootStore.peersStore.peersCarouselsPagingParams.trendingThreads,
					carouselKey: SiteSettingsKeys.PeersCarouselsNameTrendingThreads,
				};
			case SiteSettingsKeys.PeersCarouselsOrderThreadsCreatedByYou:
				return {
					isLoading: this.rootStore.peersStore.loadingGroup.createdByYouThreads,
					isEmpty: isEmpty(this.rootStore.peersStore.createdByYou),
					label:
						this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.PeersCarouselsNameThreadsCreatedByYou],
					threadsItems: this.rootStore.peersStore.createdByYou,
					getData: (params: IPagingParams) => this.rootStore.peersStore.getCreatedByYouThreads(params),
					carouselPagingParams: this.rootStore.peersStore.peersCarouselsPagingParams.createdByYouThreads,
					carouselKey: SiteSettingsKeys.PeersCarouselsNameThreadsCreatedByYou,
				};
			case SiteSettingsKeys.PeersCarouselsOrderThreadsCuratedForYou:
				return {
					isLoading: this.rootStore.peersStore.loadingGroup.recommendedThreads,
					isEmpty: isEmpty(this.rootStore.peersStore.curatedForYou),
					label:
						this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.PeersCarouselsNameThreadsCuratedForYou],
					threadsItems: this.rootStore.peersStore.curatedForYou,
					getData: (params: IPagingParams) => this.rootStore.peersStore.getRecommendedThreads(params),
					carouselPagingParams: this.rootStore.peersStore.peersCarouselsPagingParams.recommendedThreads,
					carouselKey: SiteSettingsKeys.PeersCarouselsNameThreadsCuratedForYou,
				};
			case SiteSettingsKeys.PeersCarouselsOrderLastSavedThreads:
				return {
					isLoading: this.rootStore.peersStore.loadingGroup.lastSavedThreads,
					isEmpty: isEmpty(this.rootStore.peersStore.lastSaved),
					label: this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.PeersCarouselsNameLastSavedThreads],
					threadsItems: this.rootStore.peersStore.lastSaved,
					isDarkTheme: true,
					getData: (params: IPagingParams) => this.rootStore.peersStore.getLastSavedThreads(params),
					carouselPagingParams: this.rootStore.peersStore.peersCarouselsPagingParams.lastSavedThreads,
					carouselKey: SiteSettingsKeys.PeersCarouselsNameLastSavedThreads,
				};
			case SiteSettingsKeys.InfluencersRecommendationsCarouselsOrderPeopleYouShouldBeFollowing:
				return {
					isLoading: this.rootStore.influencersStore.loadingGroup.shouldBeFollow,
					isEmpty: isEmpty(this.rootStore.influencersStore.shouldBeFollow),
					label:
						this.rootStore.settingsStore.publicSettings.Pages[
							SiteSettingsKeys.InfluencersRecommendationsCarouselsNamePeopleYouShouldBeFollowing
						],
					influencersItems: this.rootStore.influencersStore.shouldBeFollow,
					getData: (params: IPagingParams) => this.rootStore.influencersStore.getRecommendedInfluencers(params),
					carouselPagingParams: this.rootStore.influencersStore.influencersCarouselsPagingParams.shouldBeFollow,
					carouselKey: SiteSettingsKeys.InfluencersRecommendationsCarouselsNamePeopleYouShouldBeFollowing,
				};
			case SiteSettingsKeys.InfluencersRecommendationsCarouselsOrderMostLikedInfluencers:
				return {
					isLoading: this.rootStore.influencersStore.loadingGroup.mostLiked,
					isEmpty: isEmpty(this.rootStore.influencersStore.mostLiked),
					label:
						this.rootStore.settingsStore.publicSettings.Pages[
							SiteSettingsKeys.InfluencersRecommendationsCarouselsNameMostLikedInfluencers
						],
					labelPrefix: "Data driven",
					influencersItems: this.rootStore.influencersStore.mostLiked,
					getData: (params: IPagingParams) => this.rootStore.influencersStore.getMostLikedInfluencers(params),
					carouselPagingParams: this.rootStore.influencersStore.influencersCarouselsPagingParams.mostLiked,
					carouselKey: SiteSettingsKeys.InfluencersRecommendationsCarouselsNameMostLikedInfluencers,
				};
			case SiteSettingsKeys.InfluencersRecommendationsCarouselsOrderMostFollowedInfluencers:
				return {
					isLoading: this.rootStore.influencersStore.loadingGroup.mostFollowed,
					isEmpty: isEmpty(this.rootStore.influencersStore.mostFollowed),
					label:
						this.rootStore.settingsStore.publicSettings.Pages[
							SiteSettingsKeys.InfluencersRecommendationsCarouselsNameMostFollowedInfluencers
						],
					labelPrefix: "Data driven",
					influencersItems: this.rootStore.influencersStore.mostFollowed,
					getData: (params: IPagingParams) => this.rootStore.influencersStore.getMostFollowedInfluencers(params),
					carouselPagingParams: this.rootStore.influencersStore.influencersCarouselsPagingParams.mostFollowed,
					carouselKey: SiteSettingsKeys.InfluencersRecommendationsCarouselsNameMostFollowedInfluencers,
				};
			case SiteSettingsKeys.InfluencersRecommendationsCarouselsOrderMoversAndShakersOfLastWeek:
				return {
					isLoading: this.rootStore.influencersStore.loadingGroup.moversAndShakers,
					isEmpty: isEmpty(this.rootStore.influencersStore.moversAndShakers),
					label:
						this.rootStore.settingsStore.publicSettings.Pages[
							SiteSettingsKeys.InfluencersRecommendationsCarouselsNameMoversAndShakersOfLastWeek
						],
					labelPrefix: "Data driven",
					influencersItems: this.rootStore.influencersStore.moversAndShakers,
					getData: (params: IPagingParams) => this.rootStore.influencersStore.getMoverShakersOfLastWeek(params),
					carouselPagingParams: this.rootStore.influencersStore.influencersCarouselsPagingParams.moversAndShakers,
					carouselKey: SiteSettingsKeys.InfluencersRecommendationsCarouselsNameMoversAndShakersOfLastWeek,
				};
			case SiteSettingsKeys.InfluencersRecommendationsCarouselsOrderLastFollowedInfluencers:
				return {
					isLoading: this.rootStore.influencersStore.loadingGroup.myInfluencers,
					isEmpty: isEmpty(this.rootStore.influencersStore.myInfluencers),
					label:
						this.rootStore.settingsStore.publicSettings.Pages[
							SiteSettingsKeys.InfluencersRecommendationsCarouselsNameLastFollowedInfluencers
						],
					labelPrefix: "Social engine",
					influencersItems: this.rootStore.influencersStore.myInfluencers,
					isDarkTheme: true,
					getData: (params: IPagingParams) => this.rootStore.influencersStore.getFollowingInfluencers(params),
					carouselPagingParams: this.rootStore.influencersStore.influencersCarouselsPagingParams.myInfluencers,
					carouselKey: SiteSettingsKeys.InfluencersRecommendationsCarouselsNameLastFollowedInfluencers,
				};
			case SiteSettingsKeys.HistoryCarouselsOrderFollowingInfluencers:
				return {
					isLoading: this.rootStore.historyStore.loadingGroup.followingInfluencers,
					isEmpty: isEmpty(this.rootStore.historyStore.followingInfluencers),
					label:
						this.rootStore.settingsStore.publicSettings.Pages[
							SiteSettingsKeys.HistoryCarouselsNameFollowingInfluencers
						],
					influencersItems: this.rootStore.historyStore.followingInfluencers,
					getData: (params: IPagingParams) => this.rootStore.historyStore.getFollowingInfluencers(params),
					carouselPagingParams: this.rootStore.historyStore.historyCarouselsPagingParams.followingInfluencers,
					carouselKey: SiteSettingsKeys.HistoryCarouselsNameFollowingInfluencers,
				};
			case SiteSettingsKeys.HistoryCarouselsOrderWatchingHistory:
				return {
					isLoading: this.rootStore.historyStore.loadingGroup.watchingHistory,
					isEmpty: isEmpty(this.rootStore.historyStore.watchingHistory),
					label:
						this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.HistoryCarouselsNameWatchingHistory],
					courseItems: this.rootStore.historyStore.watchingHistory,
					getData: (params: IPagingParams) => this.rootStore.historyStore.getGetWatchingHistory(params),
					carouselPagingParams: this.rootStore.historyStore.historyCarouselsPagingParams.watchingHistory,
					carouselKey: SiteSettingsKeys.HistoryCarouselsNameWatchingHistory,
				};
			case SiteSettingsKeys.HistoryCarouselsOrderContinueWatching:
				return {
					isLoading: this.rootStore.historyStore.loadingGroup.continueWatching,
					isEmpty: isEmpty(this.rootStore.historyStore.continueWatching),
					label:
						this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.HistoryCarouselsNameContinueWatching],
					courseItems: this.rootStore.historyStore.continueWatching,
					getData: (params: IPagingParams) => this.rootStore.historyStore.getContinueWatching(params),
					carouselPagingParams: this.rootStore.historyStore.historyCarouselsPagingParams.continueWatching,
					carouselKey: SiteSettingsKeys.HistoryCarouselsNameContinueWatching,
				};
			case SiteSettingsKeys.HistoryCarouselsOrderBookmarkedCourses:
				return {
					isLoading: this.rootStore.historyStore.loadingGroup.bookmarkedCourses,
					isEmpty: isEmpty(this.rootStore.historyStore.bookmarkedCourses),
					label:
						this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.HistoryCarouselsNameBookmarkedCourses],
					courseItems: this.rootStore.historyStore.bookmarkedCourses,
					getData: (params: IPagingParams) => this.rootStore.historyStore.getBookmarkedCourses(params),
					carouselPagingParams: this.rootStore.historyStore.historyCarouselsPagingParams.bookmarkedCourses,
					carouselKey: SiteSettingsKeys.HistoryCarouselsNameBookmarkedCourses,
				};
			case SiteSettingsKeys.HistoryCarouselsOrderCoursesCreatedByYou:
				return {
					isLoading: this.rootStore.historyStore.loadingGroup.coursesCreatedByYou,
					isEmpty: isEmpty(this.rootStore.historyStore.coursesCreatedByYou),
					label:
						this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.HistoryCarouselsNameCoursesCreatedByYou],
					courseItems: this.rootStore.historyStore.coursesCreatedByYou,
					getData: (params: IPagingParams) => this.rootStore.historyStore.getCoursesCreatedByYou(params),
					carouselPagingParams: this.rootStore.historyStore.historyCarouselsPagingParams.coursesCreatedByYou,
					carouselKey: SiteSettingsKeys.HistoryCarouselsNameCoursesCreatedByYou,
				};
			case SiteSettingsKeys.HistoryCarouselsOrderThreadsCreatedByYou:
				return {
					isLoading: this.rootStore.historyStore.loadingGroup.myCreatedThreads,
					isEmpty: isEmpty(this.rootStore.historyStore.myCreatedThreads),
					label:
						this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.HistoryCarouselsNameThreadsCreatedByYou],
					threadsItems: this.rootStore.historyStore.myCreatedThreads,
					getData: (params: IPagingParams) => this.rootStore.historyStore.getThreadsCreatedByUser(params),
					carouselPagingParams: this.rootStore.historyStore.historyCarouselsPagingParams.myCreatedThreads,
					carouselKey: SiteSettingsKeys.HistoryCarouselsNameThreadsCreatedByYou,
				};

			case SiteSettingsKeys.HistoryCarouselsOrderLastSavedThreads:
				return {
					isLoading: this.rootStore.historyStore.loadingGroup.lastSavedThreads,
					isEmpty: isEmpty(this.rootStore.historyStore.lastSavedThreads),
					label:
						this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.HistoryCarouselsNameLastSavedThreads],
					threadsItems: this.rootStore.historyStore.lastSavedThreads,
					getData: (params: IPagingParams) => this.rootStore.historyStore.getLastSavedThreads(params),
					carouselPagingParams: this.rootStore.historyStore.historyCarouselsPagingParams.lastSavedThreads,
					carouselKey: SiteSettingsKeys.HistoryCarouselsNameLastSavedThreads,
				};
			case SiteSettingsKeys.MyDeskCarouselsOrderSimilarCourses:
				return {
					isLoading: this.rootStore.coursesStore.loadingGroup.similarCourses,
					isEmpty: isEmpty(this.rootStore.coursesStore.relatedCourses),
					label: this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.MyDeskCarouselsNameSimilarCourses],
					courseItems: this.rootStore.coursesStore.relatedCourses,
					getData: (params: IPagingParams) =>
						this.rootStore.coursesStore.getSimilarCourses(myDeskCurrentCourseId, params),
					carouselPagingParams: this.rootStore.coursesStore.coursesCarouselsPagingParams.similarCourses,
					carouselKey: SiteSettingsKeys.MyDeskCarouselsNameSimilarCourses,
				};
			case SiteSettingsKeys.MyDeskCarouselsOrderMostWatched:
				return {
					...mostWatchedCarousel,
					label: this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.MyDeskCarouselsNameMostWatched],
					labelPrefix: "",
					carouselKey: SiteSettingsKeys.MyDeskCarouselsNameMostWatched,
				};
			case SiteSettingsKeys.MyDeskCarouselsOrderAssignedToYou:
				return {
					...assignedToYouCarousel,
					label: this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.MyDeskCarouselsNameAssignedToYou],
					labelPrefix: "",
					carouselKey: SiteSettingsKeys.MyDeskCarouselsNameAssignedToYou,
				};

			case SiteSettingsKeys.MyDeskCarouselsOrderMeetYourGoals:
				return {
					...meetYourGoalsCarousel,
					label: this.rootStore.settingsStore.publicSettings.Pages[SiteSettingsKeys.MyDeskCarouselsNameMeetYourGoals],
					labelPrefix: "",
					carouselKey: SiteSettingsKeys.MyDeskCarouselsNameMeetYourGoals,
				};
			default:
				return {} as ICarouselData;
		}
	};
}
