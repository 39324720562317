import { ISaveEventCourseItemHistoryModel } from "../models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { showErrorMessage } from "helpers/error.handling.helper";
import { useAppStore } from "store";

export const useScormNav = (prev: () => void, next: () => void) => {
	const { contentStore, commonStore } = useAppStore();
	const handleNext = async () => {
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Complete,
			payload: "",
		};
		contentStore.stopKeepAlive();
		contentStore
			.sendEventCourseItemHistory(data)
			.then(() => next())
			.catch((err) => showErrorMessage(commonStore.alertEventBus, err));
	};

	const handlePrev = async () => {
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Complete,
			payload: "",
		};
		contentStore.stopKeepAlive();
		contentStore
			.sendEventCourseItemHistory(data)
			.then(() => prev())
			.catch((err) => showErrorMessage(commonStore.alertEventBus, err));
	};

	return { handleNext, handlePrev };
};
