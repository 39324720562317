import styles from "./styles.module.scss";
import classNames from "classnames";
import { IChapterRef } from "models/dto/ZoomiLxp/Models/Courses/IChapterRef";
import { ICourseItemModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseItemModel";
import isEmpty from "lodash/isEmpty";
import { getCourseItemIcon } from "components/partial/cms/cms-content-table/data";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "routes";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import { ReactComponent as CheckIcon } from "assets/icons/ic_player_check.svg";
import every from "lodash/every";
import { ContentItemTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentItemTypes";
import { getCourseItemName, getDuration } from "helpers/content.helper";
import { some, takeWhile } from "lodash";
import { useShowChapters } from "hooks/useShowChapters";
import { RefObject } from "react";
import * as qs from "querystringify";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import AlertTooltip from "components/partial/icons/alert-tooltip";

interface ChapterRosterProps {
	chapter: IChapterRef;
	roster: ICourseItemModel[];
	className?: string;
	isRosterInTile?: boolean;
	rosterRef?: RefObject<HTMLDivElement>;
}

const ChapterRoster = (props: ChapterRosterProps) => {
	const { chapter, roster, className, isRosterInTile = false, rosterRef } = props;
	const history = useHistory();
	const { coursesStore, contentStore, settingsStore } = useAppStore();
	const isNewChapter = every(roster, ["completed", false]);
	const { isShowChapters } = useShowChapters();

	if (isEmpty(roster)) return <></>;

	const getDisplayName = (item: ICourseItemModel) => {
		if (item.contentItemType === ContentItemTypes.Thread) {
			return item.thread?.author.displayName ?? "";
		} else if (item.contentItemType === ContentItemTypes.ExternalContent) {
			return item.externalContent?.accountCreated.displayName ?? "";
		}
		return item.accountCreated?.displayName ?? "";
	};

	const getIsItemOutdated = (item: ICourseItemModel): boolean => {
		return coursesStore.currentCourse.onceCompleted && !item.started;
	};

	const generateCoursePath = (item: ICourseItemModel): string => {
		let path = generatePath(Routes.MyDeskContent, { courseId: item.courseId, courseItemId: item.courseItemId });
		const searchObj = qs.parse(window.location.search) as any;
		if (searchObj && searchObj.ref) {
			path += qs.stringify(
				{
					ref: searchObj.ref,
					refCourseId: coursesStore.curActiveContent.courseId,
					refCourseItemId: coursesStore.curActiveContent.courseItemId,
				},
				true
			);
		}
		return path;
	};

	const goToItem = (item: ICourseItemModel) => {
		if (!coursesStore.isErrorCourse && !hasNotCompletedRequiredQuizBefore(item)) {
			contentStore.sessionId = undefined;
			const path = generateCoursePath(item);
			history.push(path);
		}
	};

	const hasNotCompletedRequiredQuizBefore = (item: ICourseItemModel) => {
		const itemsBefore = takeWhile(coursesStore.activeCourseItems, (e) => e.courseItemId !== item.courseItemId);
		return some(
			itemsBefore,
			(e) => e.contentItemType === ContentItemTypes.Assessment && !e.completed && e.assessment?.required
		);
	};
	const hideCreatorName =
		settingsStore.publicSettings.Pages?.[SiteSettingsKeys.MyDeskHideCreatorName]?.toLowerCase() === "true";

	return (
		<div
			className={classNames(styles.chapter_roster, className, { [styles.chapter_roster__inTileStyle]: isRosterInTile })}
		>
			{isShowChapters && <h2 className={styles.chapter_roster__title}>{chapter.name}</h2>}
			<div className={styles.chapter_roster__table} ref={rosterRef}>
				{roster.map((item) => {
					const isItemOutdated = getIsItemOutdated(item);
					const courseItem = coursesStore.activeCourseItems.find((i) => i.courseItemId === item.courseItemId);
					const itemCompleted = courseItem?.completed ?? item.completed;
					return (
						<div
							className={classNames(styles.chapter_roster__row, {
								[styles.chapter_roster__row__is_new_chapter]: isNewChapter,
							})}
							key={item.courseItemId}
						>
							<div
								className={classNames(styles.chapter_roster__content_icon, {
									[styles.chapter_roster__row__inactive]: !itemCompleted,
								})}
								onClick={() => goToItem(item)}
							>
								{getCourseItemIcon(item, true, true)}
							</div>
							<div
								className={classNames(styles.chapter_roster__wrap, {
									[styles.chapter_roster__row__inactive]: !itemCompleted,
								})}
								onClick={() => goToItem(item)}
							>
								<div
									className={classNames(styles.chapter_roster__text, {
										[styles.chapter_roster__text__light]: isRosterInTile,
									})}
								>
									{getCourseItemName(item)}
								</div>
								<div
									className={classNames(styles.chapter_roster__box, {
										[styles.chapter_roster__box__light]: isRosterInTile,
									})}
								>
									{!hideCreatorName && <div className={styles.chapter_roster__author}>{getDisplayName(item)}</div>}
									<div className={styles.chapter_roster__duration}>{getDuration(item)}</div>
								</div>
							</div>
							<div className={styles.chapter_roster__check}>
								{isItemOutdated ? (
									<AlertTooltip className={styles.version_tooltip} />
								) : (
									<CheckIcon
										className={classNames(styles.chapter_roster__check_icon, {
											[styles.chapter_roster__check_icon__active]: itemCompleted,
										})}
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default observer(ChapterRoster);
