import { AccountApi } from "api/controllers/AccountApi";
import { makeAutoObservable, runInAction } from "mobx";
import { ISignInRequestModel } from "models/dto/ZoomiLxp/Models/Authentication/ISignInRequestModel";
import { ISignUpRequestModel } from "models/dto/ZoomiLxp/Models/Authentication/ISignUpRequestModel";
import { isHydrated, makePersistable, stopPersisting } from "mobx-persist-store";
import { IExternalSignInRequestModel } from "models/dto/ZoomiLxp/Models/Authentication/IExternalSignInRequestModel";
import { IConfirmEmailRequestModel } from "models/dto/ZoomiLxp/Models/EmailConfirmation/IConfirmEmailRequestModel";
import { IRequestPasswordResetRequestModel } from "models/dto/ZoomiLxp/Models/PasswordReset/IRequestPasswordResetRequestModel";
import { clearAuthHeaders, requestErrorHandler, setAuthHeaders } from "api/utils/api";
import { AppStore } from "store";
import { IRoleModel } from "models/dto/ZoomiLxp/Models/Profile/IRoleModel";
import { sortBy } from "lodash";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { IProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IProfileDataModel";
import { ICheckInvitationCodeModel } from "models/dto/ZoomiLxp/Models/Invitation/ICheckInvitationCodeModel";
import { IConfirmInvitationRequestModel } from "models/dto/ZoomiLxp/Models/Invitation/IConfirmInvitationRequestModel";
import filter from "lodash/filter";
import lowerCase from "lodash/lowerCase";
import { FilterFunction } from "models/dto/ZoomiLxp/Utilities/Enumerations/FilterFunction";
import head from "lodash/head";
import { getInitParams } from "helpers/filter.helper";
import { showErrorMessage } from "helpers/error.handling.helper";
import { ISetup2FAModel } from "models/dto/ZoomiLxp/Models/Authentication/ISetup2FAModel";
import { ISignInResponseModel } from "models/dto/ZoomiLxp/Models/Authentication/ISignInResponseModel";
import { ICheck2FAModel } from "models/dto/ZoomiLxp/Models/Authentication/ICheck2FAModel";
import { AxiosResponse } from "axios";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";

export class AccountStore {
	accessToken: string | null = null;
	private rootStore;
	private _roles: IRoleModel[] = [];
	private _users: IProfileDataModel[] = [];
	private _usersSearchParams: IQueryParams = getInitParams();
	private _isLoading: boolean = false;
	auth2FAToken: string = "";
	setup2FACode: string = "";
	setup2FAQR: string = "";
	userAuthCode: number | null = null;
	is2FAEnabled: boolean = false;
	inviteCourseId: string = "";
	wasLogin: boolean = false;

	get isAuthenticated() {
		return Boolean(this.accessToken && this.accessToken.length);
	}

	get isHydrated() {
		return isHydrated(this);
	}

	constructor(rootStore: AppStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this);

		makePersistable(this, {
			name: "accountStore",
			properties: ["accessToken"],
			storage: window.localStorage,
		});

		makePersistable(this, {
			name: "2faStore",
			properties: ["auth2FAToken", "setup2FACode", "setup2FAQR", "is2FAEnabled"],
			storage: window.sessionStorage,
		});
	}

	stop2FAPersist() {
		stopPersisting("2faStore");
	}

	async setup2FA(authCode: number) {
		this.isLoading = true;
		try {
			const data: ISetup2FAModel = { token: this.auth2FAToken, code: authCode };
			const response = await AccountApi.setup2FA(data);
			this.initialAuthHandler(response.data.data);
			return response.data.data;
		} catch (err) {
			runInAction(() => {
				this.userAuthCode = null;
			});
			showErrorMessage(this.rootStore.commonStore.alertEventBus, err);
		} finally {
			this.isLoading = false;
		}
	}

	async check2FA(authCode: number) {
		this.isLoading = true;
		try {
			const data: ICheck2FAModel = { token: this.auth2FAToken, code: authCode };
			const response = await AccountApi.check2FA(data);
			this.initialAuthHandler(response.data.data);
			return response.data.data;
		} catch (err) {
			runInAction(() => {
				this.userAuthCode = null;
			});
			showErrorMessage(this.rootStore.commonStore.alertEventBus, err);
		} finally {
			this.isLoading = false;
		}
	}

	async signIn(data: ISignInRequestModel): Promise<ISignInResponseModel | null> {
		this.isLoading = true;
		let response: AxiosResponse<IResponseModel<ISignInResponseModel>, any> | null = null;
		try {
			response = await AccountApi.signin(data);
			this.initialAuthHandler(response.data.data);
		} catch (err) {
			showErrorMessage(this.rootStore.commonStore.alertEventBus, err);
		} finally {
			this.isLoading = false;
			return response ? response.data.data : null;
		}
	}

	initialAuthHandler(data: ISignInResponseModel) {
		if (data.mfaEnabled) {
			runInAction(() => {
				this.is2FAEnabled = data.mfaEnabled;
				this.auth2FAToken = data.token;
				this.setup2FACode = data.mfaSetupKey;
				this.setup2FAQR = data.mfaqr;
			});
			if (this.userAuthCode && this.userAuthCode.toString().length === 6) {
				this.initTokenHandler(data.token);
			}
		} else this.initTokenHandler(data.token);
	}

	initTokenHandler(token: string) {
		this.setAccessToken(token);
		setAuthHeaders(token);
		this.rootStore.usersStore.getPermissions();
	}

	async externalSignIn(data: IExternalSignInRequestModel) {
		const response = await AccountApi.externalSignIn(data);
		if (!!response.data.data.token) {
			setAuthHeaders(response.data.data.token);
			this.setAccessToken(response.data.data.token);
			await this.rootStore.usersStore.refreshCurrentUser();
		}
		return response;
	}

	async signUp(data: ISignUpRequestModel) {
		this.isLoading = true;
		try {
			return await AccountApi.signup(data);
		} finally {
			this.isLoading = false;
		}
	}

	async signout(isTokenExpired?: boolean) {
		this.rootStore.usersStore.clearCurrentUserInfo();
		this.rootStore.usersStore.clearPermissions();

		runInAction(() => {
			this.accessToken = null;
			this.auth2FAToken = "";
			this.userAuthCode = null;
		});

		if (!isTokenExpired) {
			await AccountApi.signout();
		}
		clearAuthHeaders();
	}

	setAccessToken(token: string) {
		this.accessToken = token;
		this.wasLogin = true;
	}

	async confirmEmail(data: IConfirmEmailRequestModel) {
		await AccountApi.confirmEmail(data);
	}

	async resetPassword(data: IRequestPasswordResetRequestModel) {
		await AccountApi.resetPassword(data);
	}

	async getRoles() {
		this.isLoading = true;
		try {
			const response = await AccountApi.getRoles();
			const roles = sortBy(response.data.data, ["name"]);
			runInAction(() => {
				this._roles = roles;
			});
			return roles;
		} finally {
			this.isLoading = false;
		}
	}

	async getAllUsers(data?: IQueryParams) {
		this.isLoading = true;
		try {
			this.usersSearchParams = this.rootStore.searchStore.params;
			const response = await AccountApi.getUsers(data ?? this.usersSearchParams);
			runInAction(() => {
				this._users = response.data.data.records;
			});
			return response.data.data;
		} catch (err) {
			requestErrorHandler(err);
		} finally {
			this.isLoading = false;
		}
	}

	async checkInvitationCode(data: ICheckInvitationCodeModel) {
		this.isLoading = true;
		try {
			const response = await AccountApi.checkInvitationCode(data);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async confirmInvitation(data: IConfirmInvitationRequestModel) {
		this.isLoading = true;
		try {
			const response = await AccountApi.confirmInvitation(data);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	findRoles = (queryString: string): Promise<IRoleModel[]> => {
		return Promise.resolve(filter(this._roles, (role) => lowerCase(role.displayName).includes(queryString)));
	};

	findUserByEmail = async (email: string) => {
		const params = {
			skip: 0,
			take: 100,
			filterCriteria: [
				{
					propertyNames: ["email"],
					function: FilterFunction.Equal,
					argument: email,
				},
			],
			sortCriteria: [],
			queryString: "",
		};
		const response = await AccountApi.getUsers(params);
		return head(response.data.data.records);
	};

	get users(): IProfileDataModel[] {
		return this._users;
	}

	get roles(): IRoleModel[] {
		return this._roles;
	}

	set roles(value: IRoleModel[]) {
		this._roles = value;
	}

	get isLoading() {
		return this._isLoading;
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	get usersSearchParams() {
		return this._usersSearchParams;
	}

	set usersSearchParams(params: IQueryParams) {
		this._usersSearchParams = params;
	}
}
