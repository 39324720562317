import { matchPath } from "react-router-dom";
import { Routes } from "routes";

export const isRouteExists = (path: string): boolean => {
	for (const route in Routes) {
		const value = (Routes as any)[route];
		if (path !== Routes.Root && matchPath(path, { path: value, exact: true })) {
			return true;
		}
	}

	return false;
};

export const isPublicRoute = (path: string): boolean => {
	if (
		matchPath(path, { path: Routes.Register, exact: true }) ||
		matchPath(path, { path: Routes.Login, exact: true }) ||
		matchPath(path, { path: Routes.ResetPassword, exact: true }) ||
		matchPath(path, { path: Routes.CreateNewPassword, exact: true })
	) {
		return true;
	}
	return false;
};
