import React, { useEffect, useRef } from "react";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";
import { EventTypes } from "../models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { showErrorMessage } from "helpers/error.handling.helper";
import { useAppStore } from "store";
import { usePlayerNav } from "./usePlayerNav";
import { useScormNav } from "./useScormNav";
import { ControlParams } from "../components/partial/player/player-control/player-control";
import { getFormattedProgress } from "../helpers/string.helper";
import { usePlayerControl } from "./usePlayerControl";
import { DELAY_TIME } from "constants/constants";

export const useScormDifficult = (sendStart: () => void) => {
	const { contentStore, playerStore, commonStore } = useAppStore();

	useEffect(() => {
		const onBeforeUnload = () => {
			const data: ISaveEventCourseItemHistoryModel = {
				sessionId: contentStore?.sessionId ?? 0,
				eventType: EventTypes.End,
				payload: "",
			};
			contentStore.stopKeepAlive();
			contentStore.sendEventCourseItemHistory(data).catch((err) => showErrorMessage(commonStore.alertEventBus, err));
		};
		window.addEventListener("beforeunload", onBeforeUnload);
		return () => window.removeEventListener("beforeunload", onBeforeUnload);
	}, [contentStore, commonStore.alertEventBus]);

	const { prev, next } = usePlayerNav();
	const { handleNext, handlePrev } = useScormNav(prev, next);

	const initParams: ControlParams = {
		currentProgress: 1,
		totalProgress: 1,
		boxRef: useRef<HTMLDivElement>(null),
		getFormattedProgress,
		muted: true,
		playing: false,
		handleNext,
		handlePrev,
		isPrev: true,
		isNext: true,
		isDownload: false,
	};

	const [params, setParams] = React.useState<ControlParams>(initParams);

	useEffect(() => {
		playerStore.params = params;
	}, [params, playerStore]);

	usePlayerControl(setParams);

	const onLoad = async () => {
		contentStore.sessionId
			? sendStart()
			: setTimeout(() => {
					sendStart();
			  }, DELAY_TIME);
	};

	return { params, onLoad };
};
