import Api, { ApiBase } from "api/utils/api";
import { mimeTypes } from "constants/constants";
import { Dictionary } from "lodash";
import { ICertificateSettings } from "models/dto/ZoomiLxp/Models/Certificates/ICertificateSettings";

export type PagesSiteSettingsName = "SiteSettingsKeys.HomeCarouselsNameAssignedToYou";

export type PagesSiteSettings = Record<string, string>;

export class SettingsApi {
	static getPublicSettings() {
		return Api.get<Dictionary<Dictionary<string>>>("/settings/Public");
	}

	static getPrivateSettings() {
		return Api.get<Dictionary<Dictionary<string>>>("/settings/All");
	}

	static getCertificatePreview(data: ICertificateSettings) {
		return ApiBase.post<string>(
			"/settings/certificatePreview",
			{ params: data },
			{
				headers: { Accept: mimeTypes.png },
				responseType: "arraybuffer",
			}
		);
	}

	static saveSettings(settingsData: Dictionary<string>) {
		return ApiBase.put("/settings/Save", settingsData);
	}
}
