import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { columns } from "./data";
import { usePagination, useTable } from "react-table";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import CmsTable from "components/partial/cms/cms-table/cms-table";
import { useAppStore } from "store";
import Carousel from "components/partial/carousel/carousel";
import CourseTile from "components/partial/course-tile/course-tile";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { observer } from "mobx-react";
import { first, isNil } from "lodash";
import CmsFooter from "components/partial/cms/cms-footer/cms-footer";
import QuizResultsTableTile from "./quiz-table-tile/quiz-table-tile";
import { IAssessmentResultModel } from "models/dto/ZoomiLxp/Models/Assessments/IAssessmentResultModel";
import { emptyQueryParams, initialCarouselPagingParams } from "helpers/params.helper";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { useRouteUserId } from "hooks/useRouteUserId";
import { useAsyncDataTable } from "hooks/useAsyncDataTable";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { AxiosResponse } from "axios";
import { createAlertEventBus } from "components/base/alert/alert";
import { TextInfoData } from "./my-quiz-results-info";
import { useQuery } from "hooks/useQuery";
import { TransitionSources } from "constants/constants";

const MyQuizResults = () => {
	const { routeUserId: otherUserId } = useRouteUserId();
	const [alertEventBus] = useState(() => createAlertEventBus());
	const [data, setData] = useState<IAssessmentResultModel[]>([]);
	const { quizStore } = useAppStore();
	const [courseId, setCourseId] = useState<number>(0);
	const [pageCount, setPageCount] = useState<number>(1);
	const query = useQuery();
	const id = useMemo(() => query.get("id"), [query]);

	const table = useTable<IAssessmentResultModel>(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
			manualPagination: true,
			pageCount,
		},
		usePagination
	);

	const initTableParams = {
		...emptyQueryParams,
		take: table.state.pageSize,
	};
	const [tableParams, setTableParams] = useState<IQueryParams>(initTableParams);
	const carouselParams = useRef<IPagingParams>(initialCarouselPagingParams);
	const getData = useCallback(async () => {
		return await quizStore.getCoursesWithQuizResults(carouselParams.current, otherUserId);
	}, [quizStore, otherUserId]);
	const { items, hasMore, fetchMoreData } = useInfiniteScroll<IPreviewCourseModel>(getData, carouselParams);

	useEffect(() => {
		if (!isNil(items) && items.length && !courseId) {
			setCourseId(first(items)!.id);
			quizStore.getQuizResults(first(items)!.id, tableParams, otherUserId).then(() => {
				setData(quizStore.quizResults);
			});
		}
	}, [quizStore, items, tableParams, otherUserId, courseId]);

	const handleClick = (courseId: number) => {
		setCourseId(courseId);
		quizStore.getQuizResults(courseId, tableParams, otherUserId).then(() => {
			setData(quizStore.quizResults);
		});
	};

	const fetchData = () =>
		quizStore.getQuizResults(courseId || Number(id) || first(items)!.id, tableParams, otherUserId);

	const getResult = (
		result: AxiosResponse<IResponseModel<IGetRecordsResponse<IAssessmentResultModel>>>
	): IGetRecordsResponse<IAssessmentResultModel> => result.data.data;

	const getKey = (item: IAssessmentResultModel) => item.attemptId;

	const { loading } = useAsyncDataTable<
		IAssessmentResultModel,
		AxiosResponse<IResponseModel<IGetRecordsResponse<IAssessmentResultModel>>>
	>(setData, tableParams, setPageCount, alertEventBus, fetchData, getResult);

	useEffect(() => {
		if (id) {
			setCourseId(Number(id));
		}
	}, [id]);

	return (
		<div className={styles.quiz_results}>
			<section className={styles.quiz_results__carousel}>
				<TextInfoData items={data.length} />

				<Carousel
					hasMore={hasMore}
					getMoreData={() => fetchMoreData()}
					items={quizStore.coursesWithQuizResults?.map((course: IPreviewCourseModel) => (
						<CourseTile
							courseInfo={course}
							className={styles.quiz_results__lesson_tile}
							disableRating={true}
							key={`${course.id}-${course.isBookmarked}`}
							onClick={() => handleClick(course.id)}
							isHilightDisabled={course.id === courseId}
							disableTitleForward={true}
							courseTileKey={TransitionSources.QuizResults}
						/>
					))}
				/>
			</section>
			<div className={styles.quiz_results__grid}>
				<LoadingIndicator loading={quizStore.isLoading}>
					<CmsTable
						table={table}
						data={data}
						tileComponent={QuizResultsTableTile}
						getKey={getKey}
						className={styles.quiz_results__table}
						disableRowCursor={true}
					/>
					<CmsFooter
						table={table}
						loading={loading}
						itemsCount={data.length}
						setParams={setTableParams}
						courseId={courseId}
					/>
				</LoadingIndicator>
			</div>
		</div>
	);
};

export default observer(MyQuizResults);
