import { generatePath } from "react-router-dom";
import { Routes } from "routes";

export interface MenuItem {
	id: number;
	text: string;
	route: string;
}

export const myDataMenuItems: MenuItem[] = [
	{
		id: 0,
		text: "Profile",
		route: Routes.Profile,
	},
	{
		id: 1,
		text: "History",
		route: Routes.MyHistory,
	},
	{
		id: 2,
		text: "Analytics",
		route: Routes.MyAnalytics,
	},
	{
		id: 3,
		text: "Goals",
		route: Routes.MyGoals,
	},
	{
		id: 4,
		text: "Notes",
		route: Routes.MyNotes,
	},
	{
		id: 5,
		text: "Purchases",
		route: Routes.ProfilePurchases,
	},
	{
		id: 6,
		text: "Quiz results",
		route: Routes.MyQuizResults,
	},
	{
		id: 7,
		text: "Certificates",
		route: Routes.MyCertificates,
	},
];

export const getOtherUserMenuItems = (userId: number): MenuItem[] => [
	{
		id: 0,
		text: "Profile",
		route: generatePath(Routes.CmsUserProfile, { userId }),
	},
	{
		id: 1,
		text: "History",
		route: generatePath(Routes.CmsUserHistory, { userId }),
	},
	{
		id: 2,
		text: "Analytics",
		route: generatePath(Routes.CmsUserAnalytics, { userId }),
	},
	{
		id: 3,
		text: "Goals",
		route: generatePath(Routes.CmsUserGoals, { userId }),
	},
	{
		id: 4,
		text: "Purchases",
		route: generatePath(Routes.CmsUserPurchases, { userId }),
	},
	{
		id: 5,
		text: "Quiz results",
		route: generatePath(Routes.CmsUserQuizResults, { userId }),
	},
	{
		id: 6,
		text: "Certificates",
		route: generatePath(Routes.CmsUserCertificates, { userId }),
	},
];

export const getMyAccountItems = (showQuizResultsSetting: string, isShowAllItems: boolean) => {
	const showQuizResult = showQuizResultsSetting?.toLowerCase() === "true";
	if (showQuizResult || isShowAllItems) {
		return myDataMenuItems;
	}

	return myDataMenuItems.filter((item) => item.route !== Routes.MyQuizResults);
};

export enum GroupTabs {
	Courses = "Courses",
	Threads = "Threads",
	Users = "Users",
}

export const getGroupsMenuItems = (id: string, showUsersTab: boolean = true): MenuItem[] => {
	const groupsMenuItems: MenuItem[] = [
		{
			id: 0,
			text: GroupTabs.Courses,
			route: generatePath(Routes.CmsGroupProfileTab, { id, tab: GroupTabs.Courses }),
		},
		{
			id: 1,
			text: GroupTabs.Threads,
			route: generatePath(Routes.CmsGroupProfileTab, { id, tab: GroupTabs.Threads }),
		},
		{
			id: 2,
			text: GroupTabs.Users,
			route: generatePath(Routes.CmsGroupProfileTab, { id, tab: GroupTabs.Users }),
		},
	];
	return showUsersTab ? groupsMenuItems : groupsMenuItems.filter((item) => !item.text.includes(GroupTabs.Users));
};

export const cmsMenuItems: MenuItem[] = [
	{
		id: 0,
		text: "Courses",
		route: Routes.CmsCourses,
	},
	{
		id: 1,
		text: "Threads",
		route: Routes.CmsThreads,
	},
	{
		id: 2,
		text: "Companies",
		route: Routes.CmsCompanies,
	},
	{
		id: 3,
		text: "Users",
		route: Routes.CmsUsers,
	},
	{
		id: 4,
		text: "Groups",
		route: Routes.CmsGroups,
	},
	{
		id: 5,
		text: "Taxonomy",
		route: Routes.CmsTaxonomy,
	},
	{
		id: 6,
		text: "Sales",
		route: Routes.CmsB2BSales,
	},
	{
		id: 7,
		text: "Purchases",
		route: Routes.CmsB2BPurchases,
	},
	{
		id: 8,
		text: "Settings",
		route: Routes.CmsSettings,
	},
	{
		id: 9,
		text: "Notifications",
		route: Routes.CmsNotificationsSystem,
	},
	{
		id: 10,
		text: "Log History",
		route: Routes.CmsLogHistory,
	},
];

export const cmsSalesMenuItems: MenuItem[] = [
	{
		id: 0,
		text: "B2B",
		route: Routes.CmsB2BSales,
	},
	{
		id: 1,
		text: "B2C",
		route: Routes.CmsB2CSales,
	},
];

export const cmsPurchasesMenuItems: MenuItem[] = [
	{
		id: 0,
		text: "B2B",
		route: Routes.CmsB2BPurchases,
	},
	{
		id: 1,
		text: "B2C",
		route: Routes.CmsB2CPurchases,
	},
];

export const reportsMenuItems: MenuItem[] = [
	{
		id: 0,
		text: "By user",
		route: Routes.ReportUser,
	},
	{
		id: 1,
		text: "By Course",
		route: Routes.ReportCourse,
	},
	{
		id: 2,
		text: "Dashboard",
		route: Routes.ReportDashboard,
	},
];

export const advSearchMenuItems: MenuItem[] = [
	{
		id: 0,
		text: "Courses",
		route: Routes.AdvSearchCoursesPage,
	},
	{
		id: 1,
		text: "Influencers",
		route: Routes.AdvSearchInfluensersPage,
	},
	{
		id: 2,
		text: "Threads",
		route: Routes.AdvSearchThreadsPage,
	},
];

export const influencerMenuItems: MenuItem[] = [
	{
		id: 0,
		text: "All",
		route: Routes.InfluencersAll,
	},
	{
		id: 1,
		text: "Recommendations",
		route: Routes.InfluencersRecommendations,
	},
];

export const notificationsMenuItems: MenuItem[] = [
	{
		id: 0,
		text: "System",
		route: Routes.CmsNotificationsSystem,
	},
	{
		id: 1,
		text: "Custom",
		route: Routes.CmsNotificationsCustom,
	},
];
