import { IAssessmentModel } from "models/dto/ZoomiLxp/Models/Assessments/IAssessmentModel";
import { ICreateAssessmentModel } from "models/dto/ZoomiLxp/Models/Assessments/ICreateAssessmentModel";
import { IQuestionModel } from "models/dto/ZoomiLxp/Models/Assessments/IQuestionModel";
import { IAnswerOptionModel } from "models/dto/ZoomiLxp/Models/Assessments/IAnswerOptionModel";
import { IUpdateAssessmentModel } from "models/dto/ZoomiLxp/Models/Assessments/IUpdateAssessmentModel";
import { IUpdateAnswerModel } from "models/dto/ZoomiLxp/Models/Assessments/IUpdateAnswerModel";
import { QuestionTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/QuestionTypes";
import { ICompletionMessageModel } from "models/dto/ZoomiLxp/Models/Assessments/ICompletionMessageModel";

export const convertToCreateAssessmentModel = (courseId: number, data: IAssessmentModel): ICreateAssessmentModel => {
	return {
		courseId,
		name: data.name,
		passPercent: data.passPercent,
		isActive: data.isActive,
		description: data.description,
		chapterId: data.chapterId ? data.chapterId : undefined,
		required: data.required,
		allowedSingleAttempt: data.allowedSingleAttempt,
		backgroundImageId: data.backgroundImageId,
		backgroundColor: data.backgroundColor,
		questionTextTheme: data.questionTextTheme,
		answerTextTheme: data.answerTextTheme,
		showRightAnswers: data.showRightAnswers,
		questions: data.questions.map((question: IQuestionModel) => ({
			answers: question.answerOptions.map((answer: IAnswerOptionModel) => ({
				name: answer.name,
				isRight: answer.isRight,
			})),
			name: question.name,
			weight: question.questionType === QuestionTypes.Survey ? 0 : question.weight,
			questionType: question.questionType,
			rightAnswerNumber: question.rightAnswerNumber,
			surveyIsMulti: question.surveyIsMulti,
			ratingShapeColor: question.ratingShapeColor,
			ratingScale: question.ratingScale,
			ratingShape: question.ratingShape,
			allowedRetriesCount: question.allowedRetriesCount,
		})),
		completionMessages: data.completionMessages?.map((completionMessage: ICompletionMessageModel) => ({
			text: completionMessage.text,
			percent: completionMessage.percent,
		})),
		messageForWrongAnswer: data.messageForWrongAnswer,
		messageForCorrectAnswer: data.messageForCorrectAnswer,
	};
};

export const convertToUpdateAssessmentModel = (data: IAssessmentModel): IUpdateAssessmentModel => {
	return {
		isActive: data.isActive,
		name: data.name,
		passPercent: data.passPercent,
		description: data.description,
		chapterId: data.chapterId ? data.chapterId : undefined,
		required: data.required,
		allowedSingleAttempt: data.allowedSingleAttempt,
		backgroundImageId: data.backgroundImageId,
		backgroundColor: data.backgroundColor,
		questionTextTheme: data.questionTextTheme,
		answerTextTheme: data.answerTextTheme,
		showRightAnswers: data.showRightAnswers,
		questions: data.questions?.map((question: IQuestionModel) => ({
			id: question?.id ? question?.id : undefined,
			answers: question.answerOptions.map((answer: IUpdateAnswerModel) => ({
				id: answer.id ? answer.id : undefined,
				name: answer.name,
				isRight: answer.isRight,
			})),
			name: question.name,
			weight: question.questionType === QuestionTypes.Survey ? 0 : question.weight,
			questionType: question.questionType,
			rightAnswerNumber: question.rightAnswerNumber,
			surveyIsMulti: question.surveyIsMulti,
			ratingShapeColor: question.ratingShapeColor,
			ratingScale: question.ratingScale,
			ratingShape: question.ratingShape,
			allowedRetriesCount: question.allowedRetriesCount,
		})),
		completionMessages: data.completionMessages?.map((completionMessage: ICompletionMessageModel) => ({
			id: completionMessage?.id ? completionMessage?.id : undefined,
			text: completionMessage.text,
			percent: completionMessage.percent,
		})),
		messageForWrongAnswer: data.messageForWrongAnswer,
		messageForCorrectAnswer: data.messageForCorrectAnswer,
	};
};

export interface IIntervalModel {
	low: number;
	high: number;
	text: string;
}

export const creatingIntervals = (completion: ICompletionMessageModel[]): IIntervalModel[] => {
	if (completion.length === 0) return [];

	const result: IIntervalModel[] = [];
	if (completion.length === 1) {
		const percent = completion[0].percent;
		const text = completion[0].text;
		result.push({ low: percent, high: 100, text });
	} else if (completion.length > 1) {
		for (let i = 0; i < completion.length; i++) {
			const percentCurrent = completion[i].percent;
			const textCurrent = completion[i].text;

			if (i === completion.length - 1) {
				result.push({ low: percentCurrent, high: 100, text: textCurrent });
			} else {
				const nextCompletion = completion[i + 1];
				result.push({ low: percentCurrent, high: nextCompletion.percent, text: textCurrent });
			}
		}
	}
	return result;
};
