import { generatePath, NavLink, useHistory } from "react-router-dom";
import { ReactComponent as MyDeskIcon } from "assets/icons/ic_mydesk.svg";
import { ReactComponent as InfluencerIcon } from "assets/icons/ic_influencers_alt.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/ic_settings.svg";
import { ReactComponent as PeersIcon } from "assets/icons/ic_peers.svg";
import { ReactComponent as HomeIcon } from "assets/icons/ic_home.svg";
import { ReactComponent as MyDataIcon } from "assets/icons/ic_target.svg";
import { ReactComponent as ReportsIcon } from "assets/icons/ic_pulse.svg";
import styles from "./styles.module.scss";
import { Routes } from "routes";
import UserSubmenu from "./components/user-info/user-info";
import classNames from "classnames";
import Logo from "components/base/logo/logo";
import { useAppStore } from "store";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { ReactComponent as SearchIcon } from "assets/icons/ic_search.svg";
import AccessWrapper from "components/partial/access-wrapper/access-wrapper";
import { PermissionConstantsRead } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsRead";
import { PermissionConstantsExport } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsExport";
import { useEffect, useState } from "react";
import { showErrorMessage } from "helpers/error.handling.helper";

const cx = classNames.bind(styles);

const Navbar = () => {
	const history = useHistory();
	const { settingsStore, commonStore } = useAppStore();

	const [pagesPublicSettings, setPagesPublicSettings] = useState(settingsStore.publicSettings.Pages);

	useEffect(() => {
		settingsStore
			.getPublicSettings()
			.then(() => setPagesPublicSettings(settingsStore.publicSettings.Pages))
			.catch((err) => showErrorMessage(commonStore.alertEventBus, err));
	}, [settingsStore, commonStore.alertEventBus]);

	return (
		<div className={styles.navigation}>
			<Logo className={styles.navigation__logo} onClick={() => history.push(generatePath(Routes.Home))} />
			<div className={styles.navigation__box_center}>
				<NavLink
					className={styles.navigation__button}
					activeClassName={cx(styles.navigation__active, styles.navigation__active_home)}
					to={Routes.Home}
				>
					<HomeIcon className={styles.navigation__icon_home} />
					<span className={cx(styles.navigation__text, styles.navigation__text_home)}>
						{pagesPublicSettings?.[SiteSettingsKeys.HomeTabName]}
					</span>
				</NavLink>
				<NavLink
					className={styles.navigation__button}
					activeClassName={cx(styles.navigation__active, styles.navigation__active_desk)}
					to={Routes.MyDesk}
				>
					<MyDeskIcon className={styles.navigation__icon_desk} />
					<span className={cx(styles.navigation__text, styles.navigation__text_desk)}>
						{pagesPublicSettings?.[SiteSettingsKeys.MyDeskTabName]}
					</span>
				</NavLink>
				<NavLink
					className={cx(styles.navigation__button)}
					activeClassName={cx(styles.navigation__active, styles.navigation__active_peers)}
					to={Routes.Peers}
				>
					<PeersIcon className={styles.navigation__icon_peers} />
					<span className={cx(styles.navigation__text, styles.navigation__text_peers)}>
						{pagesPublicSettings?.[SiteSettingsKeys.PeersTabName]}
					</span>
				</NavLink>
				<NavLink
					className={styles.navigation__button}
					activeClassName={cx(styles.navigation__active, styles.navigation__active_influencer)}
					to={Routes.InfluencersAll}
				>
					<InfluencerIcon className={styles.navigation__icon_influencers} />
					<span className={cx(styles.navigation__text, styles.navigation__text_influencer)}>
						{pagesPublicSettings?.[SiteSettingsKeys.InfluencerTabName]}
					</span>
				</NavLink>
				<AccessWrapper
					permissions={[
						PermissionConstantsRead.AllCoursesForTechActions,
						PermissionConstantsRead.OwnCoursesForTechActions,
					]}
				>
					<NavLink
						className={styles.navigation__button}
						activeClassName={cx(styles.navigation__active, styles.navigation__active_cms)}
						to={Routes.Cms}
					>
						<SettingsIcon className={cx(styles.navigation__icon_cms)} />
						<span className={cx(styles.navigation__text, styles.navigation__text_cms)}>
							{pagesPublicSettings?.[SiteSettingsKeys.CMSTabName]}
						</span>
					</NavLink>
				</AccessWrapper>
				<NavLink
					className={styles.navigation__button}
					activeClassName={cx(styles.navigation__active, styles.navigation__active_myData)}
					to={Routes.MyAnalytics}
				>
					<MyDataIcon className={cx(styles.navigation__icon_myData)} />
					<span className={cx(styles.navigation__text, styles.navigation__text_myData)}>
						{pagesPublicSettings?.[SiteSettingsKeys.MyDataTabName]}
					</span>
				</NavLink>
				<AccessWrapper permissions={[PermissionConstantsExport.Reports]}>
					<NavLink
						className={styles.navigation__button}
						activeClassName={cx(styles.navigation__active, styles.navigation__active_reports)}
						to={Routes.Reports}
					>
						<ReportsIcon className={cx(styles.navigation__icon_reports)} />
						<span className={cx(styles.navigation__text, styles.navigation__text_reports)}>
							{pagesPublicSettings?.[SiteSettingsKeys.ReportsTabName]}
						</span>
					</NavLink>
				</AccessWrapper>
			</div>
			<div className={styles.navigation__box_right}>
				<SearchIcon
					onClick={() => {
						history.push({
							pathname: Routes.AdvSearchPage,
							state: { prevPage: history.location.pathname },
						});
					}}
					className={styles.navigation__search_icon}
				/>
				<UserSubmenu />
			</div>
		</div>
	);
};
export default Navbar;
