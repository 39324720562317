import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { StylesConfig } from "react-select";
import { getRolesOptions, IOptionsItem } from "helpers/select.helper";
import uniqueId from "lodash/uniqueId";
import DropdownList from "components/base/dropdown-list/dropdown-list";
import isEmpty from "lodash/isEmpty";
import { IRoleModel } from "models/dto/ZoomiLxp/Models/Profile/IRoleModel";
import { showErrorMessage } from "helpers/error.handling.helper";
import { useAppStore } from "store";
import sortBy from "lodash/sortBy";
import includes from "lodash/includes";
import filter from "lodash/filter";
import { AccountRoles } from "models/dto/ZoomiLxp/Utilities/Enumerations/AccountRoles";
import { PermissionConstantsUpdate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsUpdate";

interface DropdownListRoleProps {
	className?: string;
	customStyles?: StylesConfig;
	onChange: (option: IOptionsItem | null) => void;
	value: IOptionsItem | null;
	isDisabled?: boolean;
	onlyRoleIds?: number[];
	onGetOptions?: (options: IOptionsItem[]) => void;
	placeholder?: string;
}

const cx = classNames.bind(styles);

const DropdownListRole = (props: DropdownListRoleProps) => {
	const { value, onChange, className, isDisabled, customStyles, onlyRoleIds, onGetOptions, placeholder } = props;
	const [id] = useState(() => uniqueId("role-id-"));
	const [rolesOptions, setRolesOptions] = useState<IOptionsItem[]>([]);
	const { accountStore, usersStore, commonStore } = useAppStore();

	useEffect(() => {
		if (isEmpty(rolesOptions)) {
			accountStore
				.getRoles()
				.then((response: IRoleModel[]) => {
					let roles = sortBy(response, "id").filter((role: IRoleModel) => {
						if (!usersStore.checkOneOfUserPermissions([PermissionConstantsUpdate.TenantAdmin])) {
							return role.id !== AccountRoles.TenantAdmin;
						}
						return role;
					});

					if (!isEmpty(onlyRoleIds)) {
						roles = filter(roles, (role: IRoleModel) => includes(onlyRoleIds, role.id));
					}
					const roleOptions = getRolesOptions(roles);
					onGetOptions?.(roleOptions);
					setRolesOptions(roleOptions);
				})
				.catch((err: unknown) => showErrorMessage(commonStore.alertEventBus, err));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return () => {
			accountStore.roles = [];
		};
	}, [accountStore]);

	return (
		<div className={cx(styles.dropdown_role, className)}>
			<DropdownList
				value={value}
				placeholder={placeholder ?? "Role"}
				options={rolesOptions ?? []}
				isSearchable={false}
				onChange={onChange}
				className={styles.dropdown_role__dropdown_role}
				key={id}
				isDisabled={isDisabled}
				customStyles={customStyles}
			/>
		</div>
	);
};

export default DropdownListRole;
