import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { IReport } from "../report-box/data";
import { useAppStore } from "store";
import { modalDialogService } from "components/base/modal-dialog/modal-dialog";
import { ConfirmationModal } from "components/partial/confirmation-popup/confirmation-popup";
import { promptsCancel } from "constants/messages";
import HeaderDashboardRow from "components/partial/dashboard/header-dashboard-row/header-dashboard-row";
import { observer } from "mobx-react";
import { showErrorMessage } from "helpers/error.handling.helper";
import isEmpty from "lodash/isEmpty";
import Alert, { createAlertEventBus } from "components/base/alert/alert";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import InputField from "components/base/input-field/input-field";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { urlValidatorQuickSight } from "helpers/validator.helper";
import { MAX_LENGTH_URL } from "constants/widget";
import { FormikProps } from "formik/dist/types";
import { Dictionary } from "lodash";

interface ReportPageProps {
	report: IReport;
	className?: string;
}

const cx = classNames.bind(styles);

interface IReportValues {
	url: string;
}

const ReportPage = (props: ReportPageProps) => {
	const { report, className } = props;
	const { reportsStore, settingsStore } = useAppStore();
	const [alertEventBus] = useState(() => createAlertEventBus());
	const [url, setUrl] = useState("");
	const ref = useRef<FormikProps<IReportValues>>(null);

	const initialValues: IReportValues = {
		url: url ?? "",
	};

	const initData = useCallback((): void => {
		if (!isEmpty(settingsStore.privateSettings)) {
			reportsStore.isShowButton = false;
			const reports = settingsStore.privateSettings.Reports;
			setUrl(reports[report.settingsKey]);
		}
	}, [report.settingsKey, reportsStore, settingsStore.privateSettings]);

	useEffect(() => {
		if (isEmpty(settingsStore.privateSettings)) {
			settingsStore
				.getPrivateSettings()
				.then(() => initData())
				.catch((err: unknown) => showErrorMessage(alertEventBus, err));
		} else initData();
	}, [alertEventBus, initData, settingsStore]);

	const handleEdit = () => (reportsStore.isShowButton = true);

	const handleCancel = async () => {
		await modalDialogService.show(ConfirmationModal, {
			title: promptsCancel.title,
			popupText: promptsCancel.text,
			confirmationAction: () => {
				reportsStore.isShowButton = false;
			},
			confirmationButtonLabel: promptsCancel.confirmationButton,
			cancelButtonLabel: promptsCancel.cancelButton,
		});
	};

	const handleSave = async () => {
		try {
			const errors = await ref.current?.validateForm();
			if (isEmpty(errors)) {
				const currentUrl = ref.current?.values.url ?? "";
				const info = {
					[report.settingsKey]: currentUrl,
				} as Dictionary<string>;
				await settingsStore.saveSettings(info);
				await settingsStore.getPrivateSettings();
				setUrl(currentUrl);
				reportsStore.isShowButton = false;
			} else {
				ref.current?.setErrors(errors);
			}
		} catch (err: unknown) {
			showErrorMessage(reportsStore.alertEventBus, err);
		}
	};

	const ReportSchema = Yup.object().shape({
		url: urlValidatorQuickSight(MAX_LENGTH_URL),
	});

	return (
		<div className={cx(styles.report_page, className)}>
			<Alert eventBus={alertEventBus} />
			<HeaderDashboardRow title={report.label} onEdit={handleEdit} onCancel={handleCancel} onSave={handleSave} />
			<LoadingIndicator
				loading={settingsStore.isLoading}
				spinnerPosition="top"
				backgroundStyle={"none"}
				className={styles.report_page__loading}
			>
				<div className={styles.report_page__body}>
					{reportsStore.isShowButton ? (
						<div className={styles.report_page__form}>
							<Formik
								initialValues={initialValues}
								validationSchema={ReportSchema}
								onSubmit={handleSave}
								validateOnChange={false}
								innerRef={ref}
							>
								{({ values, setFieldValue, errors }) => (
									<Form className={styles.report_page__form}>
										<div className={styles.report_page__fields_wrap}>
											<InputField
												inputType="text"
												label="Url"
												className={cx(styles.report_page__form_field, styles.report_page__url_field)}
												name="url"
												value={values.url}
												onChange={(e) => setFieldValue("url", e.target.value)}
												isError={Boolean(errors["url"])}
												errorText={errors["url"]}
											/>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					) : (
						<>
							{!isEmpty(url) ? (
								<iframe
									className={styles.report_page__frame}
									title={report.label}
									id={report.label}
									name={report.label}
									src={url}
									loading="lazy"
									allowFullScreen
								/>
							) : (
								<></>
							)}
						</>
					)}
				</div>
			</LoadingIndicator>
		</div>
	);
};

export default observer(ReportPage);
