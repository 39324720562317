import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";
import { MenuItem } from "./items";
import classNames from "classnames";
import { mobileCheck, tabletCheck } from "helpers/device.helper";
import { useRef } from "react";
import { useDetectOutsideClick } from "hooks/useDetectOutsideClick";
import { ReactComponent as BurgerIcon } from "assets/icons/ic_burger_menu.svg";
import { useSubMenuItems } from "hooks/useSubMenuItems";

interface MenuProps {
	exact?: boolean;
	menuItems?: MenuItem[];
	className?: string;
	useDefaultMenu?: boolean;
}

const SubMenu = ({ menuItems, className, exact, useDefaultMenu }: MenuProps) => {
	const { allowedMenuItems } = useSubMenuItems();
	const items = !!menuItems && menuItems.length ? menuItems : allowedMenuItems;
	const isMobile = mobileCheck();
	const isTablet = tabletCheck;
	const isAdaptive = isMobile || isTablet;
	const filterButtonRef = useRef<HTMLDivElement>(null);
	const { isActive: isOpen, setIsActive: setIsOpen } = useDetectOutsideClick(filterButtonRef, false);

	const openMenu = () => setIsOpen((prevState) => !prevState);

	const DefaultNavMenu = () => {
		return (
			<nav className={classNames(className, styles.submenu, { [styles.submenu__default]: useDefaultMenu })}>
				{items.map((item) => (
					<NavLink
						className={styles.submenu__link}
						activeClassName={styles.submenu__active}
						to={item.route}
						key={item.id}
						exact={exact}
					>
						{item.text}
					</NavLink>
				))}
			</nav>
		);
	};

	const MobileBurgerMenu = () => {
		return (
			<div className={styles.mobile_menu__button_container} ref={filterButtonRef}>
				<div onClick={openMenu} className={classNames(styles.mobile_menu__button)}>
					<BurgerIcon className={styles.mobile_menu__icon} />
				</div>
				<div
					className={classNames(styles.mobile_menu, {
						[styles.mobile_menu__hide_menu]: !isOpen,
					})}
				>
					<DefaultNavMenu />
				</div>
			</div>
		);
	};

	return isAdaptive && !useDefaultMenu ? <MobileBurgerMenu /> : <DefaultNavMenu />;
};

export default SubMenu;
