import styles from "./styles.module.scss";
import { observer } from "mobx-react";
import PublicLayout from "layouts/public/public";
import React, { useState } from "react";
import Logo from "components/base/logo/logo";
import InputField from "components/base/input-field/input-field";
import Checkbox from "components/base/checkbox/checkbox";
import InputPassword from "components/base/input-password/input-password";
import { Form, Formik } from "formik";
import { generatePath, Link, NavLink, useHistory } from "react-router-dom";
import { Routes } from "routes";
import isEmpty from "lodash/isEmpty";
import { useAppStore } from "store";
import { signupSchema } from "helpers/validator.helper";
import Alert, { createAlertEventBus } from "components/base/alert/alert";
import classNames from "classnames/bind";
import { ReactComponent as SpinnerIcon } from "assets/icons/ic_spinner.svg";
import { getUserFullName } from "helpers/string.helper";
import { showErrorMessage, showSuccessMessage } from "helpers/error.handling.helper";
import { PRIVACY_POLICY_URL } from "constants/constants";
import Button from "components/base/button/button";
import { passwordRequirements } from "constants/messages";
import { useFooterData } from "hooks/useGetTermsOfUse";

interface FormValues {
	firstName: string;
	lastName: string;
	email: string;
	jobTitle: string;
	password: string;
	passwordConfirmation: string;
	acceptTerms: boolean;
}

const cx = classNames.bind(styles);

const RegisterPage: React.FC = observer(() => {
	const { accountStore } = useAppStore();
	const [alertEventBus] = useState(() => createAlertEventBus());
	const { termsOfUseEnabled, termsOfUseUrl } = useFooterData();
	const history = useHistory();

	const initialValues: FormValues = {
		firstName: "",
		lastName: "",
		email: "",
		jobTitle: "",
		password: "",
		passwordConfirmation: "",
		acceptTerms: false,
	};

	const isLoading = accountStore.isLoading;

	const handleSubmit = async (values: FormValues) => {
		try {
			accountStore
				.signUp({
					email: values.email,
					firstName: values.firstName,
					lastName: values.lastName,
					jobTitle: values.jobTitle,
					password: values.password,
					passwordConfirmation: values.passwordConfirmation,
					username: values.email,
					displayName: getUserFullName(values.firstName, values.lastName),
					isTermsAccepted: values.acceptTerms,
					isPPAccepted: values.acceptTerms,
				})
				.then(() => {
					showSuccessMessage(
						alertEventBus,
						"Registration was successful and a confirmation code was sent by email. You will be redirected to login page."
					);
				})
				.finally(() => setTimeout(() => history.push(generatePath(Routes.Login)), 2000));
		} catch (err: any) {
			showErrorMessage(alertEventBus, err);
		}
	};

	return (
		<PublicLayout>
			<Alert eventBus={alertEventBus} />
			<section className={styles.register_page}>
				<div className={styles.register_page__logo_box}>
					<Logo />
				</div>
				<div className={styles.register_page__title}>Create an account</div>
				<div className={styles.register_page__wrap}>
					<div className={`${styles.register_page__text} ${styles.register_page__text_inner}`}>
						Already Have Account?
					</div>
					<NavLink className={styles.register_page__link} to={generatePath(Routes.Login)}>
						Log In
					</NavLink>
				</div>

				<Formik
					initialValues={initialValues}
					validationSchema={signupSchema}
					onSubmit={handleSubmit}
					validateOnChange={false}
				>
					{({ values, validateForm, setFieldValue, errors, setErrors }) => (
						<Form
							onKeyDown={(event) => {
								if (event.key === "Enter") {
									event.preventDefault();
									handleSubmit(values);
								}
							}}
						>
							<div className={styles.register_page__fields_wrap}>
								<div className={styles.register_page__fields_container}>
									<InputField
										inputType="text"
										label="First Name"
										className={styles.register_page__field_inner}
										name="firstname"
										value={values.firstName}
										onChange={(e) => setFieldValue("firstName", e.target.value)}
										isError={Boolean(errors["firstName"])}
										errorText={errors["firstName"]}
									/>
									<InputField
										inputType="text"
										label="Last Name"
										className={styles.register_page__field_inner}
										name="lastname"
										value={values.lastName}
										onChange={(e) => setFieldValue("lastName", e.target.value)}
										isError={Boolean(errors["lastName"])}
										errorText={errors["lastName"]}
									/>
									<InputField
										inputType="text"
										label="Job Title"
										className={styles.register_page__field_inner}
										name="jobTitle"
										value={values.jobTitle}
										onChange={(e) => setFieldValue("jobTitle", e.target.value)}
										isError={Boolean(errors["jobTitle"])}
										errorText={errors["jobTitle"]}
									/>
									<InputField
										inputType="email"
										label="Email"
										className={styles.register_page__field_inner}
										name="email"
										value={values.email}
										onChange={(e) => setFieldValue("email", e.target.value)}
										isError={Boolean(errors["email"])}
										errorText={errors["email"]}
									/>
									<InputPassword
										label="Password"
										className={styles.register_page__field_inner}
										name="password"
										value={values.password}
										onChange={(e) => setFieldValue("password", e.target.value)}
										isError={Boolean(errors["password"])}
										errorText={errors["password"]}
									/>
									<div className={styles.register_page__password_text}>{passwordRequirements}</div>
									<InputPassword
										label="Confirm Password"
										className={styles.register_page__field_inner}
										name="passwordConfirmation"
										value={values.passwordConfirmation}
										onChange={(e) => setFieldValue("passwordConfirmation", e.target.value)}
										isError={Boolean(errors["passwordConfirmation"])}
										errorText={errors["passwordConfirmation"]}
									/>
								</div>
								<div className={styles.register_page__box}>
									<Checkbox
										value={values.acceptTerms}
										onChange={(value) => setFieldValue("acceptTerms", value)}
										name="acceptTerms"
										isError={Boolean(errors["acceptTerms"])}
										errorText={errors["acceptTerms"]}
									>
										<div className={styles.register_page__checkbox_inner}>
											I accept the
											<Link
												className={`${styles.register_page__link} ${styles.register_page__link_inner}`}
												to={{ pathname: PRIVACY_POLICY_URL }}
												target="_blank"
											>
												Privacy Policy
											</Link>
											{termsOfUseEnabled && (
												<>
													and
													<Link
														className={`${styles.register_page__link} ${styles.register_page__link_inner}`}
														to={termsOfUseUrl}
														target="_blank"
													>
														Terms Of Service
													</Link>
												</>
											)}
										</div>
									</Checkbox>
								</div>
							</div>
							<Button
								onClick={async (e) => {
									e.preventDefault();
									const errors = await validateForm();
									isEmpty(errors) ? await handleSubmit(values) : setErrors(errors);
								}}
								label="Create account"
								className={cx(styles.register_page__btn_sign_in, { register_page__btn_disable: isLoading })}
								icon={
									isLoading ? (
										<div className={styles.register_page__icon_box}>
											<SpinnerIcon className={styles.register_page__icon} />
										</div>
									) : (
										<></>
									)
								}
							/>
						</Form>
					)}
				</Formik>
			</section>
		</PublicLayout>
	);
});

export default RegisterPage;
