import styles from "./styles.module.scss";
import classNames from "classnames";
import { SearchType } from "pages/private/adv-search-page/data";
import { useAppStore } from "store";
import isEmpty from "lodash/isEmpty";
import InfiniteScroll from "react-infinite-scroll-component";
import { observer } from "mobx-react";
import { useGetData } from "hooks/useGetData";
import { useGetItems } from "./useGetItems";

interface SearchResultsProps {
	searchType: SearchType;
	className?: string;
}

const cx = classNames.bind(styles);

const SearchResults = (props: SearchResultsProps) => {
	const { searchType, className } = props;
	const { searchStore } = useAppStore();
	const { fetchMoreData } = useGetData(searchType);
	const { getResultTiles, getSearchResults } = useGetItems(searchType);
	const results = getSearchResults();

	return (
		<div className={cx(styles.search_results, className)}>
			<div className={styles.search_results__content}>
				<div className={styles.search_results__section_grid}>
					{!isEmpty(results) && (
						<InfiniteScroll
							dataLength={results?.length ?? 0}
							next={fetchMoreData}
							hasMore={searchStore.hasMore}
							loader={<h4>Loading...</h4>}
						>
							<div className={styles.search_results__grid}>{getResultTiles()}</div>
						</InfiniteScroll>
					)}
				</div>
			</div>
		</div>
	);
};

export default observer(SearchResults);
