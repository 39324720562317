import React, { useCallback, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Alert from "components/base/alert/alert";
import { getFormattedProgress } from "helpers/string.helper";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { useAppStore } from "store";
import { usePlayerNav } from "hooks/usePlayerNav";
import PlayerControl, { ControlParams } from "../player-control/player-control";
import { IPlayerExternalLinkBoxProps } from "pages/private/cms-external-link-page/constants";
import { usePlayerControl } from "hooks/usePlayerControl";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";

const cx = classNames.bind(styles);

const PlayerExternalLinkBox = (props: IPlayerExternalLinkBoxProps) => {
	const { externalContent, className, onEventHandle } = props;
	const {
		contentStore,
		coursesStore,
		commonStore: { alertEventBus },
	} = useAppStore();

	const { prev, next } = usePlayerNav();

	const handleNext = () => next();

	const handlePrev = () => prev();

	const handleClick = () => {
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Complete,
			payload: "",
		};
		onEventHandle(data).then(() => {
			coursesStore.curActiveContent.completed = true;
			coursesStore.updateActiveCourseItemToCompleted();
		});
	};

	const initParams: ControlParams = {
		currentProgress: 1,
		totalProgress: 1,
		boxRef: useRef<HTMLDivElement>(null),
		getFormattedProgress,
		muted: true,
		playing: false,
		isDownload: false,
		handleNext,
		handlePrev,
		isPrev: false,
		isNext: false,
	};

	const [params, setParams] = React.useState<ControlParams>(initParams);

	usePlayerControl(setParams);

	const sendStart = useCallback(() => {
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Start,
			payload: "",
		};
		contentStore.sendEventCourseItemHistory(data);
	}, [contentStore]);

	useEffect(() => {
		if (contentStore.sessionId) {
			sendStart();
		}
	}, [contentStore.sessionId, sendStart]);

	if (!externalContent) return <></>;

	return (
		<div className={cx(styles.player_external_link_box, className)} ref={params.boxRef}>
			<Alert eventBus={alertEventBus} />
			<div className={styles.player_external_link_box__wrap}>
				<div className={styles.player_external_link_box__color_bar} />
				<div className={styles.player_external_link_box__body}>
					<div className={styles.player_external_link_box__title}>{externalContent.title}</div>
					<div className={styles.player_external_link_box__description}>{externalContent.description}</div>
				</div>
				<div className={styles.player_external_link_box__footer}>
					<a
						href={externalContent.url}
						target="_blank"
						className={styles.player_external_link_box__btn}
						rel="noreferrer"
						onClick={handleClick}
					>
						Show Resource
					</a>
				</div>
			</div>
			<PlayerControl params={params} />
		</div>
	);
};

export default PlayerExternalLinkBox;
