import styles from "./styles.module.scss";
import { observer } from "mobx-react";
import PublicLayout from "layouts/public/public";
import React, { useEffect, useState } from "react";
import Logo from "components/base/logo/logo";
import InputPassword from "components/base/input-password/input-password";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "routes";
import isEmpty from "lodash/isEmpty";
import { emailValidator, passwordConfirmationValidator, passwordValidator } from "helpers/validator.helper";
import InputField from "components/base/input-field/input-field";
import { useQuery } from "hooks/useQuery";
import Alert, { createAlertEventBus } from "components/base/alert/alert";
import { IConfirmPasswordResetRequestModel } from "models/dto/ZoomiLxp/Models/PasswordReset/IConfirmPasswordResetRequestModel";
import { AccountApi } from "api/controllers/AccountApi";
import { useAppStore } from "store";
import { ICheckPasswordConfirmationCodeModel } from "models/dto/ZoomiLxp/Models/PasswordReset/ICheckPasswordConfirmationCodeModel";
import { showErrorMessage, showSuccessMessage } from "helpers/error.handling.helper";
import { passwordRequirements } from "constants/messages";

interface FormValues {
	email: string;
	password: string;
	passwordConfirmation: string;
}

const NewPasswordPage: React.FC = observer(() => {
	const { accountStore } = useAppStore();
	const history = useHistory();
	const query = useQuery();
	const code = String(query.get("code")) ?? 0;
	const email = query.get("email") ?? "";
	const [alertEventBus] = useState(() => createAlertEventBus());

	useEffect(() => {
		if (accountStore.isAuthenticated) {
			accountStore.signout();
			history.replace(`${Routes.CreateNewPassword}?code=${code}&email=${email}`);
		}
	}, [accountStore, accountStore.isAuthenticated, history, code, email]);

	useEffect(() => {
		const data: ICheckPasswordConfirmationCodeModel = {
			code,
			email,
		};
		AccountApi.CheckPasswordConfirmationCode(data)
			.then((result) => {
				const isValid = result.data.data.isValid;
				if (!isValid) {
					setTimeout(() => history.push(generatePath(Routes.Home)), 1500);
					showErrorMessage(alertEventBus, "The link is invalid. The link is valid only for 24 hours.");
				}
			})
			.catch((e) => {
				showErrorMessage(alertEventBus, e);
			});
	}, [alertEventBus, history, code, email]);

	const SignupSchema = Yup.object().shape({
		email: emailValidator,
		password: passwordValidator,
		passwordConfirmation: passwordConfirmationValidator,
	});

	const initialValues: FormValues = {
		email: query.get("email") ?? "",
		password: "",
		passwordConfirmation: "",
	};

	const handleSubmit = async (values: FormValues) => {
		try {
			const data: IConfirmPasswordResetRequestModel = {
				code: String(query.get("code")) ?? 0,
				email: values.email,
				newPassword: values.password,
				newPasswordConfirmation: values.passwordConfirmation,
			};
			await AccountApi.confirmPasswordReset(data);
			showSuccessMessage(alertEventBus, "The password has been successfully changed");

			setTimeout(() => history.push(generatePath(Routes.Home)), 500);
		} catch (err: any) {
			showErrorMessage(alertEventBus, err);
		}
	};

	return (
		<PublicLayout>
			<Alert eventBus={alertEventBus} />
			<section className={styles.newpassword_page}>
				<div className={styles.newpassword_page__logo_box}>
					<Logo />
				</div>
				<div className={styles.newpassword_page__title}>Create new password</div>

				<Formik
					initialValues={initialValues}
					validationSchema={SignupSchema}
					onSubmit={handleSubmit}
					validateOnChange={false}
				>
					{({ values, validateForm, setFieldValue, errors, setErrors }) => (
						<Form>
							<div className={styles.newpassword_page__fields_wrap}>
								<InputField
									inputType="email"
									label="Email"
									className={styles.newpassword_page__email_field}
									name="email"
									value={values.email}
									onChange={(e) => setFieldValue("email", e.target.value)}
									isError={Boolean(errors["email"])}
									errorText={errors["email"]}
								/>

								<InputPassword
									label="Password"
									className={styles.newpassword_page__password_field}
									name="password"
									value={values.password}
									onChange={(e) => setFieldValue("password", e.target.value)}
									isError={Boolean(errors["password"])}
									errorText={errors["password"]}
								/>
								<div className={styles.newpassword_page__password_text}>{passwordRequirements}</div>
								<InputPassword
									label="Confirm Password"
									name="passwordConfirmation"
									value={values.passwordConfirmation}
									onChange={(e) => setFieldValue("passwordConfirmation", e.target.value)}
									isError={Boolean(errors["passwordConfirmation"])}
									errorText={errors["passwordConfirmation"]}
								/>
							</div>
							<button
								onClick={async (e) => {
									e.preventDefault();
									const errors = await validateForm();
									isEmpty(errors) ? handleSubmit(values) : setErrors(errors);
								}}
								type="submit"
								className={styles.newpassword_page__btn_sign_in}
							>
								Set new password
							</button>
						</Form>
					)}
				</Formik>
				<div className={styles.newpassword_page__container}>
					<div className={`${styles.newpassword_page__text} ${styles.newpassword_page__text_inner}`}>
						Know your password?
					</div>
					<button
						className={styles.newpassword_page__link}
						onClick={() => history.push(generatePath(Routes.Login))}
						type="button"
					>
						Try logging In
					</button>
				</div>
			</section>
		</PublicLayout>
	);
});

export default NewPasswordPage;
