//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AccountRoles {
	MasterAdmin = 1,
	Influencer = 2,
	FreeLearner = 3,
	CompanyManager = 4,
	CompanyLearner = 5,
	TenantAdmin = 6
}
