import React, { useEffect, useState } from "react";
import HeaderDashboard from "components/partial/dashboard/header-dashboard/header-dashboard";
import BodyDashboard from "components/partial/dashboard/body-dashboard/body-dashboard";
import Alert, { createAlertEventBus } from "components/base/alert/alert";
import { useAppStore } from "store";
import { showErrorMessage } from "helpers/error.handling.helper";

const ReportDashboard = () => {
	const [alertEventBus] = useState(() => createAlertEventBus());
	const { reportsStore } = useAppStore();

	useEffect(() => {
		reportsStore.alertEventBus = alertEventBus;
		reportsStore.getWidgets().catch((err) => showErrorMessage(alertEventBus, err));

		return () => reportsStore.clearWidgets();
	}, [alertEventBus, reportsStore]);

	return (
		<>
			<Alert eventBus={alertEventBus} />
			<HeaderDashboard />
			<BodyDashboard />
		</>
	);
};

export default ReportDashboard;
