import { FC, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useAppStore } from "store";
import {
	getAuthorsOptions,
	getCategoryOptions,
	getSubjectOptions,
	getTagsOptions,
	IOptionsItem,
	searchStyleSheet,
} from "helpers/select.helper";
import DateFilter from "components/partial/filters/date-filter/date-filter";
import MultiSelectFilter from "components/partial/filters/select-filter/multi-select-filter";
import { getSearchInitParams } from "helpers/filter.helper";
import SearchClearButton from "./filter-clear-button";
import classNames from "classnames/bind";
import { observer } from "mobx-react";
import { useAdvSearch } from "hooks/useAdvSearch";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import { showErrorMessage } from "helpers/error.handling.helper";
import { IDefaultFilter } from "./data";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { emptyQueryParams } from "helpers/params.helper";
import { TaxonomyApi } from "api/controllers/TaxonomyApi";

interface IAdvSearchFilterProps extends IDefaultFilter {}

interface IAdvFilterOptions {
	categories?: IOptionsItem[];
	subjects?: IOptionsItem[];
	authors?: IOptionsItem[];
	tags?: IOptionsItem[];
}

const cx = classNames.bind(styles);

const AdvSearchThreadFilters: FC<IAdvSearchFilterProps> = (props) => {
	const { className } = props;
	const [filtersOptions, setFilterOptions] = useState<IAdvFilterOptions>({} as IAdvFilterOptions);
	const [isClear, setIsClear] = useState(false);
	const { searchStore, commonStore } = useAppStore();
	const [tagParams, setTagParams] = useState<IQueryParams>({ ...emptyQueryParams });
	const [tagsIsLoading, setTagsIsLoading] = useState(false);
	const hasFilters = !isEqual(getSearchInitParams(), searchStore.params) || searchStore.threadsResult.length;

	useEffect(() => {
		Promise.allSettled([searchStore.getCategories(), searchStore.getSubjects(), searchStore.getCreators()]).then(
			(response) => {
				setFilterOptions((prevState) => ({
					...prevState,
					categories: response[0].status === "fulfilled" ? getCategoryOptions(response[0].value) : [],
					subjects: response[1].status === "fulfilled" ? getSubjectOptions(response[1].value) : [],
					authors: response[2].status === "fulfilled" ? getAuthorsOptions(response[2].value) : [],
				}));
				const errors = response
					.filter((result) => result.status === "rejected")
					.map((result) => ("reason" in result ? result.reason : null));
				if (!isEmpty(errors)) showErrorMessage(commonStore.alertEventBus, errors);
			}
		);
	}, [searchStore, commonStore.alertEventBus]);

	const { setParams } = useAdvSearch();

	useEffect(() => {
		setTagsIsLoading(true);
		TaxonomyApi.getThreadTags(tagParams)
			.then((e) => {
				setFilterOptions((prevState) => ({
					...prevState,
					tags: getTagsOptions(e.data.data),
				}));
			})
			.finally(() => setTagsIsLoading(false));
	}, [tagParams, tagParams.queryString, tagParams.filterCriteria]);

	return (
		<div className={cx(styles.adv_search_filter__row, className)}>
			<MultiSelectFilter
				containerClassName={styles.adv_search_filter__select_container}
				customStyles={searchStyleSheet}
				className={styles.adv_search_filter__col}
				params={searchStore.params}
				setParams={setParams}
				label="Creator"
				options={filtersOptions.authors ?? []}
				propertyName="AccountCreatedId"
				isClear={isClear}
			/>

			<MultiSelectFilter
				containerClassName={styles.adv_search_filter__select_container}
				customStyles={searchStyleSheet}
				className={styles.adv_search_filter__col}
				params={searchStore.params}
				setParams={setParams}
				label="Category"
				options={filtersOptions.categories ?? []}
				propertyName="CategoryId"
				isClear={isClear}
			/>

			<DateFilter
				className={styles.adv_search_filter__select_container}
				buttonClassName={styles.adv_search_filter__col}
				params={searchStore.params}
				setParams={setParams}
				label="Last updated"
				propertyName="UpdatedUtc"
				isClear={isClear}
			/>

			<MultiSelectFilter
				containerClassName={styles.adv_search_filter__select_container}
				customStyles={searchStyleSheet}
				className={styles.adv_search_filter__col}
				params={searchStore.params}
				setParams={setParams}
				label="Tags"
				options={filtersOptions.tags ?? []}
				propertyName="Tags.Id"
				isClear={isClear}
				isSearchable={true}
				querySearchParams={{
					params: tagParams,
					setParams: setTagParams,
					isLoading: tagsIsLoading,
					propertyName: "name",
				}}
			/>

			{hasFilters && <SearchClearButton setIsClear={setIsClear} />}
		</div>
	);
};

export default observer(AdvSearchThreadFilters);
