//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class SiteSettingsKeys
{
	public static GetCourseAccessMsg: string = `GetCourseAccessMsg`;
	public static GetThreadAccessMsg: string = `ThreadAccessMsg`;
	public static Favicon: string = `Favicon`;
	public static Logo: string = `Logo`;
	public static HomeBanner: string = `Home.Banner`;
	public static PeersBanner: string = `Peers.Banner`;
	public static InfluencersBanner: string = `Influencers.Banner`;
	public static FaviconContentType: string = `Favicon.ContentType`;
	public static LogoContentType: string = `Logo.ContentType`;
	public static HomeBannerContentType: string = `Home.Banner.ContentType`;
	public static PeersBannerContentType: string = `Peers.Banner.ContentType`;
	public static InfluencersBannerContentType: string = `Influencers.Banner.ContentType`;
	public static HomeCarouselsNameAssignedToYou: string = `Home.Carousels.Name.AssignedToYou`;
	public static HomeCarouselsNameMeetYourGoals: string = `Home.Carousels.Name.MeetYourGoals`;
	public static HomeCarouselsNameCoursesCuratedForYou: string = `Home.Carousels.Name.CoursesCuratedForYou`;
	public static HomeCarouselsNameRequiredSkillsFor: string = `Home.Carousels.Name.RequiredSkillsFor`;
	public static HomeCarouselsNamePurchasedCourses: string = `Home.Carousels.Name.PurchasedCourses`;
	public static HomeCarouselsNamePlaylist: string = `Home.Carousels.Name.Playlist`;
	public static HomeCarouselsNameMostWatched: string = `Home.Carousels.Name.MostWatched`;
	public static HomeCarouselsNameMostLiked: string = `Home.Carousels.Name.MostLiked`;
	public static HomeCarouselsNameHighestRated: string = `Home.Carousels.Name.HighestRated`;
	public static HomeCarouselsNameTrendingCourses: string = `Home.Carousels.Name.TrendingCourses`;
	public static HomeCarouselsOrderAssignedToYou: string = `Home.Carousels.Order.AssignedToYou`;
	public static HomeCarouselsOrderMeetYourGoals: string = `Home.Carousels.Order.MeetYourGoals`;
	public static HomeCarouselsOrderCoursesCuratedForYou: string = `Home.Carousels.Order.CoursesCuratedForYou`;
	public static HomeCarouselsOrderRequiredSkillsFor: string = `Home.Carousels.Order.RequiredSkillsFor`;
	public static HomeCarouselsOrderPurchasedCourses: string = `Home.Carousels.Order.PurchasedCourses`;
	public static HomeCarouselsOrderPlaylist: string = `Home.Carousels.Order.Playlist`;
	public static HomeCarouselsOrderMostWatched: string = `Home.Carousels.Order.MostWatched`;
	public static HomeCarouselsOrderMostLiked: string = `Home.Carousels.Order.MostLiked`;
	public static HomeCarouselsOrderHighestRated: string = `Home.Carousels.Order.HighestRated`;
	public static HomeCarouselsOrderTrendingCourses: string = `Home.Carousels.Order.TrendingCourses`;
	public static PeersCarouselsNameThreadsCreatedByYou: string = `Peers.Carousels.Name.ThreadsCreatedByYou`;
	public static PeersCarouselsNameLastSavedThreads: string = `Peers.Carousels.Name.LastSavedThreads`;
	public static PeersCarouselsNameTrendingThreads: string = `Peers.Carousels.Name.TrendingThreads`;
	public static PeersCarouselsNameThreadsCuratedForYou: string = `Peers.Carousels.Name.ThreadsCuratedForYou`;
	public static PeersCarouselsOrderThreadsCreatedByYou: string = `Peers.Carousels.Order.ThreadsCreatedByYou`;
	public static PeersCarouselsOrderLastSavedThreads: string = `Peers.Carousels.Order.LastSavedThreads`;
	public static PeersCarouselsOrderTrendingThreads: string = `Peers.Carousels.Order.TrendingThreads`;
	public static PeersCarouselsOrderThreadsCuratedForYou: string = `Peers.Carousels.Order.ThreadsCuratedForYou`;
	public static MyDeskCarouselsNameSimilarCourses: string = `MyDesk.Carousels.Name.SimilarCourses`;
	public static MyDeskCarouselsOrderSimilarCourses: string = `MyDesk.Carousels.Order.SimilarCourses`;
	public static MyDeskCarouselsNameMostWatched: string = `MyDesk.Carousels.Name.MostWatched`;
	public static MyDeskCarouselsOrderMostWatched: string = `MyDesk.Carousels.Order.MostWatched`;
	public static MyDeskCarouselsNameAssignedToYou: string = `MyDesk.Carousels.Name.AssignedToYou`;
	public static MyDeskCarouselsNameMeetYourGoals: string = `MyDesk.Carousels.Name.MeetYourGoals`;
	public static MyDeskCarouselsOrderAssignedToYou: string = `MyDesk.Carousels.Order.AssignedToYou`;
	public static MyDeskCarouselsOrderMeetYourGoals: string = `MyDesk.Carousels.Order.MeetYourGoals`;
	public static HistoryCarouselsNameAssignedToYou: string = `History.Carousels.Name.AssignedToYou`;
	public static HistoryCarouselsNameMeetYourGoals: string = `History.Carousels.Name.MeetYourGoals`;
	public static HistoryCarouselsNameCoursesCuratedForYou: string = `History.Carousels.Name.CoursesCuratedForYou`;
	public static HistoryCarouselsNameRequiredSkillsFor: string = `History.Carousels.Name.RequiredSkillsFor`;
	public static HistoryCarouselsNamePurchasedCourses: string = `History.Carousels.Name.PurchasedCourses`;
	public static HistoryCarouselsNamePlaylist: string = `History.Carousels.Name.Playlist`;
	public static HistoryCarouselsNameMostWatched: string = `History.Carousels.Name.MostWatched`;
	public static HistoryCarouselsNameMostLiked: string = `History.Carousels.Name.MostLiked`;
	public static HistoryCarouselsNameHighestRated: string = `History.Carousels.Name.HighestRated`;
	public static HistoryCarouselsNameTrendingCourses: string = `History.Carousels.Name.TrendingCourses`;
	public static HistoryCarouselsNameFollowingInfluencers: string = `History.Carousels.Name.FollowingInfluencers`;
	public static HistoryCarouselsNameWatchingHistory: string = `History.Carousels.Name.WatchingHistory`;
	public static HistoryCarouselsNameContinueWatching: string = `History.Carousels.Name.ContinueWatching`;
	public static HistoryCarouselsNameBookmarkedCourses: string = `History.Carousels.Name.BookmarkedCourses`;
	public static HistoryCarouselsNameCoursesCreatedByYou: string = `History.Carousels.Name.CoursesCreatedByYou`;
	public static HistoryCarouselsNameThreadsCreatedByYou: string = `History.Carousels.Name.ThreadsCreatedByYou`;
	public static HistoryCarouselsNameLastSavedThreads: string = `History.Carousels.Name.LastSavedThreads`;
	public static HistoryCarouselsNameTrendingThreads: string = `History.Carousels.Name.TrendingThreads`;
	public static HistoryCarouselsNameThreadsCuratedForYou: string = `History.Carousels.Name.ThreadsCuratedForYou`;
	public static HistoryCarouselsNamePeopleYouShouldBeFollowing: string = `History.Carousels.Name.PeopleYouShouldBeFollowing`;
	public static HistoryCarouselsNameMostLikedInfluencers: string = `History.Carousels.Name.MostLikedInfluencers`;
	public static HistoryCarouselsNameMostFollowedInfluencers: string = `History.Carousels.Name.MostFollowedInfluencers`;
	public static HistoryCarouselsNameLastFollowedInfluencers: string = `History.Carousels.Name.LastFollowedInfluencers`;
	public static HistoryCarouselsNameMoversAndShakersOfLastWeek: string = `History.Carousels.Name.MoversAndShakersOfLastWeek`;
	public static HistoryCarouselsNameCreatedByInfluencer: string = `History.Carousels.Name.CreatedByInfluencer`;
	public static HistoryCarouselsNameSimilarCourses: string = `History.Carousels.Name.SimilarCourses`;
	public static HistoryCarouselsOrderAssignedToYou: string = `History.Carousels.Order.AssignedToYou`;
	public static HistoryCarouselsOrderMeetYourGoals: string = `History.Carousels.Order.MeetYourGoals`;
	public static HistoryCarouselsOrderCoursesCuratedForYou: string = `History.Carousels.Order.CoursesCuratedForYou`;
	public static HistoryCarouselsOrderRequiredSkillsFor: string = `History.Carousels.Order.RequiredSkillsFor`;
	public static HistoryCarouselsOrderPurchasedCourses: string = `History.Carousels.Order.PurchasedCourses`;
	public static HistoryCarouselsOrderPlaylist: string = `History.Carousels.Order.Playlist`;
	public static HistoryCarouselsOrderMostWatched: string = `History.Carousels.Order.MostWatched`;
	public static HistoryCarouselsOrderMostLiked: string = `History.Carousels.Order.MostLiked`;
	public static HistoryCarouselsOrderHighestRated: string = `History.Carousels.Order.HighestRated`;
	public static HistoryCarouselsOrderTrendingCourses: string = `History.Carousels.Order.TrendingCourses`;
	public static HistoryCarouselsOrderFollowingInfluencers: string = `History.Carousels.Order.FollowingInfluencers`;
	public static HistoryCarouselsOrderWatchingHistory: string = `History.Carousels.Order.WatchingHistory`;
	public static HistoryCarouselsOrderContinueWatching: string = `History.Carousels.Order.ContinueWatching`;
	public static HistoryCarouselsOrderBookmarkedCourses: string = `History.Carousels.Order.BookmarkedCourses`;
	public static HistoryCarouselsOrderCoursesCreatedByYou: string = `History.Carousels.Order.CoursesCreatedByYou`;
	public static HistoryCarouselsOrderThreadsCreatedByYou: string = `History.Carousels.Order.ThreadsCreatedByYou`;
	public static HistoryCarouselsOrderLastSavedThreads: string = `History.Carousels.Order.LastSavedThreads`;
	public static HistoryCarouselsOrderTrendingThreads: string = `History.Carousels.Order.TrendingThreads`;
	public static HistoryCarouselsOrderThreadsCuratedForYou: string = `History.Carousels.Order.ThreadsCuratedForYou`;
	public static HistoryCarouselsOrderPeopleYouShouldBeFollowing: string = `History.Carousels.Order.PeopleYouShouldBeFollowing`;
	public static HistoryCarouselsOrderMostLikedInfluencers: string = `History.Carousels.Order.MostLikedInfluencers`;
	public static HistoryCarouselsOrderMostFollowedInfluencers: string = `History.Carousels.Order.MostFollowedInfluencers`;
	public static HistoryCarouselsOrderLastFollowedInfluencers: string = `History.Carousels.Order.LastFollowedInfluencers`;
	public static HistoryCarouselsOrderMoversAndShakersOfLastWeek: string = `History.Carousels.Order.MoversAndShakersOfLastWeek`;
	public static HistoryCarouselsOrderCreatedByInfluencer: string = `History.Carousels.Order.CreatedByInfluencer`;
	public static HistoryCarouselsOrderSimilarCourses: string = `History.Carousels.Order.SimilarCourses`;
	public static InfluencersRecommendationsCarouselsNamePeopleYouShouldBeFollowing: string = `InfluencersRecommendations.Carousels.Name.PeopleYouShouldBeFollowing`;
	public static InfluencersRecommendationsCarouselsNameMostLikedInfluencers: string = `InfluencersRecommendations.Carousels.Name.MostLikedInfluencers`;
	public static InfluencersRecommendationsCarouselsNameMostFollowedInfluencers: string = `InfluencersRecommendations.Carousels.Name.MostFollowedInfluencers`;
	public static InfluencersRecommendationsCarouselsNameLastFollowedInfluencers: string = `InfluencersRecommendations.Carousels.Name.LastFollowedInfluencers`;
	public static InfluencersRecommendationsCarouselsNameMoversAndShakersOfLastWeek: string = `InfluencersRecommendations.Carousels.Name.MoversAndShakersOfLastWeek`;
	public static InfluencersRecommendationsCarouselsOrderPeopleYouShouldBeFollowing: string = `InfluencersRecommendations.Carousels.Order.PeopleYouShouldBeFollowing`;
	public static InfluencersRecommendationsCarouselsOrderMostLikedInfluencers: string = `InfluencersRecommendations.Carousels.Order.MostLikedInfluencers`;
	public static InfluencersRecommendationsCarouselsOrderMostFollowedInfluencers: string = `InfluencersRecommendations.Carousels.Order.MostFollowedInfluencers`;
	public static InfluencersRecommendationsCarouselsOrderLastFollowedInfluencers: string = `InfluencersRecommendations.Carousels.Order.LastFollowedInfluencers`;
	public static InfluencersRecommendationsCarouselsOrderMoversAndShakersOfLastWeek: string = `InfluencersRecommendations.Carousels.Order.MoversAndShakersOfLastWeek`;
	public static InfluencerCarouselsNameAssignedToYou: string = `Influencer.Carousels.Name.AssignedToYou`;
	public static InfluencerCarouselsNameMeetYourGoals: string = `Influencer.Carousels.Name.MeetYourGoals`;
	public static InfluencerCarouselsNameCoursesCuratedForYou: string = `Influencer.Carousels.Name.CoursesCuratedForYou`;
	public static InfluencerCarouselsNameRequiredSkillsFor: string = `Influencer.Carousels.Name.RequiredSkillsFor`;
	public static InfluencerCarouselsNamePurchasedCourses: string = `Influencer.Carousels.Name.PurchasedCourses`;
	public static InfluencerCarouselsNamePlaylist: string = `Influencer.Carousels.Name.Playlist`;
	public static InfluencerCarouselsNameMostWatched: string = `Influencer.Carousels.Name.MostWatched`;
	public static InfluencerCarouselsNameMostLiked: string = `Influencer.Carousels.Name.MostLiked`;
	public static InfluencerCarouselsNameHighestRated: string = `Influencer.Carousels.Name.HighestRated`;
	public static InfluencerCarouselsNameTrendingCourses: string = `Influencer.Carousels.Name.TrendingCourses`;
	public static InfluencerCarouselsNameFollowingInfluencers: string = `Influencer.Carousels.Name.FollowingInfluencers`;
	public static InfluencerCarouselsNameWatchingHistory: string = `Influencer.Carousels.Name.WatchingHistory`;
	public static InfluencerCarouselsNameContinueWatching: string = `Influencer.Carousels.Name.ContinueWatching`;
	public static InfluencerCarouselsNameBookmarkedCourses: string = `Influencer.Carousels.Name.BookmarkedCourses`;
	public static InfluencerCarouselsNameCoursesCreatedByYou: string = `Influencer.Carousels.Name.CoursesCreatedByYou`;
	public static InfluencerCarouselsNameThreadsCreatedByYou: string = `Influencer.Carousels.Name.ThreadsCreatedByYou`;
	public static InfluencerCarouselsNameLastSavedThreads: string = `Influencer.Carousels.Name.LastSavedThreads`;
	public static InfluencerCarouselsNameTrendingThreads: string = `Influencer.Carousels.Name.TrendingThreads`;
	public static InfluencerCarouselsNameThreadsCuratedForYou: string = `Influencer.Carousels.Name.ThreadsCuratedForYou`;
	public static InfluencerCarouselsNamePeopleYouShouldBeFollowing: string = `Influencer.Carousels.Name.PeopleYouShouldBeFollowing`;
	public static InfluencerCarouselsNameMostLikedInfluencers: string = `Influencer.Carousels.Name.MostLikedInfluencers`;
	public static InfluencerCarouselsNameMostFollowedInfluencers: string = `Influencer.Carousels.Name.MostFollowedInfluencers`;
	public static InfluencerCarouselsNameLastFollowedInfluencers: string = `Influencer.Carousels.Name.LastFollowedInfluencers`;
	public static InfluencerCarouselsNameMoversAndShakersOfLastWeek: string = `Influencer.Carousels.Name.MoversAndShakersOfLastWeek`;
	public static InfluencerCarouselsNameCreatedByInfluencer: string = `Influencer.Carousels.Name.CreatedByInfluencer`;
	public static InfluencerCarouselsNameSimilarCourses: string = `Influencer.Carousels.Name.SimilarCourses`;
	public static InfluencerCarouselsOrderAssignedToYou: string = `Influencer.Carousels.Order.AssignedToYou`;
	public static InfluencerCarouselsOrderMeetYourGoals: string = `Influencer.Carousels.Order.MeetYourGoals`;
	public static InfluencerCarouselsOrderCoursesCuratedForYou: string = `Influencer.Carousels.Order.CoursesCuratedForYou`;
	public static InfluencerCarouselsOrderRequiredSkillsFor: string = `Influencer.Carousels.Order.RequiredSkillsFor`;
	public static InfluencerCarouselsOrderPurchasedCourses: string = `Influencer.Carousels.Order.PurchasedCourses`;
	public static InfluencerCarouselsOrderPlaylist: string = `Influencer.Carousels.Order.Playlist`;
	public static InfluencerCarouselsOrderMostWatched: string = `Influencer.Carousels.Order.MostWatched`;
	public static InfluencerCarouselsOrderMostLiked: string = `Influencer.Carousels.Order.MostLiked`;
	public static InfluencerCarouselsOrderHighestRated: string = `Influencer.Carousels.Order.HighestRated`;
	public static InfluencerCarouselsOrderTrendingCourses: string = `Influencer.Carousels.Order.TrendingCourses`;
	public static InfluencerCarouselsOrderFollowingInfluencers: string = `Influencer.Carousels.Order.FollowingInfluencers`;
	public static InfluencerCarouselsOrderWatchingHistory: string = `Influencer.Carousels.Order.WatchingHistory`;
	public static InfluencerCarouselsOrderContinueWatching: string = `Influencer.Carousels.Order.ContinueWatching`;
	public static InfluencerCarouselsOrderBookmarkedCourses: string = `Influencer.Carousels.Order.BookmarkedCourses`;
	public static InfluencerCarouselsOrderCoursesCreatedByYou: string = `Influencer.Carousels.Order.CoursesCreatedByYou`;
	public static InfluencerCarouselsOrderThreadsCreatedByYou: string = `Influencer.Carousels.Order.ThreadsCreatedByYou`;
	public static InfluencerCarouselsOrderLastSavedThreads: string = `Influencer.Carousels.Order.LastSavedThreads`;
	public static InfluencerCarouselsOrderTrendingThreads: string = `Influencer.Carousels.Order.TrendingThreads`;
	public static InfluencerCarouselsOrderThreadsCuratedForYou: string = `Influencer.Carousels.Order.ThreadsCuratedForYou`;
	public static InfluencerCarouselsOrderPeopleYouShouldBeFollowing: string = `Influencer.Carousels.Order.PeopleYouShouldBeFollowing`;
	public static InfluencerCarouselsOrderMostLikedInfluencers: string = `Influencer.Carousels.Order.MostLikedInfluencers`;
	public static InfluencerCarouselsOrderMostFollowedInfluencers: string = `Influencer.Carousels.Order.MostFollowedInfluencers`;
	public static InfluencerCarouselsOrderLastFollowedInfluencers: string = `Influencer.Carousels.Order.LastFollowedInfluencers`;
	public static InfluencerCarouselsOrderMoversAndShakersOfLastWeek: string = `Influencer.Carousels.Order.MoversAndShakersOfLastWeek`;
	public static InfluencerCarouselsOrderCreatedByInfluencer: string = `Influencer.Carousels.Order.CreatedByInfluencer`;
	public static InfluencerCarouselsOrderSimilarCourses: string = `Influencer.Carousels.Order.SimilarCourses`;
	public static HomeTabName: string = `Home.TabName`;
	public static MyDeskTabName: string = `MyDesk.TabName`;
	public static PeersTabName: string = `Peers.TabName`;
	public static InfluencerTabName: string = `Influencer.TabName`;
	public static CMSTabName: string = `CMS.TabName`;
	public static MyDataTabName: string = `MyData.TabName`;
	public static ReportsTabName: string = `Reports.TabName`;
	public static MyDeskCommentsOnTheRightSide: string = `MyDesk.CommentsOnTheRightSide`;
	public static MyDeskRecommendationsBelowComments: string = `MyDesk.RecommendationsBelowComments`;
	public static MyDeskRecommendSegmentsInsideOfCurrentCourse: string = `MyDesk.RecommendSegmentsInsideOfCurrentCourse`;
	public static MyDeskHideCreatorName: string = `MyDesk.HideCreatorName`;
	public static MyDeskShowAlwaysPrevNextButtons: string = `MyDesk.ShowAlwaysPrevNextButtons`;
	public static MyDeskCourseItemsNameTooltipForArrowButtons: string = `MyDesk.CourseItemsNameTooltipForArrowButtons`;
	public static MyDeskAllowClickableLinks: string = `MyDesk.AllowClickableLinks`;
	public static MyDeskSendEventsInterval: string = `MyDesk.SendEventsInterval`;
	public static MyDeskSendEventsBatchSize: string = `MyDesk.SendEventsBatchSize`;
	public static MyDataTabQuizResultsTabVisible: string = `MyData.QuizResults.Visible`;
	public static StringsTaxCode: string = `Strings.TaxCode`;
	public static StringsTaxBehavior: string = `Strings.TaxBehavior`;
	public static StringsCompanyName: string = `Strings.CompanyName`;
	public static StringsPlatformName: string = `Strings.PlatformName`;
	public static StringsTeamName: string = `Strings.TeamName`;
	public static StringsCurrentTenantId: string = `Strings.CurrentTenantId`;
	public static StringsCurrentApiVersion: string = `Strings.CurrentApiVersion`;
	public static BillingUseOwnStripe: string = `Billing.UseOwnStripe`;
	public static BillingStripeSecretKey: string = `Billing.StripeSecretKey`;
	public static BillingStripeEndpointSecret: string = `Billing.StripeEndpointSecret`;
	public static BillingStripeAllowPromocodes: string = `Billing.StripeAllowPromocodes`;
	public static ColorsBackgroundNavbar: string = `--background-navbar`;
	public static ColorsBackgroundTile: string = `--background-tile`;
	public static ColorsBackgroundLayout: string = `--background-layout`;
	public static ColorsNavbarHome: string = `--navbar-home`;
	public static ColorsNavbarDesk: string = `--navbar-desk`;
	public static ColorsBackgroundMyDeskPlayer: string = `--background-desk-player`;
	public static ColorsNavbarPeers: string = `--navbar-peers`;
	public static ColorsNavbarInfluencers: string = `--navbar-influencers`;
	public static ColorsNavbarCms: string = `--navbar-cms`;
	public static ColorsNavbarMyData: string = `--navbar-myData`;
	public static ColorsNavbarReports: string = `--navbar-reports`;
	public static ColorsBackgroundLayoutHeader: string = `--background-layout-header`;
	public static ColorsTextContrast: string = `--text-contrast`;
	public static ColorsBackgroundProfileBody: string = `--background-profile-body`;
	public static ColorsInputBackground: string = `--input-background`;
	public static ColorsTextLayoutHeader: string = `--text-layout-header`;
	public static ColorsTextTableHeader: string = `--text-table-header`;
	public static ColorsTextInputPrimary: string = `--text-input-primary`;
	public static ColorsPrimary: string = `--primary`;
	public static ColorsAvatarEmpty: string = `--avatar-empty`;
	public static ColorsCommentColors: string = `--comment-colors`;
	public static ColorsTextSecondary: string = `--text-secondary`;
	public static ColorsTextPrimary: string = `--text-primary`;
	public static ColorsSecondary: string = `--secondary`;
	public static ColorsTextGeneral: string = `--text-general`;
	public static ColorsTextTiles: string = `--text-tiles`;
	public static ColorsGrid: string = `--grid`;
	public static ColorsTextGrid: string = `--text-grid`;
	public static SupportEmail: string = `SupportEmail`;
	public static NoReplyEmail: string = `NoReplyEmail`;
	public static GoogleAuthEnabled: string = `GoogleAuthEnabled`;
	public static LinkedInAuthEnabled: string = `LinkedInAuthEnabled`;
	public static FacebookAuthEnabled: string = `FacebookAuthEnabled`;
	public static InternalAuthEnabled: string = `InternalAuthEnabled`;
	public static GoogleAuthAppId: string = `GoogleAuthAppId`;
	public static LinkedInAuthAppId: string = `LinkedInAuthAppId`;
	public static FacebookAuthAppId: string = `FacebookAuthAppId`;
	public static AllowedDomains: string = `AllowedDomains`;
	public static DefaultRole: string = `DefaultRole`;
	public static DefaultCompany: string = `DefaultCompany`;
	public static Enabled2FA: string = `Enabled2FA`;
	public static EnabledApiKeyAccess: string = `EnabledApiKeyAccess`;
	public static ApiKey: string = `ApiKey`;
	public static WikiTermsOfUse: string = `Wiki.TermsOfUse`;
	public static WikiTermsOfUseEnabled: string = `Wiki.TermsOfUseEnabled`;
	public static ReportByUser: string = `Reports.ByUser`;
	public static ReportByCourse: string = `Reports.ByCourse`;
	public static CertificatesEnable: string = `Certificates.Enable`;
	public static CertificatesSignature: string = `Certificates.Signature`;
	public static CertificatesStamp: string = `Certificates.Stamp`;
	public static CertificatesIssuedBy: string = `Certificates.IssuedBy`;
	public static CertificatesGenerateQR: string = `Certificates.GenerateQR`;
}
