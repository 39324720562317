import styles from "./styles.module.scss";
import classNames from "classnames";
import Tooltip from "components/base/tooltip/tooltip";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import "react-circular-progressbar/dist/styles.css";
import { ReactComponent as CommentIcon } from "assets/icons/ic_comment.svg";
import { ReactComponent as LikeIcon } from "assets/icons/ic_heart.svg";
import { ReactComponent as VideoIcon } from "assets/icons/ic_play_circle.svg";
import { ReactComponent as PodcastIcon } from "assets/icons/ic_podcast.svg";
import { ReactComponent as TextIcon } from "assets/icons/ic_document.svg";
import { ReactComponent as InfoIcon } from "assets/icons/ic_info.svg";
import { ReactComponent as VideoTypeIcon } from "assets/icons/ic_type_video.svg";
import { ReactComponent as PodcastTypeIcon } from "assets/icons/ic_type_audio.svg";
import { ReactComponent as TextTypeIcon } from "assets/icons/ic_type_document.svg";
import { ReactComponent as CourseTypeIcon } from "assets/icons/ic_type_course_bg.svg";

import { useAppStore } from "store";
import { observer } from "mobx-react";
import Avatar from "components/base/avatar/avatar";
import { TimeFilterTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/TimeFilterTypes";
import { IInteractionsInfluencerResponseModel } from "models/dto/ZoomiLxp/Models/Analytics/IInteractionsInfluencerResponseModel";
import { getAvatarTitle } from "helpers/string.helper";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "routes";
import { Duration } from "luxon";
import { engagementDescription } from "constants/messages";
import { showErrorMessage } from "helpers/error.handling.helper";
import Alert, { createAlertEventBus } from "components/base/alert/alert";
import { useRouteUserId } from "hooks/useRouteUserId";
import HeadChart from "./components/head-chart";
// import MotivesMeter from "./components/motif-meter/motifs-meter";
import AnalyticsFilters from "./components/analytics-filter";
import MyLearningPath from "./components/learning-path/my-learning-path";
import MotivesMeter from "./components/motif-meter/motifs-meter";

const MyAnalytics = () => {
	const { routeUserId: otherUserId } = useRouteUserId();

	const { analyticsStore } = useAppStore();
	const history = useHistory();

	const [alertEventBus] = useState(() => createAlertEventBus());

	const handleParamsChange = async (timeFilterType: TimeFilterTypes, subjectId?: number) =>
		await Promise.allSettled([
			analyticsStore.getEngagementScore({ subjectId, timeFilterType }, otherUserId),
			analyticsStore.getZ1Score({ subjectId, timeFilterType }, otherUserId),
			analyticsStore.getMotifsMeterData({ subjectId, timeFilterType }, otherUserId),
		]);

	useEffect(() => {
		analyticsStore.getAllAnalytics(Number(otherUserId)).catch((err) => showErrorMessage(alertEventBus, err));
		return () => analyticsStore.clearStore();
	}, [analyticsStore, otherUserId, alertEventBus]);

	const getFormattedTime = (seconds: number) => {
		const duration = Duration.fromObject({ seconds });
		const currentTime = duration.shiftTo("hours", "minutes").toObject();
		return `${currentTime.hours?.toFixed(0)} h ${currentTime.minutes?.toFixed(0)} min`;
	};

	return (
		<div className={styles.analytics_page}>
			<Alert eventBus={alertEventBus} />
			<div className={classNames(styles.tile, styles.analytics_page__charts_container)}>
				<div className={styles.analytics_page__title_group}>
					Scores
					<AnalyticsFilters
						onParamsChange={(timeFilterType: TimeFilterTypes, _, subjectId?: number) =>
							handleParamsChange(timeFilterType, subjectId)
						}
					/>
				</div>
				<div className={styles.analytics_page__heads_container}>
					<HeadChart
						title="Z1"
						type="z1"
						value={Math.round(analyticsStore.z1Score)}
						tooltipText="The Z1 score analyzes your clickstream data to predict how much you are learning from content without an assessment. Scores are calculated relative to other learners for that content. Scores close to zero indicate less learning and scores close to 100 indicate high levels of learning."
					/>
					<HeadChart
						title="Engagement"
						type="engagement"
						value={Math.round(analyticsStore.engagementScore)}
						tooltipText={engagementDescription}
					/>
				</div>
				{!!analyticsStore.motifsMeterItems.length && <MotivesMeter />}
			</div>

			<div className={classNames(styles.tile, styles.analytics_page__common_counters)}>
				<div className={styles.analytics_page__counter_block}>
					<CommentIcon className={styles.analytics_page__icon} />
					<div className={styles.analytics_page__count}>
						<span className={styles.analytics_page__count_title}>Comments</span>
						<span className={styles.analytics_page__count_digits}>{analyticsStore.commentsCount}</span>
					</div>
				</div>
				<div className={styles.analytics_page__counter_block}>
					<LikeIcon className={classNames(styles.analytics_page__icon, styles.analytics_page__fill_icon)} />
					<div className={styles.analytics_page__count}>
						<span className={styles.analytics_page__count_title}>Likes</span>
						<span className={styles.analytics_page__count_digits}>{analyticsStore.likesCount}</span>
					</div>
				</div>
				<div className={styles.analytics_page__counter_block}>
					<VideoIcon className={classNames(styles.analytics_page__icon, styles.analytics_page__fill_icon)} />
					<div className={styles.analytics_page__count}>
						<span className={styles.analytics_page__count_title}>Videos</span>
						<span className={styles.analytics_page__count_digits}>{analyticsStore.videosCount}</span>
					</div>
				</div>
				<div className={styles.analytics_page__counter_block}>
					<PodcastIcon className={classNames(styles.analytics_page__icon, styles.analytics_page__fill_icon)} />
					<div className={styles.analytics_page__count}>
						<span className={styles.analytics_page__count_title}>Podcasts</span>
						<span className={styles.analytics_page__count_digits}>{analyticsStore.podcastsCount}</span>
					</div>
				</div>
				<div className={styles.analytics_page__counter_block}>
					<TextIcon className={classNames(styles.analytics_page__icon, styles.analytics_page__fill_icon)} />
					<div className={styles.analytics_page__count}>
						<span className={styles.analytics_page__count_title}>Texts</span>
						<span className={styles.analytics_page__count_digits}>{analyticsStore.textsCount}</span>
					</div>
				</div>
			</div>

			<div className={styles.analytics_page__timing_container}>
				<div className={classNames(styles.tile, styles.analytics_page__time_tile)}>
					<div className={styles.analytics_page__counter_box}>
						<VideoTypeIcon className={styles.analytics_page__type_icon} />
						<div className={styles.analytics_page__count}>
							<span className={styles.analytics_page__count_title}>Total time watched</span>
							<span className={styles.analytics_page__count_digits}>
								{getFormattedTime(analyticsStore.watchingTime)}
							</span>
						</div>
					</div>
				</div>
				<div className={classNames(styles.tile, styles.analytics_page__time_tile)}>
					<div className={styles.analytics_page__counter_box}>
						<PodcastTypeIcon className={styles.analytics_page__type_icon} />
						<div className={styles.analytics_page__count}>
							<span className={styles.analytics_page__count_title}>Total time listened</span>
							<span className={styles.analytics_page__count_digits}>
								{getFormattedTime(analyticsStore.listenedTime)}
							</span>
						</div>
					</div>
				</div>
				<div className={classNames(styles.tile, styles.analytics_page__time_tile)}>
					<div className={styles.analytics_page__counter_box}>
						<TextTypeIcon className={styles.analytics_page__type_icon} />
						<div className={styles.analytics_page__count}>
							<span className={styles.analytics_page__count_title}>Total time read</span>
							<span className={styles.analytics_page__count_digits}>{getFormattedTime(analyticsStore.readTime)}</span>
						</div>
					</div>
				</div>
				<div className={classNames(styles.tile, styles.analytics_page__time_tile)}>
					<div className={styles.analytics_page__counter_box}>
						<CourseTypeIcon className={styles.analytics_page__type_icon} />
						<div className={styles.analytics_page__count}>
							<span className={styles.analytics_page__count_title}>Total Course time</span>
							<span className={styles.analytics_page__count_digits}>{getFormattedTime(analyticsStore.scormTime)}</span>
						</div>
					</div>
				</div>
			</div>

			<div className={classNames(styles.tile, styles.analytics_page__interactions_container)}>
				<div className={styles.analytics_page__title_group}>
					Top 5 Interactions with Influencers and Peers
					<Tooltip
						label={
							"A number of actions, including viewing an influencer’s content and commenting in a thread, are used to calculate who you’ve been engaging with the most."
						}
						className={classNames(
							styles.analytics_page__info_tooltip,
							styles.analytics_page__info_tooltip__text_position_left
						)}
					>
						<InfoIcon className={styles.analytics_page__info_icon} />
					</Tooltip>
				</div>
				<div
					className={classNames(styles.analytics_page__interactions_info_box, {
						[styles.analytics_page__centered]: analyticsStore.interactions.length < 5,
					})}
				>
					{!isEmpty(analyticsStore.interactions) ? (
						analyticsStore.interactions.map((interaction: IInteractionsInfluencerResponseModel) => {
							const nameArray = interaction.displayName.split(" ");
							return (
								<div
									key={interaction.id}
									className={styles.analytics_page__interaction_info}
									onClick={() => history.push(generatePath(Routes.InfluencerPofile, { id: interaction.id }))}
								>
									<Avatar
										image={interaction.thumbnail?.url}
										title={nameArray?.length === 2 ? getAvatarTitle(nameArray[0], nameArray[1]) : ""}
										size="medium"
										className={styles.analytics_page__interaction_avatar}
									/>
									<div className={styles.analytics_page__count}>
										<span className={styles.analytics_page__count_title}>{interaction.displayName}</span>
										<span className={styles.analytics_page__count_digits}>{interaction.count}</span>
									</div>
								</div>
							);
						})
					) : (
						<span>You don't have any interactions yet.</span>
					)}
				</div>
			</div>
			<MyLearningPath />
		</div>
	);
};

export default observer(MyAnalytics);
