import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { useLoadScript } from "./useLoadScript";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import { IPackageMetadata } from "models/dto/ZoomiLxp/Models/LMS/IPackageMetadata";
import { ReactComponent as Play } from "assets/icons/ic_player_play.svg";
import Button from "components/base/button/button";
import isNil from "lodash/isNil";
import screenfull from "screenfull";
import { useDebounce } from "@react-hook/debounce";
import { useAppStore } from "store";
import Alert, { createAlertEventBus } from "components/base/alert/alert";
import { getRteRequired, getScormVersion } from "helpers/scorm.helper";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { observer } from "mobx-react";
import { ControlParams } from "../player-control/player-control";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";
import { showErrorMessage } from "helpers/error.handling.helper";
import { IActivityInfo } from "models/dto/ZoomiLxp/Models/LMS/IActivityInfo";
import { isEmpty } from "lodash";

interface PlayerScormProps {
	url: string;
	params: ControlParams;
	contentId: number;
	className?: string;
	children?: ReactNode;
	onLoad?: () => void;
}

const cx = classNames.bind(styles);

const PlayerScormLite = (props: PlayerScormProps) => {
	const { children, contentId, url, className, params, onLoad } = props;

	const { boxRef } = params;
	const [isLoading, setLoading] = useState(true);
	const [isStub, setStub] = useState(false);
	const [isFullScreen, setFullScreen] = useDebounce<boolean>(false, 200);
	const { lmsStore, contentStore } = useAppStore();
	const [metaData, setMetaData] = useState<IPackageMetadata>({} as IPackageMetadata);
	const [alertEventBus] = useState(() => createAlertEventBus());
	const scormVer = useRef<string>("");
	const rteRequired = useRef<boolean>(false);
	const [isShowFrame, setShowFrame] = useState(false);
	const [disableFullScreen, setDisableFullScreen] = useState(false);

	const getCorrectAttemptId = (info: IActivityInfo) => {
		const isCorrectAttempt = metaData && metaData.attemptId && info.attemptId === metaData.attemptId;
		return isCorrectAttempt ? info.attemptId : metaData?.attemptId;
	};

	const onContentFrameInitialized = (info: IActivityInfo) => {
		const g_frameMgr = (window as any).g_frameMgr;
		const attemptId = getCorrectAttemptId(info);
		if (g_frameMgr) {
			g_frameMgr.SetAttemptId(attemptId);
			if (info.sessionIsEnded) g_frameMgr.TrainingComplete(info.title, info.errorMessage);
			g_frameMgr.SetView(info.view);
			g_frameMgr.SetPostFrame(info.postFrame);
			if (info.activityHasChanged && !info.submitPageDisplayed) {
				g_frameMgr.InitNewActivity(rteRequired.current);
			}
			g_frameMgr.SetActivityId(info.activityId);
			(window as any).activityId = info.activityId;
			(window as any).view = info.view;
			g_frameMgr.SetContentFrameUrl(info.contentHref);
			g_frameMgr.InitDataModelValues(info.dataModel, info.objectiveIdMap);
			if (!isNil(info.isNavigationValidResponse)) g_frameMgr.SetIsNavigationValid(info.isNavigationValidResponse);
			g_frameMgr.RegisterFrameLoad("frameHidden");
		}
	};

	const createActivityInfo = async (attemptId: number) => {
		if (isNil(attemptId)) return;
		await lmsStore.getActivityInfo(0, attemptId);
	};

	useEffect(() => {
		if (contentId && contentStore.sessionId) {
			const data: ISaveEventCourseItemHistoryModel = {
				sessionId: contentStore.sessionId,
				eventType: EventTypes.Play,
				payload: "",
			};
			Promise.all([lmsStore.getPackageMetadata(contentId), contentStore.sendEventCourseItemHistory(data)])
				.then((response) => {
					const requestMeta = response[0];
					setMetaData(requestMeta);
					scormVer.current = getScormVersion(requestMeta.scormVersion);
					rteRequired.current = getRteRequired(requestMeta.scormVersion);
					(window as any).rteRequired = rteRequired.current;
					setLoading(false);
					if (isNil(requestMeta.attemptId)) {
						setStub(true);
					} else {
						setShowFrame(true);
						setStub(false);
					}
				})
				.catch((err) => {
					showErrorMessage(alertEventBus, err);
				});
		}

		return () => {
			setShowFrame(false);
			lmsStore.clearStore();
		};
	}, [alertEventBus, contentId, contentStore, contentStore.sessionId, lmsStore]);

	const isLMSInitialize = useLoadScript(metaData);

	useEffect(() => {
		if (isLMSInitialize && isShowFrame) {
			createActivityInfo(metaData?.attemptId!);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLMSInitialize, metaData.attemptId, isShowFrame]);

	const onClickBtn = async () => {
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Play,
			payload: "",
		};
		const response = await Promise.all([
			lmsStore.createAttempt(String(metaData.organizationId)),
			contentStore.sendEventCourseItemHistory(data),
			contentStore.startKeepAlive(),
		]);
		setShowFrame(true);
		setStub(false);

		await createActivityInfo(response[0] ? response[0].attemptId : lmsStore.attemptId);
	};

	const onChangeFullScreen = useCallback(() => {
		if (isFullScreen !== screenfull.isFullscreen) setFullScreen(screenfull.isFullscreen);
	}, [isFullScreen, setFullScreen]);

	useEffect(() => {
		if (!disableFullScreen) {
			try {
				screenfull.on("change", onChangeFullScreen);
				return () => {
					screenfull.off("change", onChangeFullScreen);
				};
			} catch (error) {
				setDisableFullScreen(true);
			}
		}
	}, [disableFullScreen, onChangeFullScreen]);

	//!  SCORM player show scrollbars by default even whet it not necessary. So we need to hide it. This part will work only if we have not full height nav arrows.
	const scormPlayerFrames = document.getElementsByTagName("iframe");
	if (scormPlayerFrames.length < 2) {
		const contentDoc = scormPlayerFrames[0]?.contentDocument;
		if (scormPlayerFrames.length && contentDoc) {
			const contDocFrames = contentDoc.getElementsByTagName("body");
			if (contDocFrames.length) {
				contDocFrames[0].style.overflow = "hidden";
			}
		}
	}

	if (url === "") return <></>;

	return (
		<LoadingIndicator loading={isLoading}>
			<div className={cx(styles.player_scorm_lite, className)} ref={boxRef}>
				<Alert eventBus={alertEventBus} />

				{isShowFrame && !isEmpty(lmsStore.activityInfo) ? (
					<iframe
						className={styles.player_scorm_lite__document}
						title="frameLearnTask"
						id="frameLearnTask"
						name="frameLearnTask"
						src={url}
						loading="lazy"
						allowFullScreen
						onLoad={() => {
							setLoading(false);
							onLoad?.();
							onContentFrameInitialized(lmsStore.activityInfo);
						}}
					/>
				) : (
					<div className={styles.player_scorm_lite__document}> Loading SCORM data... </div>
				)}

				{isStub && (
					<div className={styles.player_scorm_lite__box}>
						<Button
							label="Course start"
							onClick={onClickBtn}
							icon={<Play className={styles.player_scorm_lite__icon} />}
							className={styles.player_scorm_lite__btn_play}
							type="text"
						/>
					</div>
				)}
				{children}
			</div>
		</LoadingIndicator>
	);
};

export default observer(PlayerScormLite);
