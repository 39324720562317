import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { ReactComponent as EditIcon } from "assets/icons/ic_edit.svg";
import IconCorner from "components/partial/icons/icon-corner/icon-corner";
import { Emitter } from "mitt";
import { AlertEventTypes } from "components/base/alert/alert";
import InputField from "components/base/input-field/input-field";
import { Form, Formik, FormikErrors, FormikValues } from "formik";
import * as Yup from "yup";
import { nameValidator } from "helpers/validator.helper";
import { IRenameDiscussionModel } from "models/dto/ZoomiLxp/Models/Peers/IRenameDiscussionModel";
import Button from "components/base/button/button";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import { ReactComponent as SpinnerIcon } from "assets/icons/ic_spinner.svg";
import { showErrorMessage } from "helpers/error.handling.helper";
import { PermissionConstantsRead } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsRead";
import { isNil } from "lodash";
import { useShowLinksInText } from "hooks/useShowLinksInText";

interface DiscussionProps {
	alertEventBus: Emitter<AlertEventTypes>;
	className?: string;
}

const Discussion = ({ alertEventBus, className }: DiscussionProps) => {
	const { peersStore, usersStore } = useAppStore();
	const [isEdit, setEdit] = useState(false);
	const [text, setText] = useState("");
	const ref = useRef<HTMLDivElement>(null);
	const { formattedText } = useShowLinksInText(text);

	useEffect(() => {
		const discussionName = peersStore.currentThread.discussionName;
		if (!isNil(discussionName)) {
			setText(discussionName);
		}
	}, [peersStore.currentThread]);

	const isPermissionAvailable =
		usersStore.checkOneOfUserPermissions([PermissionConstantsRead.AllThreads]) ||
		usersStore.currentUserInfo?.id === peersStore.currentThread?.author?.id;

	const initialValues: IRenameDiscussionModel = {
		name: text,
	};

	const schema = Yup.object().shape({
		name: nameValidator,
	});

	useEffect(() => {
		if (ref?.current?.clientHeight) {
			const adjustmentHeight = 352;
			peersStore.chartContainerHeight = ref.current.clientHeight + adjustmentHeight;
		}
	}, [peersStore, isEdit, text]);

	async function onSave(values: FormikValues) {
		try {
			await peersStore.renameDiscussion(peersStore.currentThread.id, { name: values.name } as IRenameDiscussionModel);
		} catch (err) {
			showErrorMessage(alertEventBus, err);
		}
	}

	const onSaveClick =
		(
			values: FormikValues,
			validateForm: (values?: any) => Promise<FormikErrors<FormikValues>>,
			setErrors: (errors: FormikErrors<FormikValues>) => void
		) =>
		async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			e.preventDefault();
			const errors = await validateForm();

			if (isEmpty(errors)) {
				await onSave(values);
				setText(values.name);
				setEdit(false);
			} else {
				setErrors(errors);
				showErrorMessage(alertEventBus, "Please check all fields are filled correctly");
			}
		};

	if (isEmpty(text) && !isPermissionAvailable) return <></>;

	return (
		<div className={classNames(styles.discussion, className)} ref={ref}>
			<div className={styles.discussion__header}>Discussion prompt</div>
			<div className={styles.discussion__body}>
				{isEdit ? (
					<Formik
						initialValues={initialValues}
						validationSchema={schema}
						onSubmit={() => {}}
						validateOnChange={false}
						enableReinitialize={true}
					>
						{({ values, validateForm, setFieldValue, errors, setErrors }) => (
							<Form className={styles.discussion__form}>
								<InputField
									inputType="text"
									fieldType="textarea"
									label="Start typing..."
									className={styles.discussion__field_title}
									name="name"
									value={values.name}
									onChange={(e) => setFieldValue("name", String(e.target.value))}
									isError={!!errors.name}
									errorText={errors.name}
								/>
								<div className={styles.discussion__btn_group}>
									<Button
										label={"Cancel"}
										onClick={() => setEdit(false)}
										className={styles.discussion__btn_cancel}
										type="secondary"
										size="common"
									/>
									<Button
										label={"Save"}
										onClick={onSaveClick(values, validateForm, setErrors)}
										className={styles.discussion__btn_save}
										size="common"
										icon={peersStore.isLoading ? <SpinnerIcon className={styles.discussion__icon_spinner} /> : null}
									/>
								</div>
							</Form>
						)}
					</Formik>
				) : (
					<div className={styles.discussion__text}>{formattedText}</div>
				)}
			</div>
			{isPermissionAvailable && !isEdit && (
				<div className={styles.discussion__footer}>
					<div className={styles.discussion__inner_group} onClick={() => setEdit(true)}>
						<EditIcon className={classNames(styles.discussion__edit_icon)} />
						<div className={styles.discussion__text_info}>Edit</div>
					</div>
				</div>
			)}
			<div className={styles.discussion__corner}>
				<IconCorner fill={document.documentElement.style.getPropertyValue("--background-tile")} />
			</div>
		</div>
	);
};

export default observer(Discussion);
