import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.scss";
import PrivateLayout from "layouts/private/private";
import { observer } from "mobx-react";
import { generatePath, NavLink, useRouteMatch } from "react-router-dom";
import Alert, { createAlertEventBus } from "components/base/alert/alert";
import CommonHeader from "components/partial/common-header/common-header";
import { Routes } from "routes";
import { ReactComponent as DartLeft } from "assets/icons/ic_dart-left.svg";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";
import { useAppStore } from "store";
import { useHistory } from "react-router";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import CommentAdd from "components/partial/simple-comments/components/comment-add/comment-add";
import { FormikValues } from "formik";
import { ICreatePostModel } from "models/dto/ZoomiLxp/Models/Peers/ICreatePostModel";
import { isBadRequest } from "api/utils/api";
import PeerCard from "components/partial/peers/peer-card/peer-card";
import PeersList from "components/partial/peers/peers-list/peers-list";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { SortDirection } from "models/dto/ZoomiLxp/Utilities/Enumerations/SortDirection";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { IPostModel } from "models/dto/ZoomiLxp/Models/Peers/IPostModel";
import { emptyErrorInfo, IErrorInfo, makeErrorInfo, showErrorMessage } from "helpers/error.handling.helper";

export type scrollType = { items: IPostModel[]; hasMore: boolean; fetchMoreData: () => void; isLoading: boolean };

export default observer(function PeersTopic() {
	const match = useRouteMatch<{ id: string; shareLink: string }>();
	const threadId = Number(match.params.id);
	const shareLink = match.params.shareLink;
	const shareIds: number[] = useMemo(() => shareLink?.split(":").map((item) => Number(item)) ?? [], [shareLink]);

	const { peersStore } = useAppStore();
	const [threadInfo, setThreadInfo] = useState<IThreadModel | null>();
	const history = useHistory();
	const [alertEventBus] = useState(() => createAlertEventBus());
	const [reload, setReload] = useState(true);
	const [errorState, setErrorState] = useState<IErrorInfo>(emptyErrorInfo());
	useEffect(() => {
		if (!isEmpty(shareIds)) peersStore.shareId = last(shareIds) ?? null;
	}, [peersStore, shareIds]);

	useEffect(() => {
		if (threadId) {
			peersStore
				.getThreadById(threadId)
				.then((response) => {
					setThreadInfo(response);
					if (!response.isActive) {
						showErrorMessage(alertEventBus, "This thread has been disabled");
						setTimeout(() => history.push(generatePath(Routes.Peers)), 2000);
					}
				})
				.catch((err: Error) => {
					let errorMessage = err.toString();
					if (isBadRequest(err)) {
						errorMessage = err.response?.data?.data.errorMessage ?? errorMessage;
					}
					setErrorState(makeErrorInfo(errorMessage));
					showErrorMessage(alertEventBus, errorMessage);
				});
		} else {
			history.push(generatePath(Routes.Peers));
		}
	}, [alertEventBus, history, peersStore, threadId]);

	const params = useRef<IQueryParams>({
		skip: 0,
		take: 20,
		filterCriteria: [],
		sortCriteria: [
			{
				order: 1,
				propertyName: "createdUTC",
				direction: SortDirection.Descending,
			},
		],
		queryString: "",
	});

	const getData = useCallback(async () => {
		if (threadId) return await peersStore.getThreadPosts(threadId, params.current);
		return null;
	}, [peersStore, threadId]);

	const scroll: scrollType = useInfiniteScroll<IPostModel>(getData, params, alertEventBus, reload);

	useEffect(() => {
		if (scroll.isLoading) setReload(false);
	}, [scroll.isLoading]);

	const onSave = async (values: FormikValues) => {
		try {
			const data = {
				threadId: threadInfo?.id,
				text: values.description,
				uploadedFiles: values?.uploadedFiles ?? [],
			} as ICreatePostModel;
			await peersStore.addPost(data);

			setReload(true);
		} catch (err) {
			showErrorMessage(alertEventBus, err);
		}
	};

	return (
		<PrivateLayout className={styles.peers_topic}>
			<Alert eventBus={alertEventBus} />
			<CommonHeader
				label=""
				className={styles.peers_topic__header}
				classNameContent={styles.peers_topic__header_content}
			>
				<div className={styles.peers_topic__header_box}>
					<NavLink to={Routes.Peers} className={styles.peers_topic__btn_back_out}>
						<DartLeft className={styles.peers_topic__icon} />
					</NavLink>
				</div>
			</CommonHeader>
			<div className={styles.peers_topic__page}>
				<LoadingIndicator loading={peersStore.isLoading} hideChildrenDuringLoading={true}>
					{threadInfo && !errorState.isError ? (
						<>
							<PeerCard threadInfo={threadInfo} alertEventBus={alertEventBus} />
							<CommentAdd alertEventBus={alertEventBus} onSave={onSave} className={styles.peers_topic__comment_add} />
							<PeersList alertEventBus={alertEventBus} location="peers" shareIds={shareIds} scroll={scroll} />
						</>
					) : (
						<div className={styles.peers_topic__nodata}>{errorState.errorMessage}</div>
					)}
				</LoadingIndicator>
			</div>
		</PrivateLayout>
	);
});
