import PrivateLayout from "layouts/private/private";
import { observer } from "mobx-react";
import { useRouteMatch } from "react-router-dom";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import { useAppStore } from "store";
import Alert, { createAlertEventBus } from "components/base/alert/alert";
import { useState } from "react";
import Button from "components/base/button/button";
import styles from "./styles.module.scss";
import { showErrorMessage } from "helpers/error.handling.helper";

const ShortQuizResultsPage = () => {
	const match = useRouteMatch<{ courseId: string }>();
	const courseId = Number(match.params?.courseId ?? 0);
	const [alertEventBus] = useState(() => createAlertEventBus());

	const { quizResultsStore } = useAppStore();

	const handleExportQuizResults = async () => {
		await quizResultsStore.exportShortQuizResults(courseId).catch((err) => {
			showErrorMessage(alertEventBus, err);
		});
	};

	const handleExportCourseCompletion = async () => {
		await quizResultsStore.exportCourseCompletion().catch((err) => {
			showErrorMessage(alertEventBus, err);
		});
	};

	const handleExportZ1Score = async () => {
		await quizResultsStore.exportZ1Score(courseId).catch((err) => {
			showErrorMessage(alertEventBus, err);
		});
	};

	const handleExportScormQuizResults = async () => {
		await quizResultsStore.exportScormQuizResults().catch((err) => {
			showErrorMessage(alertEventBus, err);
		});
	};

	return (
		<PrivateLayout>
			<Alert eventBus={alertEventBus} />
			<div className={styles.short_quiz_results}>
				<Button
					label="Export quiz results"
					onClick={handleExportQuizResults}
					size="small"
					className={styles.short_quiz_results__export_btn}
				/>
				<Button
					label="Export course completion"
					onClick={handleExportCourseCompletion}
					size="small"
					className={styles.short_quiz_results__export_btn}
				/>
				<Button
					label="Export Z1 score"
					onClick={handleExportZ1Score}
					size="small"
					className={styles.short_quiz_results__export_btn}
				/>
				<Button
					label="Export Scorm quiz results"
					onClick={handleExportScormQuizResults}
					size="small"
					className={styles.short_quiz_results__export_btn}
				/>
			</div>
			<LoadingIndicator loading={quizResultsStore.isLoading}></LoadingIndicator>
		</PrivateLayout>
	);
};

export default observer(ShortQuizResultsPage);
