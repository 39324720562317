import { useAppStore } from "store";
import styles from "../../styles.module.scss";
import classNames from "classnames";
import { observer } from "mobx-react";

import MyLearningFlow from "./my-learning-flow";
import { useCallback, useRef } from "react";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { ILearningPathItem } from "models/dto/ZoomiLxp/Models/Analytics/ILearningPathItem";
import { initialCarouselPagingParams } from "helpers/params.helper";
import { useRouteUserId } from "hooks/useRouteUserId";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";

const MyLearningPath = () => {
	const { analyticsStore, commonStore } = useAppStore();
	const { routeUserId } = useRouteUserId();
	const params = useRef<IPagingParams>(initialCarouselPagingParams);
	const getData = useCallback(async () => {
		return await analyticsStore.getMyLearningPathData(params.current, routeUserId);
	}, [analyticsStore, routeUserId]);
	const { items, hasMore, fetchMoreData } = useInfiniteScroll<ILearningPathItem>(
		getData,
		params,
		commonStore.alertEventBus,
		true,
		undefined,
		true
	);

	if (!items.length) {
		return <></>;
	}

	return (
		<div className={classNames(styles.tile, styles.learning_path)}>
			<div className={styles.analytics_page__title_group}>My Learning path</div>
			<LoadingIndicator loading={analyticsStore.isLoading}>
				<MyLearningFlow pathItems={items} pagingParams={{ onLoadMore: fetchMoreData, hasMore }} />
			</LoadingIndicator>
		</div>
	);
};
export default observer(MyLearningPath);
