import { forIn, isNil, range } from "lodash";
import { IAccountModel } from "models/dto/ZoomiLxp/Models/Authentication/IAccountModel";
import { IAuthorModel } from "models/dto/ZoomiLxp/Models/Common/IAuthorModel";
import { IBaseModel } from "models/dto/ZoomiLxp/Models/Common/IBaseModel";
import { IContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/IContentModel";
import { ICategoryModel } from "models/dto/ZoomiLxp/Models/Taxonomy/ICategoryModel";
import { ISubjectModel } from "models/dto/ZoomiLxp/Models/Taxonomy/ISubjectModel";
import { ICompanyModel } from "models/dto/ZoomiLxp/Models/CompanyModels/ICompanyModel";
import { IRoleModel } from "models/dto/ZoomiLxp/Models/Profile/IRoleModel";
import { B2BPurchaseStatuses } from "models/dto/ZoomiLxp/Utilities/Enumerations/B2BPurchaseStatuses";
import { IProfileDataModel } from "models/dto/ZoomiLxp/Models/Profile/IProfileDataModel";
import { ITagModel } from "models/dto/ZoomiLxp/Models/Common/ITagModel";
import { IChapterModel } from "models/dto/ZoomiLxp/Models/Courses/IChapterModel";
import { IChapterRef } from "models/dto/ZoomiLxp/Models/Courses/IChapterRef";
import { IEmailAddressNotificationModel } from "models/dto/ZoomiLxp/Models/Notifications/IEmailAddressNotificationModel";
import isEmpty from "lodash/isEmpty";
import { EmailAddressModelType } from "models/dto/ZoomiLxp/Utilities/Enumerations/EmailAddressModelType";
import { StylesConfig } from "react-select";
import { BulkOperationStatus } from "models/dto/ZoomiLxp/Utilities/Enumerations/BulkOperationStatus";
import { ITenantModel } from "models/dto/ZoomiLxp/Models/Tenants/ITenantModel";
import { IPreviewCourseVersionModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseVersionModel";
import { DateTime } from "luxon";
import { getTimezoneOffset } from "./time.helper";
import { IMotifMeterItem } from "models/dto/ZoomiLxp/Models/Analytics/IMotifMeterItem";
import { ShowRightAnswersOptions } from "models/dto/ZoomiLxp/Utilities/Enumerations/ShowRightAnswersOptions";
import { ValidationBehavior } from "models/dto/ZoomiLxp/Utilities/Enumerations/ValidationBehavior";

export interface IOptionsItem {
	value: number | string;
	label: string;
	isCreatedNow?: boolean;
}

export interface ICategorySubjectItem {
	value: number | string;
	label: string;
	categoryColor?: string;
}

export const emptyOptionItem: IOptionsItem = {
	value: "0",
	label: "",
	isCreatedNow: false,
};

export const activeStateOptions: IOptionsItem[] = [
	{ value: 1, label: "Active" },
	{ value: 2, label: "Inactive" },
];

export const b2bInvoiceOptions: IOptionsItem[] = [
	{ value: B2BPurchaseStatuses.New, label: B2BPurchaseStatuses[B2BPurchaseStatuses.New] },
	{ value: B2BPurchaseStatuses.InvoiceCreated, label: B2BPurchaseStatuses[B2BPurchaseStatuses.InvoiceCreated] },
	{ value: B2BPurchaseStatuses.Paid, label: B2BPurchaseStatuses[B2BPurchaseStatuses.Paid] },
];

export const ActivityStatusOptions: IOptionsItem[] = [
	{ value: BulkOperationStatus.Completed, label: BulkOperationStatus[BulkOperationStatus.Completed] },
	{ value: BulkOperationStatus.Failed, label: BulkOperationStatus[BulkOperationStatus.Failed] },
	{ value: BulkOperationStatus.Pending, label: BulkOperationStatus[BulkOperationStatus.Pending] },
	{ value: BulkOperationStatus.Running, label: BulkOperationStatus[BulkOperationStatus.Running] },
];

export const getValidationBehaviorOptions = (): IOptionsItem[] => {
	const behaviorOption: IOptionsItem[] = [];
	forIn(ValidationBehavior, (value, key) => {
		if (isNaN(Number(key))) {
			behaviorOption.push({ label: key, value });
		}
	});
	return behaviorOption;
};

export const getValidationBehaviorOption = (providedValue: number): string => {
	let behaviorOption: string = "";
	forIn(ValidationBehavior, (value, key) => {
		if (isNaN(Number(key)) && value === providedValue) {
			behaviorOption = key;
		}
	});
	return behaviorOption;
};

export const getBaseModelOptions = (items?: IBaseModel[] | null) => toOptions(fromBaseModel, items);

export const getB2BInvoiceOptions = (isUnassigned?: boolean) => {
	return b2bInvoiceOptions.map((status: IOptionsItem) => ({
		value: status.value,
		label: `${status.label} ${isUnassigned ? "(Unassigned)" : ""} `,
	}));
};

export const fromAccount = (accountModel: IAuthorModel | IAccountModel | IProfileDataModel): IOptionsItem => ({
	value: accountModel.id,
	label: accountModel.displayName,
});

export const fromString = (str: string) => ({
	value: str,
	label: str,
});

export const getCategoriesWithSubjects = (categories?: ICategoryModel[]) => {
	const mappedArray: ICategorySubjectItem[] = [];
	if (categories) {
		for (const category of categories) {
			for (const subject of category.subjects) {
				mappedArray.push({
					value: subject.id,
					label: `${category.name} | ${subject.name}`,
					categoryColor: category.color,
				});
			}
		}
	}

	return mappedArray;
};

export const getSubjectChipStyles = (isMain: boolean, additionalColor: string) => {
	const secondaryChipsStyle: React.CSSProperties = {
		background: "transparent",
		color: additionalColor,
		border: `2px solid ${additionalColor}`,
	};
	const mainChipStyle: React.CSSProperties = {
		background: additionalColor,
	};

	const iconColor = isMain ? "" : additionalColor;
	const style = { chipStyle: isMain ? mainChipStyle : secondaryChipsStyle, iconColor };
	return style;
};

export const getAuthorsOptions = (authors?: (IAuthorModel | IAccountModel | IProfileDataModel)[]) =>
	toOptions(fromAccount, authors);
export const getCategoryOptions = (categories?: ICategoryModel[]) => toOptions(fromBaseModel, categories);
export const getSubjectOptions = (subjects?: ISubjectModel[]) => toOptions(fromBaseModel, subjects);
export const getMotifsOptions = (motifs?: IMotifMeterItem[]) => toOptions(fromBaseModel, motifs);
export const getTenantsOption = (tenants?: ITenantModel[]) => toOptions(fromTenantModel, tenants);
export const getTagsOptions = (tags?: ITagModel[]) => toOptions(fromBaseModel, tags);
export const getCourseVersionsOptions = (versions?: IPreviewCourseVersionModel[]) =>
	toOptions(fromVersionModel, versions);
export const getStringOptions = (stringArray?: string[]) => {
	if (stringArray?.length || !isNil(stringArray)) {
		return stringArray?.map((option: string, index) => ({ value: index, label: option }));
	}
	return [];
};

export const getJobTitles = (stringArray?: string[]) => toOptions(fromString, stringArray);
export const getSources = (stringArray?: string[]) => toOptions(fromString, stringArray);
export const getAreaOfExpertises = (items: IBaseModel[]) => toOptions(fromBaseModel, items);

export const getContentOptions = (contents?: IContentModel[]) => {
	if (contents?.length || !isNil(contents)) {
		return contents?.map((option: IContentModel) => ({
			value: option?.id,
			label: `${option?.name} / ${option?.course?.source ?? "N/A"} / ${option?.course?.creator?.displayName}`,
		}));
	}
	return [];
};

export const fromBaseModel = (baseModel: IBaseModel): IOptionsItem => ({
	value: baseModel.id,
	label: baseModel.name,
});

export const fromMotifModel = (motif: IMotifMeterItem): IOptionsItem => ({
	value: motif.id,
	label: motif.name,
});

export const fromTenantModel = (tenant: ITenantModel): IOptionsItem => ({
	value: tenant.id,
	label: tenant.url,
});

export const fromCompanyModel = (company: ICompanyModel): IOptionsItem => ({
	value: company.id,
	label: company.name,
});

export const fromRoleModel = (role: IRoleModel): IOptionsItem => ({
	value: role.id,
	label: role.displayName,
});

export const fromProfileModel = (profile: IProfileDataModel): IOptionsItem => ({
	value: profile.id,
	label: profile.displayName,
});

export const fromChapterModel = (chapter: IChapterModel): IOptionsItem => ({
	value: chapter.id,
	label: chapter.name,
});

export const fromChapterRef = (chapterRef: IChapterRef): IOptionsItem => ({
	value: chapterRef.id,
	label: chapterRef.name,
});

export const getVersionTitle = (version: IPreviewCourseVersionModel, isHideAuthor?: boolean): string => {
	const formattedDate = DateTime.fromISO(version?.createdUtc)
		.setLocale("en")
		.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
	if (isNil(version) || isEmpty(version)) {
		return "-";
	}
	return `v.${formattedDate} ${getTimezoneOffset(true)} ${isHideAuthor ? "" : `- ${version.author?.displayName}`} `;
};

export const fromVersionModel = (versionModel: IPreviewCourseVersionModel): IOptionsItem => ({
	value: versionModel.versionId,
	label: getVersionTitle(versionModel),
});

export const fromNumberOption = (n: number): IOptionsItem => ({
	value: n,
	label: String(n),
});

export const toOptions = <T>(mapper: (t: T) => IOptionsItem, entities?: T[] | null): IOptionsItem[] => {
	if (!isNil(entities)) return entities.map(mapper);
	return [];
};

export const getCompanyOptions = (companies?: ICompanyModel[]): IOptionsItem[] =>
	toOptions(fromCompanyModel, companies);
export const getRolesOptions = (roles?: IRoleModel[]): IOptionsItem[] => toOptions(fromRoleModel, roles);
export const getUsersOptions = (users?: IProfileDataModel[]): IOptionsItem[] => toOptions(fromProfileModel, users);
export const fromChapterModels = (chapters?: IChapterModel[] | null): IOptionsItem[] =>
	chapters?.map(fromChapterModel) ?? [];

export const optionsPageSize: IOptionsItem[] = toOptions(fromNumberOption, [10, 30, 50]);
export const optionsPages: IOptionsItem[] = toOptions(fromNumberOption, range(1, 5));

const optionLabels: { [key in ShowRightAnswersOptions]: string } = {
	[ShowRightAnswersOptions.NeverShow]: "Highlight never",
	[ShowRightAnswersOptions.ShowWrongSelected]: "Highlight wrong selected (red)",
	[ShowRightAnswersOptions.ShowCorrectAndWrongIfNoAttempts]:
		"Highlight correct (green) and wrong (red) if no attempts left",
};

export const getRightAnswerOptionLabel = (opt: ShowRightAnswersOptions | string): string => {
	if (opt in ShowRightAnswersOptions) {
		return optionLabels[opt as ShowRightAnswersOptions];
	}
	return "";
};

export const showQuizRightAnswersOptions: IOptionsItem[] = Object.keys(ShowRightAnswersOptions)
	.filter((key) => isNaN(Number(key)))
	.map((_, idx) => ({
		label: getRightAnswerOptionLabel(idx),
		value: idx,
	}));

export const stylesSelect: StylesConfig<IOptionsItem> = {
	control: (styles) => ({
		...styles,
		width: "100%",
		background: "var(--input-background)",
		borderRadius: "15px",
		border: "none",
		cursor: "pointer",
		boxShadow: "none",
		display: "flex",
		justifyContent: "space-between",

		"@media screen and (max-width: 767px)": {
			width: "100%",
		},
	}),
	placeholder: (styles) => ({
		...styles,
		color: "var(--text-input-primary)",
		paddingLeft: "8px",
		fontSize: "16px",
		fontWeight: 500,
	}),
	indicatorSeparator: (styles) => ({
		...styles,
		display: "none",
	}),
	indicatorsContainer: (styles) => ({
		...styles,
		cursor: "pointer",
		svg: {
			color: "var(--text-input-primary)",
		},
	}),
	option: (styles, { isSelected }) => ({
		...styles,
		cursor: "pointer",
		fontWeight: 500,
		fontSize: "16px",
		padding: "8px 16px",
		color: isSelected ? "var(--text-dropdown-hover)" : "black",
		backgroundColor: isSelected ? "#7e7e7e" : "#FFF",
		borderRadius: "4px",
		position: "relative",
		":active": {
			...styles[":active"],
			backgroundColor: isSelected ? "#7e7e7e" : "var(--background-dropdown-hover)",
		},
		":hover": {
			...styles[":hover"],
			backgroundColor: isSelected ? "#7e7e7e" : "var(--background-dropdown-hover)",
			color: "var(--text-dropdown-hover)",
		},

		"@media screen and (max-width: 767px)": {
			backgroundColor: "#FFF",
			borderRadius: "2px",
			borderBottom: "solid 1px #7e7e7e",
			padding: "4px",
		},
	}),
	menu: (styles) => ({
		...styles,
		left: "10px",
		borderRadius: "2px",
		border: "1px solid",
		boxShadow: "none",
		top: "100%",
		backgroundColor: "var(--input-background)",
		marginBottom: "8px",
		marginTop: "4px",
		position: "absolute",
		width: "fit-content",
		maxWidth: "300px",
		minWidth: "100%",
		zIndex: "1",
		boxSizing: "border-box",

		"@media screen and (max-width: 767px)": {
			left: "10px",
			border: "solid 1px #000",
			backgroundColor: "#FFF",
			borderRadius: "5px",
			maxWidth: "300px",
			width: "100%",
		},
	}),
	valueContainer: (styles) => ({
		...styles,
		padding: 0,
		width: "fit-content",
		maxWidth: "300px",
	}),
	singleValue: (styles) => ({
		...styles,
		color: "var(--text-input-primary)",
		fontWeight: 500,
		paddingLeft: "8px",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		fontSize: "16px",
	}),
	menuList: (styles) => ({
		...styles,
		paddingTop: 0,
		paddingBottom: 0,
	}),
	input: (styles) => ({
		...styles,
		paddingLeft: "5px",
		color: "var(--text-input-primary)",
	}),
};

export const stylesTableSelect: StylesConfig<IOptionsItem> = {
	control: (styles) => ({
		...styles,
		background: "transparent",
		border: "none",
		cursor: "pointer",
		boxShadow: "none",
		minHeight: "auto",
		"@media screen and (max-width: 767px)": {
			background: "var(--input-background)",
		},
	}),
	indicatorSeparator: (styles) => ({
		...styles,
		display: "none",
	}),
	dropdownIndicator: (base, state) => ({
		...base,
		transition: "all .2s ease",
		transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "none",
	}),
	indicatorsContainer: (styles, state) => ({
		...styles,
		cursor: "pointer",
		svg: {
			color: "var(--text-input-primary)",
		},
		"> div": {
			padding: 0,
		},
	}),
	option: (styles, { isSelected }) => ({
		...styles,
		cursor: "pointer",
		fontWeight: 500,
		fontSize: "14px",
		padding: "5px 16px",
		color: "var(--text-input-primary)",
		backgroundColor: "var(--input-background)",
		borderRadius: "4px",
		position: "relative",
		textAlign: "center",
		borderBottom: "1px solid var(--text-input-primary)",
		":hover": {
			...styles[":hover"],
			backgroundColor: "var(--background-dropdown-hover)",
			color: "var(--text-dropdown-hover)",
		},
	}),
	menu: (styles) => ({
		...styles,
		borderRadius: "4px",
		border: "none",
		boxShadow: "none",
		bottom: "100%",
		backgroundColor: "var(--input-background)",
		marginBottom: 0,
		marginTop: 0,
		position: "absolute",
		width: "auto",
		zIndex: "1",
		boxSizing: "border-box",
		top: "unset",
		color: "var(--$text-input-primary)",
	}),
	singleValue: (styles, state) => ({
		...styles,
		fontWeight: 500,
		paddingLeft: "8px",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		fontSize: "14px",
		color: "var(--$text-input-primary)",
	}),
	menuList: (styles) => ({
		...styles,
		paddingTop: 0,
		paddingBottom: 0,
		"::-webkit-scrollbar": {
			width: "4px",
		},
		"::-webkit-scrollbar-thumb": {
			borderRadius: "4px",
			backgroundColor: "var(--input-background)",
		},
		"::-webkit-scrollbar-track": {
			webkitBoxShadow: "inset 0 0 4px rgba(0, 0, 0, 0.7)",
			borderRadius: "4px",
		},
	}),
	valueContainer: (styles) => ({
		...styles,
		padding: 0,
	}),
};

export const searchStyleSheet: StylesConfig<IOptionsItem> = {
	control: (styles) => ({
		...styles,
		width: "100%",
		background: "var(--input-background)",
		borderRadius: "110px",
		border: "none",
		cursor: "pointer",
		boxShadow: "none",
		display: "flex",
		justifyContent: "space-between",

		"@media screen and (max-width: 767px)": {
			width: "100%",
		},
	}),
	placeholder: (styles) => ({
		...styles,
		color: "var(--text-input-primary)",
		paddingLeft: "8px",
		fontSize: "16px",
		fontWeight: 500,
		"@media screen and (max-width: 767px)": {
			fontSize: "13px",
		},
	}),
	indicatorSeparator: (styles) => ({
		...styles,
		display: "none",
	}),
	indicatorsContainer: (styles) => ({
		...styles,
		float: "right",
		cursor: "pointer",
		svg: {
			color: "var(--text-input-primary)",
		},
	}),
	option: (styles, { isSelected }) => ({
		...styles,
		cursor: "pointer",
		fontWeight: 500,
		fontSize: "16px",
		padding: "8px",
		color: "var(--text-input-primary)",
		display: "flex",
		backgroundColor: "var(--input-background)",
		position: "relative",
		":active": {
			...styles[":active"],
			backgroundColor: isSelected ? "#7e7e7e" : "#B6B6B6",
		},
		":hover": {
			...styles[":hover"],
			backgroundColor: isSelected ? "#7e7e7e" : "#B6B6B6",
			color: "var(--text-tiles)",
		},
		"@media screen and (max-width: 767px)": {
			fontSize: "13px",
			padding: "6px 4px",
		},
	}),
	menu: (styles) => ({
		...styles,
		left: "10px",
		borderRadius: "8px",
		border: "none",
		boxShadow: "none",
		top: "100%",
		backgroundColor: "var(--input-background)",
		marginBottom: "8px",
		marginTop: "4px",
		position: "absolute",
		width: "fit-content",
		maxWidth: "300px",
		minWidth: "100%",
		zIndex: "11",
		boxSizing: "border-box",

		"@media screen and (max-width: 767px)": {
			left: "0",
			border: "solid 1px #000",
			borderRadius: "5px",
			maxWidth: "300px",
			width: "30vw",
		},
	}),
	container: (styles) => ({
		...styles,
		borderRadius: "110px",
	}),
	valueContainer: (styles) => ({
		...styles,
		paddingLeft: "10%",
		width: "100%",
		maxWidth: "300px",
		color: "var(--text-input-primary)",
		"@media screen and (max-width: 767px)": {
			fontSize: "13px",
		},
	}),
	menuList: (styles) => ({
		...styles,
		paddingTop: 0,
		paddingBottom: 0,
		borderRadius: "8px",
		overflowX: "hidden",
		"::-webkit-scrollbar": {
			width: "4px",
		},
		"::-webkit-scrollbar-thumb": {
			borderRadius: "4px",
			backgroundColor: "#A3A4AB",
		},
		"::-webkit-scrollbar-track": {
			webkitBoxShadow: "inset 0 0 4px rgba(0, 0, 0, 0.7)",
			borderRadius: "4px",
		},
	}),
	input: (styles, state) => ({
		...styles,
		fontWeight: 500,
		fontSize: "16px",
		color: "var(--text-input-primary)",
		paddingLeft: "26%",
		borderRadius: "8px",
		outline: "none",
		display: state.selectProps.menuIsOpen ? "flex" : "none",
	}),
};

const fromEmailAddressNotification = (item: IEmailAddressNotificationModel): IOptionsItem => {
	const label = isEmpty(item.value) ? "No data" : item.value;
	if (item.type === EmailAddressModelType.Email)
		return {
			label,
			value: item.value + "|" + EmailAddressModelType.Email,
		};
	else
		return {
			label,
			value: item.substitutionId + "|" + item.type,
		};
};
export const getRecipientsOptions = (items: IEmailAddressNotificationModel[]): IOptionsItem[] =>
	toOptions(fromEmailAddressNotification, items);
