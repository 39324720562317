import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { columns } from "./data";
import { usePagination, useTable } from "react-table";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import Alert, { createAlertEventBus } from "components/base/alert/alert";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { useAsyncDataTable } from "hooks/useAsyncDataTable";
import { AxiosResponse } from "axios";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";
import FilterMenu from "components/partial/filters/filter-menu/filter-menu";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import CmsTable from "components/partial/cms/cms-table/cms-table";
import CmsFooter from "components/partial/cms/cms-footer/cms-footer";
import { IB2CPurchaseModel } from "models/dto/ZoomiLxp/Models/Billing/IB2CPurchaseModel";
import { useAppStore } from "store";
import { BillingApi } from "api/controllers/BillingApi";
import Button from "components/base/button/button";
import SelectFilter from "components/partial/filters/select-filter/select-filter";
import { getStringOptions } from "helpers/select.helper";
import DateFilter from "components/partial/filters/date-filter/date-filter";
import CmsB2CTileSale from "components/partial/cms/cms-sales/cms-tile-sale/cms-b2c-tile-sale";
import { showErrorMessage } from "helpers/error.handling.helper";
import { useRouteUserId } from "hooks/useRouteUserId";

const ProfilePurchases = () => {
	const { routeUserId: otherUserId } = useRouteUserId();
	const tableColumns = React.useMemo(() => columns, []);
	const [alertEventBus] = useState(() => createAlertEventBus());
	const [data, setData] = React.useState<IB2CPurchaseModel[]>([]);
	const [pageCount, setPageCount] = React.useState<number>(0);
	const { billingStore } = useAppStore();

	const table = useTable<IB2CPurchaseModel>(
		{
			columns: tableColumns,
			data,
			initialState: { pageIndex: 0 },
			manualPagination: true,
			pageCount,
		},
		usePagination
	);

	const initParams: IQueryParams = {
		take: table.state.pageSize,
		skip: 0,
		filterCriteria: [],
		sortCriteria: [],
		queryString: "",
	};
	const [params, setParams] = React.useState<IQueryParams>(initParams);

	const handleExport = () => {
		billingStore.exportB2CPurchases(params).catch((err) => showErrorMessage(alertEventBus, err));
	};

	const fetchData = async (data: IQueryParams) =>
		otherUserId ? await BillingApi.getUserB2CPurchases(otherUserId, data) : await BillingApi.getB2CPurchases(data);

	const getResult = (
		result: AxiosResponse<IResponseModel<IGetRecordsResponse<IB2CPurchaseModel>>>
	): IGetRecordsResponse<IB2CPurchaseModel> => result.data.data;

	const { loading } = useAsyncDataTable<
		IB2CPurchaseModel,
		AxiosResponse<IResponseModel<IGetRecordsResponse<IB2CPurchaseModel>>>
	>(setData, params, setPageCount, alertEventBus, fetchData, getResult);

	const getKey = (item: IB2CPurchaseModel) => item.id;

	useEffect(() => {
		billingStore.getB2CStatuses().catch((err) => showErrorMessage(alertEventBus, err));
	}, [alertEventBus, billingStore]);

	return (
		<div className={styles.profile_purchases}>
			<Alert eventBus={alertEventBus} />
			<div className={styles.profile_purchases__header}>
				<div className={styles.profile_purchases__title}>Purchases</div>
				<div className={styles.profile_purchases__filters_group}>
					<FilterMenu params={params} setParams={setParams} initParams={initParams} menuClassName={styles.filter_menu}>
						<SelectFilter
							params={params}
							setParams={setParams}
							label="Status"
							options={getStringOptions(billingStore.b2cStatuses) ?? []}
							propertyName="Status"
							isStringOptions={true}
						/>
						<DateFilter params={params} setParams={setParams} label="Purchase date" propertyName="Created" />
					</FilterMenu>
					<Button label="Export" onClick={handleExport} className={styles.profile_purchases__export_button} />
				</div>
			</div>
			<div className={styles.profile_purchases__body}>
				<LoadingIndicator loading={loading}>
					<CmsTable
						table={table}
						data={data}
						tileComponent={CmsB2CTileSale}
						getKey={getKey}
						className={styles.profile_purchases__table}
					/>
					<CmsFooter table={table} loading={false} setParams={setParams} />
				</LoadingIndicator>
			</div>
		</div>
	);
};

export default ProfilePurchases;
