import { AlertEventTypes } from "components/base/alert/alert";
import { modalDialogService } from "components/base/modal-dialog/modal-dialog";
import { ConfirmationModal } from "components/partial/confirmation-popup/confirmation-popup";
import { getBulkOperationsMessages } from "constants/messages";
import { getBulkPopupForm, getLabel } from "helpers/bulk-modal.helper";
import { showErrorMessage, showSuccessMessage } from "helpers/error.handling.helper";
import { Emitter } from "mitt";
import { BulkOperationType } from "models/dto/ZoomiLxp/Utilities/Enumerations/BulkOperationType";
import { useAppStore } from "store";

export const useBulkOperationsModal = (alertEventBus: Emitter<AlertEventTypes>) => {
	const { searchStore, bulkOperationsStore } = useAppStore();

	const handleConfirmation = async (title: string) => {
		const isSingleClonning: boolean = bulkOperationsStore.checkedState.length === 1;

		const getSuccessMessage = () => {
			if (bulkOperationsStore.bulkOperationType === BulkOperationType.CourseCloning && isSingleClonning) {
				return getBulkOperationsMessages().oneCourse;
			}
			if (bulkOperationsStore.bulkOperationType === BulkOperationType.UserCloning && isSingleClonning) {
				return getBulkOperationsMessages().oneUser;
			}
			return getBulkOperationsMessages(title).commonBulkOperations;
		};

		try {
			await bulkOperationsStore.createBulkOperation();
			bulkOperationsStore.clearData();
			searchStore.clearSearchResult();
			showSuccessMessage(alertEventBus, getSuccessMessage());
		} catch (err) {
			showErrorMessage(alertEventBus, err);
		}
	};

	const openBulkPopup = async (title: string) => {
		await modalDialogService.show(ConfirmationModal, {
			title,
			confirmationAction: () => handleConfirmation(title),
			confirmationButtonLabel: getLabel(bulkOperationsStore.bulkOperationType),
			children: getBulkPopupForm(bulkOperationsStore.bulkOperationType),
			isWideButtons: true,
			showCloseIcon: true,
		});
		bulkOperationsStore.checkedState = [];
		bulkOperationsStore.assignmentRecipients = [];
	};

	return { openBulkPopup };
};
