import { ISaveAnswerRequest } from "models/dto/ZoomiLxp/Models/Assessments/ISaveAnswerRequest";
import { ITextTheme } from "models/dto/ZoomiLxp/Models/Common/ITextTheme";
import { CSSProperties } from "react";

export const answerRequest: ISaveAnswerRequest = {
	questionId: 0,
	attemptId: 0,
	answerOptionIds: [],
	answerText: "",
	answerNumber: 0,
};

export const getStyle = (theme: ITextTheme): CSSProperties => {
	const getColor = () => {
		if (theme.color && theme.color.length) return theme.color;
		return document.documentElement.style.getPropertyValue("--text-general");
	};
	return {
		color: getColor(),
		fontWeight: theme.bold ? "700" : "400",
		fontStyle: theme.italic ? "italic" : "normal",
		fontSize: theme.size ? theme.size : "14px",
		textDecoration: theme.underline ? "underline" : "none",
	};
};

export enum Slide {
	First,
	Middle,
	Last,
}
