import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Alert, { createAlertEventBus } from "components/base/alert/alert";
import { DEFAULT_COLOR } from "constants/colors";
import { getFormattedProgress, getThreadTitle } from "helpers/string.helper";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { useAppStore } from "store";
import StarRating from "components/partial/star-rating/star-rating";
import { generatePath, NavLink } from "react-router-dom";
import { Routes } from "routes";
import { usePlayerNav } from "hooks/usePlayerNav";
import PlayerControl, { ControlParams } from "../player-control/player-control";
import isNull from "lodash/isNull";
import { usePlayerControl } from "hooks/usePlayerControl";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";
import { IThreadRefModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadRefModel";
import { IPlayerPropsBase } from "../data/data";

interface IPlayerThreadBoxProps extends IPlayerPropsBase {
	thread: IThreadRefModel | undefined;
}

const cx = classNames.bind(styles);

const PlayerThreadBox = (props: IPlayerThreadBoxProps) => {
	const { thread, className, onEventHandle } = props;
	const [alertEventBus] = useState(() => createAlertEventBus());
	const { contentStore, coursesStore } = useAppStore();
	const { prev, next } = usePlayerNav();

	const onRating = async (rating: number) => {
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.ThreadRated,
			payload: JSON.stringify({ rating: rating, threadId: thread?.id }),
		};
		await onEventHandle(data);
	};

	const handleNext = () => next();

	const handlePrev = () => prev();

	const handleClick = () => {
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Complete,
			payload: "",
		};
		onEventHandle(data).then(() => {
			coursesStore.curActiveContent.completed = true;
			coursesStore.updateActiveCourseItemToCompleted();
		});	};

	const initParams: ControlParams = {
		currentProgress: 1,
		totalProgress: 1,
		boxRef: useRef<HTMLDivElement>(null),
		getFormattedProgress,
		muted: true,
		playing: false,
		isDownload: false,
		handleNext,
		handlePrev,
		isPrev: false,
		isNext: false,
	};

	const [params, setParams] = React.useState<ControlParams>(initParams);

	usePlayerControl(setParams);

	const sendStart = useCallback(() => {
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: contentStore?.sessionId ?? 0,
			eventType: EventTypes.Start,
			payload: "",
		};
		contentStore.sendEventCourseItemHistory(data);
	}, [contentStore]);

	useEffect(() => {
		if (contentStore.sessionId) {
			sendStart();
		}
	}, [contentStore.sessionId, sendStart]);

	if (!thread) return <></>;

	return (
		<div className={cx(styles.player_thread_box, className)} ref={params.boxRef}>
			<Alert eventBus={alertEventBus} />
			{!isNull(thread?.thumbnail) && (
				<img src={thread?.thumbnail.url} alt="thumbnail" className={styles.player_thread_box__img} />
			)}
			<div className={styles.player_thread_box__wrap}>
				<div
					className={styles.player_thread_box__color_bar}
					style={{ backgroundColor: thread?.category?.color ?? DEFAULT_COLOR }}
				/>
				<div className={styles.player_thread_box__body}>
					<div className={styles.player_thread_box__title}>{getThreadTitle(thread)}</div>
					<div className={styles.player_thread_box__desc}>
						<div className={styles.player_thread_box__desc_text}>{thread?.description}</div>
					</div>
					<div className={styles.player_thread_box__delimiter}></div>
				</div>
				<div className={styles.player_thread_box__footer}>
					<StarRating className={styles.player_thread_box__rating} value={thread?.rating} onRatingChange={onRating} />
					<NavLink
						to={generatePath(Routes.PeersThread, {
							id: thread.id,
						})}
						target="_blank"
						className={styles.player_thread_box__btn}
						onClick={handleClick}
					>
						Go to thread
					</NavLink>
				</div>
			</div>
			<PlayerControl params={params} />
		</div>
	);
};

export default PlayerThreadBox;
