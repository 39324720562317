import classNames from "classnames";
import Button from "components/base/button/button";
import styles from "../../styles.module.scss";
import { ICourseModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseModel";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { Routes } from "routes";
import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { CourseAssignButton, CourseBuyButton } from "../course-card-buy-button/course-card-buttons";
import { PermissionConstantsCreate } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsCreate";
import { useAppStore } from "store";
import * as qs from "querystringify";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { ICourseAssignmentModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseAssignmentModel";
import { showErrorMessage } from "helpers/error.handling.helper";
import StarRating from "components/partial/star-rating/star-rating";
import { getIsFreeCourse } from "../../course-card";

interface IRatingBlockProps {
	courseInfo: ICourseModel;
	isLanding: boolean;
	activeItems: number;
	isLight?: boolean;
	disableRating?: boolean;
}
const MAX_VIEWS: number = 999;

const RatingBlock = ({ courseInfo, disableRating, activeItems, isLanding, isLight }: IRatingBlockProps) => {
	const location = useLocation();
	const history = useHistory();
	const { usersStore, coursesStore, billingStore, contentStore, commonStore } = useAppStore();
	const [rating, setRating] = useState<number>(0);
	const [userRating, setUserRating] = useState<number>(0);
	const [isAssignRequested, setAssignRequested] = useState(false);
	const price: number = courseInfo.price! / 100;
	const accountId: number = usersStore.currentUserInfo?.id ?? 0;
	const isRequestAssigned = usersStore.checkOneOfUserPermissions([PermissionConstantsCreate.RequestAssignments]);
	const isFreeCourse = getIsFreeCourse(courseInfo);
	const numberOfRates =
		courseInfo.ratingCount > MAX_VIEWS
			? Number(courseInfo.ratingCount / 1000).toFixed(0) + "K"
			: courseInfo.ratingCount;

	const buyButtonData = useMemo(() => {
		const buyButtonParams = () => {
			if (coursesStore.currentCourse.isAssigned) return { title: "", isPrimaryColor: false, showPrice: false };
			if (courseInfo.isPaid) {
				return { title: "Bought", isPrimaryColor: false, showPrice: false };
			}
			return { title: "Buy", isPrimaryColor: true, showPrice: true };
		};
		return buyButtonParams();
	}, [courseInfo.isPaid, coursesStore.currentCourse.isAssigned]);

	const assignButtonData = useMemo(() => {
		const assignButtonParams = () => {
			if (coursesStore.currentCourse.isAssigned || !isRequestAssigned) return false;
			if (coursesStore.currentCourse.isPaid || isAssignRequested) {
				return { title: "Requested", isPrimaryColor: false };
			} else {
				return { title: "Assign to me", isPrimaryColor: true };
			}
		};
		return assignButtonParams();
	}, [isRequestAssigned, coursesStore.currentCourse.isAssigned, coursesStore.currentCourse.isPaid, isAssignRequested]);

	useEffect(() => {
		if (courseInfo.rating) {
			setRating(courseInfo.rating);
		}
		if (courseInfo.userRating) {
			setUserRating(courseInfo.userRating);
		}
	}, [courseInfo.rating, courseInfo?.userRating]);

	useEffect(() => {
		if (!isLanding && courseInfo.id) {
			if (isRequestAssigned) {
				coursesStore.getIsCourseRequested(courseInfo.id).then((isRequested: boolean) => {
					setAssignRequested(isRequested);
				});
			}
		}
	}, [coursesStore, usersStore, courseInfo.id, accountId, isLanding, isRequestAssigned]);

	const handleBuy = () => {
		billingStore.createCheckoutSession(courseInfo.id).then((checkoutUrl: string) => window.open(checkoutUrl, "_blank"));
	};

	const handleRate = async (rating: number) => {
		setRating(rating);
		setUserRating(rating);
		await coursesStore.sendCourseEvent({
			courseId: courseInfo.id,
			eventType: EventTypes.CourseRated,
			payload: JSON.stringify({ rating: rating }),
		});
	};

	const handleAssignRequest = () => {
		coursesStore
			.requestAssignCourse({
				accountId,
				courseId: courseInfo.id,
				isAssigned: isAssignRequested,
			})
			.then((assignResponse: ICourseAssignmentModel) => {
				setAssignRequested(assignResponse.isRequested);
			})
			.catch((err: unknown) => showErrorMessage(commonStore.alertEventBus, err));
	};

	const generateCoursePath = (): string => {
		let path = generatePath(Routes.MyDeskLesson, { courseId: courseInfo.id });
		const searchObj = qs.parse(location.search) as any;
		if (searchObj && searchObj.ref) {
			path += qs.stringify(
				{
					ref: searchObj.ref,
					refCourseId: searchObj.refCourseId,
					refCourseItemId: searchObj.refCourseItemId,
				},
				true
			);
		}
		return path;
	};

	const goToCourse = () => {
		contentStore.sessionId = undefined;
		const path = generateCoursePath();
		history.push(path);
	};

	const getStartCourseBtnLabel = () => {
		if (!courseInfo.isActive) return "Course is inactive";
		return activeItems > 1 ? "Go to course" : "Go to content";
	};

	const startBtnLabel = getStartCourseBtnLabel();

	return (
		<div className={styles.course_card__price_and_rating}>
			<div className={classNames(styles.course_card__rating_block, styles.course_card__digits_info_container)}>
				<span className={styles.course_card__rating_digits_section}>
					<b className={styles.course_card__rating}>{courseInfo.rating?.toFixed(1)}</b>
					<p className={styles.course_card__views}>{numberOfRates}</p>
				</span>
				<div className={styles.course_card__star_rating_block}>
					<StarRating
						value={rating}
						isDisabled={disableRating}
						onRatingChange={async (newRating) => {
							await handleRate(newRating);
						}}
						darkBorder={isLight}
						className={styles.course_card__stars}
					/>
					{userRating > 0 ? (
						<p>
							Your rating is {userRating} Star{userRating > 1 && "s"}
						</p>
					) : (
						<p>Rate this course</p>
					)}
				</div>
			</div>
			{!isFreeCourse ? (
				<div className={classNames(styles.course_card__price_block, styles.course_card__digits_info_container)}>
					{buyButtonData?.showPrice && (
						<div className={styles.course_card__price}>${courseInfo.price! % 100 === 0 ? price : price.toFixed(2)}</div>
					)}
					{isLanding ? (
						<>
							{!coursesStore.isErrorCourse && (
								<Button
									label={startBtnLabel}
									onClick={goToCourse}
									className={styles.course_card__start_button}
									disabled={!courseInfo.isActive}
								/>
							)}
						</>
					) : (
						<div>
							<CourseBuyButton buyButtonData={buyButtonData} handleBuy={handleBuy} />
							<CourseAssignButton assignButtonData={assignButtonData} handleAssignRequest={handleAssignRequest} />
						</div>
					)}
				</div>
			) : (
				isLanding && (
					<Button
						label={startBtnLabel}
						onClick={goToCourse}
						size="small"
						className={styles.course_card__start_button}
					/>
				)
			)}
		</div>
	);
};

export default observer(RatingBlock);
