import classNames from "classnames";
import CommonHeader from "components/partial/common-header/common-header";
import { getMyAccountItems, getOtherUserMenuItems } from "components/partial/submenu/items";
import SubMenu from "components/partial/submenu/submenu";
import { useRouteUserId } from "hooks/useRouteUserId";
import { IPreviewProfileModel } from "models/dto/ZoomiLxp/Models/Profile/IPreviewProfileModel";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { ReactNode, useEffect, useState } from "react";
import { matchPath, useHistory } from "react-router-dom";
import { Routes } from "routes";
import { useAppStore } from "store";
import PrivateLayout from "../private";
import styles from "./styles.module.scss";
import { PermissionConstantsRead } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsRead";

interface IProfileLayoutProps {
	children: ReactNode;
	headerLabel?: string;
	onBackButtonClick?: () => void;
}

const ProfileLayout = (props: IProfileLayoutProps) => {
	const { children, headerLabel, onBackButtonClick } = props;
	const { settingsStore, usersStore } = useAppStore();
	const [userPreview, setUserPreview] = useState<IPreviewProfileModel | null>(usersStore.profilePreviewData);
	const history = useHistory();
	const { routeUserId } = useRouteUserId();
	const isCurrentUserProfile = !!matchPath(history.location.pathname, Routes.Profile);
	const isOtherUsersInfoAllowed = usersStore.checkOneOfUserPermissions([
		PermissionConstantsRead.OtherAllUsersAdditionalInformation,
		PermissionConstantsRead.OtherOwnCompanyUsersAdditionalInformation,
	]);
	const showSubHeader = !isNaN(routeUserId) && !isCurrentUserProfile && isOtherUsersInfoAllowed;

	useEffect(() => {
		if (routeUserId && !isCurrentUserProfile) {
			usersStore.getProfilePreviewByUserId(Number(routeUserId)).then((previewData) => setUserPreview(previewData));
		}
	}, [usersStore, routeUserId, isCurrentUserProfile]);

	const getHeaderLabel = () => {
		if (headerLabel) {
			return headerLabel;
		}
		return !isCurrentUserProfile ? "CMS" : "My Account";
	};

	const ProfileSubHeader = () => {
		return (
			<div className={styles.profile_layout__subheader}>
				<div className={styles.profile_layout__caption}>{userPreview && userPreview.displayName}</div>
				<SubMenu className={styles.profile_layout__submenu} menuItems={getOtherUserMenuItems(routeUserId)} exact />
			</div>
		);
	};
	return (
		<PrivateLayout>
			<div className={styles.profile_layout}>
				<div className={styles.profile_layout__scroll}>
					<div className={styles.profile_layout__body}>
						<CommonHeader
							label={getHeaderLabel()}
							className={classNames(styles.profile_layout__header, {
								[styles.marginless]: showSubHeader,
							})}
							classNameContent={styles.profile_layout__header_content}
							classNameLabel={styles.profile_layout__header_label}
							showLogoutButton={isCurrentUserProfile}
							showBackButton={!isCurrentUserProfile}
							onBackButtonClick={onBackButtonClick}
						>
							{isCurrentUserProfile ? (
								<SubMenu
									className={styles.profile_layout__submenu}
									menuItems={getMyAccountItems(
										settingsStore.publicSettings.Pages?.[SiteSettingsKeys.MyDataTabQuizResultsTabVisible],
										isOtherUsersInfoAllowed
									)}
								/>
							) : (
								<SubMenu />
							)}
						</CommonHeader>
						{showSubHeader && <ProfileSubHeader />}
						{children}
					</div>
				</div>
			</div>
		</PrivateLayout>
	);
};

export default ProfileLayout;
