import { useCallback, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { QuestionTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/QuestionTypes";
import { infoMessages } from "constants/messages";

export const useSchema = (questionType: number) => {
	const [schema, setSchema] = useState({});
	const oldQuestionType = useRef(-1);

	const QuizSchemaAnswerOptionIds = useCallback(() => {
		return Yup.object().shape({
			answerOptionIds: Yup.array().min(1, "You need to choose at least one answer option"),
		});
	}, []);

	const QuizSchemaAnswerNumber = useCallback(() => {
		return Yup.object().shape({
			answerNumber: Yup.number()
				.required(infoMessages.requiredField)
				.test("check Number", "The field can have no more than one number after the dot", (value) => {
					if (!value) return false;
					return Number(value) === Number((Math.round(value * 10) / 10).toFixed(1));
				}),
		});
	}, []);

	const QuizSchemaAnswerText = useCallback(() => {
		return Yup.object().shape({
			answerText: Yup.string()
				.required(infoMessages.requiredField)
				.max(10000, "The text length must be less than 10000 symbols."),
		});
	}, []);

	const QuizSchemaAnswerRating = useCallback(() => {
		return Yup.object().shape({
			answerNumber: Yup.number().min(1, "Please set at least minimum value"),
		});
	}, []);

	useEffect(() => {
		if (!questionType) return;
		if (oldQuestionType.current === questionType) return;
		oldQuestionType.current = questionType;

		if (
			questionType === QuestionTypes.Choice ||
			questionType === QuestionTypes.MultiChoice ||
			questionType === QuestionTypes.Survey
		) {
			setSchema(QuizSchemaAnswerOptionIds);
		} else if (questionType === QuestionTypes.Number || questionType === QuestionTypes.NumberWithCheck) {
			setSchema(QuizSchemaAnswerNumber);
		} else if (questionType === QuestionTypes.Text) {
			setSchema(QuizSchemaAnswerText);
		} else if (questionType === QuestionTypes.Rating) {
			setSchema(QuizSchemaAnswerRating);
		}
	}, [QuizSchemaAnswerNumber, QuizSchemaAnswerOptionIds, QuizSchemaAnswerRating, QuizSchemaAnswerText, questionType]);

	if (!questionType) return;

	return schema;
};
