import styles from "./styles.module.scss";
import { observer } from "mobx-react";
import { useCallback, useRef } from "react";
import { IPagingParams } from "models/dto/ZoomiLxp/Models/Query/IPagingParams";
import { initialCarouselPagingParams } from "helpers/params.helper";
import { IPreviewCourseModel } from "models/dto/ZoomiLxp/Models/Courses/IPreviewCourseModel";
import { IInfluencerDataModel } from "models/dto/ZoomiLxp/Models/Influencer/IInfluencerDataModel";
import { IThreadModel } from "models/dto/ZoomiLxp/Models/Peers/IThreadModel";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { isNil } from "lodash";
import Carousel from "../carousel/carousel";
import CourseTile from "../course-tile/course-tile";
import { ICarouselData } from "store/settings-store/settings-store";
import PeerTile from "../peers/peer-tile/peer-tile";
import InfluencerTile from "../influencer/influencer-tile/influencer-tile";
import { useAppStore } from "store";
import { isCoursesCarousel, isInfluencersCarousel, isPeersCarousel } from "helpers/typechecking.helper";

export interface Props {
	carouselData: ICarouselData;
	step?: number;
}

export type CarouselDataType = IPreviewCourseModel | IInfluencerDataModel | IThreadModel;

const CarouselDataProvider = ({ carouselData, step }: Props) => {
	const params = useRef<IPagingParams>(initialCarouselPagingParams);
	const getData = useCallback(async () => {
		if (carouselData.getData) {
			return await carouselData.getData(params.current);
		}
		return;
	}, [carouselData]);

	const { items, hasMore, fetchMoreData, isLoading } = useInfiniteScroll<CarouselDataType>(
		getData,
		params,
		undefined,
		undefined,
		carouselData.carouselPagingParams
	);

	const { influencersStore } = useAppStore();
	const getIsFollowed = (influencerId: number) =>
		!!influencersStore.myInfluencers.find((i: IInfluencerDataModel) => i.id === influencerId);

	const getCarousel = () => {
		if (!isNil(carouselData.courseItems) && isCoursesCarousel(items)) {
			return (
				<Carousel
					isLoading={isLoading}
					hasMore={hasMore}
					getMoreData={fetchMoreData}
					step={step}
					items={items.map((course: IPreviewCourseModel) => (
						<CourseTile
							courseInfo={course}
							className={styles.carousels_array__lesson_tile}
							disableRating={true}
							tileStyle={carouselData.isDarkTheme ? "light" : "default"}
							key={`${course.id}-${course.isBookmarked}`}
							courseTileKey={carouselData.carouselKey}
						/>
					))}
				/>
			);
		}

		if (!isNil(carouselData.threadsItems) && isPeersCarousel(items)) {
			return (
				<Carousel
					hasMore={hasMore}
					getMoreData={fetchMoreData}
					items={items.map((thread: IThreadModel) => (
						<PeerTile
							threadInfo={thread}
							key={thread.id}
							className={styles.carousels_array__lesson_tile}
							tileStyle={carouselData.isDarkTheme ? "light" : "default"}
						/>
					))}
				/>
			);
		}

		if (!isNil(carouselData.influencersItems) && isInfluencersCarousel(items)) {
			return (
				<Carousel
					hasMore={hasMore}
					getMoreData={fetchMoreData}
					items={items.map((influencer: IInfluencerDataModel) => (
						<InfluencerTile
							influencerInfo={influencer}
							className={styles.carousels_array__lesson_tile}
							key={`${influencer.id}-${getIsFollowed(influencer.id)}`}
							tileStyle={carouselData.isDarkTheme ? "light" : "default"}
						/>
					))}
				/>
			);
		}

		return <></>;
	};

	return !carouselData ? <></> : getCarousel();
};

export default observer(CarouselDataProvider);
