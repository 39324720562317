import styles from "./styles.module.scss";
import classNames from "classnames";
import { IPostModel } from "models/dto/ZoomiLxp/Models/Peers/IPostModel";
import isEmpty from "lodash/isEmpty";
import Avatar from "components/base/avatar/avatar";
import { getAvatarTitle, getUserFullName } from "helpers/string.helper";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as ShareIcon } from "assets/icons/ic_share.svg";
import IconCorner from "components/partial/icons/icon-corner/icon-corner";
import Button from "components/base/button/button";
import IconExpand from "components/partial/icons/icon-expand/icon-expand";
import { ReactComponent as CollapseIcon } from "assets/icons/ic_collapse.svg";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import { ISaveEventPostHistoryRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventPostHistoryRequestModel";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { ReactComponent as EditIcon } from "assets/icons/ic_edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/ic_trash.svg";
import CommentAdd from "components/partial/simple-comments/components/comment-add/comment-add";
import { Emitter } from "mitt";
import { AlertEventTypes } from "components/base/alert/alert";
import { FormikValues } from "formik";
import { IReplyPostModel } from "models/dto/ZoomiLxp/Models/Peers/IReplyPostModel";
import { getAttachmentPicture, getIsAttachmentPicture, getMediumImage, getThumbnail } from "helpers/image.helper";
import { IUpdatePostModel } from "models/dto/ZoomiLxp/Models/Peers/IUpdatePostModel";
import { generatePath } from "react-router-dom";
import { Routes } from "routes";
import { copyTextToClipboard } from "helpers/clipboard.helper";
import isNil from "lodash/isNil";
import { modalDialogService } from "components/base/modal-dialog/modal-dialog";
import { ConfirmationModal } from "components/partial/confirmation-popup/confirmation-popup";
import ButtonLike from "components/partial/button-like/button-like";
import { DELAY_TIME } from "constants/constants";
import { infoMessages } from "constants/messages";
import { showErrorMessage, showSuccessMessage } from "helpers/error.handling.helper";
import { PermissionConstantsRead } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsRead";
import { useShowLinksInText } from "hooks/useShowLinksInText";

interface PeerAnswerProps {
	post: IPostModel;
	color?: string;
	level: number;
	alertEventBus: Emitter<AlertEventTypes>;
	className?: string;
	type?: "secondary" | "compact" | "plain_secondary";
	sharePath: string;
	location: "my-desk" | "peers";
	shareIds: number[];
	isReply?: boolean;
}

const cx = classNames.bind(styles);

type stateLiked = { active: boolean; count: number };

const MAX_LEVEL = 2;

const PeerAnswer = (props: PeerAnswerProps) => {
	const { post, alertEventBus, className, level, type, sharePath, location, shareIds, isReply } = props;
	const { peersStore, usersStore, coursesStore } = useAppStore();
	const [item, setItem] = useState<IPostModel>(() => post);
	const [liked, setLiked] = useState<stateLiked>({
		active: post.isLiked,
		count: post.likeCount,
	});

	const [isExpand, setIsExpand] = useState(false);
	const [isAdminOrCommentOwner] = useState(
		() =>
			usersStore.checkOneOfUserPermissions([PermissionConstantsRead.AllThreads]) ||
			usersStore.currentUserInfo?.id === post.author?.id
	);
	const [isShowReply, setShowReply] = useState(false);
	const [isShowEditForm, setShowEditForm] = useState(false);

	const [replies, setReplies] = useState<IPostModel[]>([]);
	const [childrenCount, setChildrenCount] = useState(0);
	const { formattedText } = useShowLinksInText(item?.text);
	const isShowReplies = childrenCount > 0 && level < MAX_LEVEL;
	const isShowReplyBtn = level < MAX_LEVEL;
	const refPeerAnswer = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (post && !isEmpty(post)) {
			setItem(post);
			setChildrenCount(post.childrenCount);
		}
	}, [post]);

	const onLike = async () => {
		const active = liked.active;

		let count = liked.count;
		if (active) {
			count--;
			if (count < 0) count = 0;
		} else count++;

		setLiked((prevState) => ({
			active: !prevState.active,
			count,
		}));

		const data = {
			postId: item.id,
			eventType: active ? EventTypes.PostUnliked : EventTypes.PostLiked,
		} as ISaveEventPostHistoryRequestModel;
		await peersStore.saveEventPostHistory(data);
	};

	const onSave = async (values: FormikValues) => {
		try {
			const data = {
				parentPostId: item.id,
				text: values.description,
				uploadedFiles: values?.uploadedFiles ?? [],
			} as IReplyPostModel;
			await peersStore.replyOnPost(data);
			const posts = await peersStore.getReplies(post.id);
			if (posts) {
				setReplies(posts);
				setChildrenCount((prev) => prev + 1);
				setIsExpand(true);
			}
		} catch (err) {
			showErrorMessage(alertEventBus, err);
		}
		setShowReply(false);
	};

	const onChange = async (values: FormikValues) => {
		try {
			const data = {
				text: values.description,
				uploadedFiles: values?.uploadedFiles ?? [],
			} as IUpdatePostModel;
			const response = await peersStore.updatePost(item.id, data);
			if (response) {
				setItem(response);
			}
		} catch (err) {
			showErrorMessage(alertEventBus, err);
		}
		setShowEditForm(false);
	};

	const getAllReplies = useCallback(async () => {
		if (isEmpty(replies)) {
			const posts = await peersStore.getReplies(post.id);
			if (posts) setReplies(posts);
		}
	}, [peersStore, post.id, replies]);

	const onExpand = async () => {
		setIsExpand(!isExpand);
		await getAllReplies();
	};

	const onDelete = async (post: IPostModel) => {
		setShowEditForm(false);
		await modalDialogService.show(ConfirmationModal, {
			popupText: "Are you sure you want to delete this comment?",
			confirmationAction: async () => {
				try {
					await peersStore.deleteReply(post.id);
					const replies = post.parentId
						? await peersStore.getReplies(post.parentId)
						: await peersStore.getReplies(post.id);
					const deletedItem = replies.find((record: IPostModel) => record.id === post.id);
					setItem({
						...deletedItem,
						created: post.created,
						isDeleted: true,
						text: post.text,
					} as IPostModel);
				} catch (err) {
					showErrorMessage(alertEventBus, err);
				}
			},
			confirmationButtonLabel: "Yes, I`m sure",
			cancelButtonLabel: "No",
		});
	};

	const isSecondaryType = type === "secondary" || type === "plain_secondary";
	const isCompactOrSecondaryType = type === "compact" || type === "plain_secondary";

	const getTypeCommentAdd = () => {
		if (type === "compact") return "plain_edit";
		else if (type === "plain_secondary") return "plain_edit";
		else if (type === "secondary") return "edit_secondary";
		else return "edit";
	};

	const getTypeReply = () => {
		if (type === "compact") return "plain_compact";
		else if (type === "plain_secondary") return "plain_secondary";
		else return "simple";
	};

	const getTypePeerAnswer = () => {
		if (type === "compact") return "plain_secondary";
		else if (type === "plain_secondary") return "plain_secondary";
		else return "secondary";
	};

	const onShare = () => {
		const url = window.location.origin;
		const path =
			location === "peers"
				? generatePath(Routes.PeersThreadShareLink, { id: peersStore.thread.id, shareLink: sharePath })
				: generatePath(Routes.MyDeskShareLink, {
						courseId: coursesStore.currentCourse.id,
						courseItemId: Number(coursesStore.curActiveContent.courseItemId),
						shareLink: sharePath,
				  });

		const fullPath = url + path;

		copyTextToClipboard(fullPath).then(() => {
			showSuccessMessage(alertEventBus, infoMessages.linkCopiedToClipboard);
		});
	};

	const existId = (id: number): number[] => {
		if (!!shareIds.length) if (id === shareIds[0]) return shareIds.slice(1);
		return [];
	};

	useEffect(() => {
		if (!!shareIds.length) {
			getAllReplies()
				.then(() => setIsExpand(true))
				.catch((err) => console.log(err));
		}
	}, [getAllReplies, shareIds.length]);

	useEffect(() => {
		if (post.id === peersStore.shareId) {
			setTimeout(() => {
				refPeerAnswer?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
			}, DELAY_TIME);
		}
	}, [peersStore, post.id]);

	return (
		<div
			className={cx(styles.peer_answer__wrap, styles[`peer_answer__wrap__l${level}`], {
				[styles.peer_answer__wrap__secondary]: type === "secondary",
				[styles[`peer_answer__wrap__secondary__l${level}`]]: type === "secondary",
				[styles.peer_answer__wrap__compact]: type === "compact",
				[styles.peer_answer__wrap__plain_secondary]: type === "plain_secondary",
				[styles[`peer_answer__wrap__secondary__plain_l${level}`]]: type === "plain_secondary",
			})}
			ref={refPeerAnswer}
		>
			<div
				className={cx(styles.peer_answer, className, {
					[styles.peer_answer__secondary]: type === "secondary",
					[styles.peer_answer__compact]: type === "compact",
					[styles.peer_answer__plain_secondary]: type === "plain_secondary",
				})}
				style={{ backgroundColor: isReply ? "transparent" : "var(--background-tile)" }}
			>
				<div className={styles.peer_answer__header}>
					{!isNil(item?.author) && !isEmpty(item?.author) && (
						<Avatar
							title={getAvatarTitle(item?.author.firstName ?? "", item?.author.lastName ?? "")}
							className={cx(styles.peer_answer__cell_avatar, {
								[styles.peer_answer__cell_avatar__compact]: isCompactOrSecondaryType,
							})}
							size="little"
							image={!isNil(item?.author.userPicture) ? getThumbnail(item?.author.userPicture) : undefined}
						/>
					)}
					<div
						className={cx(styles.peer_answer__header_group, {
							[styles.peer_answer__header_group__compact]: isCompactOrSecondaryType,
						})}
					>
						{!isEmpty(item?.author) && (
							<div className={styles.peer_answer__fullname}>
								{getUserFullName(item?.author.firstName ?? "", item?.author.lastName ?? "")}
							</div>
						)}
						{!isEmpty(item?.created) && (
							<div
								className={cx(styles.peer_answer__datetime, {
									[styles.peer_answer__datetime__secondary]: isSecondaryType,
								})}
							>
								<time className={styles.peer_answer__date}>
									{DateTime.fromISO(item?.created).setLocale("en").toLocaleString(DateTime.DATETIME_MED)}
								</time>
							</div>
						)}
						{item?.isDeleted && <div>Deleted</div>}
					</div>
				</div>
				<div className={styles.peer_answer__body}>
					{!isShowEditForm ? (
						<>
							{!isEmpty(item?.text) && <div className={styles.peer_answer__text}>{formattedText}</div>}
							{!isNil(item?.uploadedFiles && !isEmpty(item?.uploadedFiles)) && (
								<div className={styles.peer_answer__uploaded_images}>
									{item?.uploadedFiles.map((item) => {
										const thumbnail = getAttachmentPicture(item);
										return getIsAttachmentPicture(item) ? (
											<Avatar
												size="medium"
												className={cx(styles.peer_answer__thumbnail, {
													[styles.peer_answer__thumbnail__compact]: isCompactOrSecondaryType,
												})}
												image={thumbnail}
												title={""}
												noEmptyAvatar={true}
												key={item.id}
												onClick={() => window.open(getMediumImage(item), "_blank", "")}
											/>
										) : (
											<a href={item.url} download key={item.id}>
												<img
													src={thumbnail}
													alt=""
													className={cx(styles.peer_answer__thumbnail, {
														[styles.peer_answer__thumbnail__compact]: isCompactOrSecondaryType,
													})}
												/>
											</a>
										);
									})}
								</div>
							)}
						</>
					) : (
						<CommentAdd
							alertEventBus={alertEventBus}
							onSave={onChange}
							className={styles.peer_answer__comment_edit}
							type={getTypeCommentAdd()}
							item={item}
							onCancel={() => setShowEditForm(false)}
							loading={peersStore.isPostLoading}
						/>
					)}
				</div>
				{!isNil(item?.author) && (
					<div className={styles.peer_answer__footer}>
						{isShowReplyBtn && (
							<Button
								label={"Reply"}
								onClick={() => setShowReply(!isShowReply)}
								className={styles.peer_answer__btn_reply}
							/>
						)}
						<div
							className={cx(styles.peer_answer__group, styles.peer_answer__group__edit, {
								[styles.peer_answer__hide]: !isAdminOrCommentOwner,
							})}
							onClick={() => setShowEditForm(!isShowEditForm)}
						>
							<EditIcon
								className={cx(styles.peer_answer__edit_icon, {
									[styles.peer_answer__edit_icon__secondary]: isSecondaryType,
								})}
							/>
							Edit
						</div>
						{isAdminOrCommentOwner && (
							<div
								className={cx(styles.peer_answer__group, {
									[styles.peer_answer__hide]: !isAdminOrCommentOwner,
									[styles.peer_answer__btn_delete__secondary]: isSecondaryType,
								})}
								onClick={() => onDelete(post)}
							>
								<TrashIcon
									className={cx(styles.peer_answer__delete_icon, {
										[styles.peer_answer__delete_icon__secondary]: isSecondaryType,
									})}
								/>
								Delete
							</div>
						)}
						<div className={styles.peer_answer__group}>
							<ButtonLike
								isLikable={true}
								isActive={liked.active}
								isSecondaryType={isSecondaryType}
								onClick={() => onLike()}
							/>
							{liked.count ?? 0}
						</div>
						<div className={cx(styles.peer_answer__group, styles.peer_answer__group__share)} onClick={() => onShare()}>
							<ShareIcon
								className={cx(styles.peer_answer__share_icon, {
									[styles.peer_answer__share_icon__secondary]: isSecondaryType,
								})}
							/>
							Share
						</div>
					</div>
				)}

				<div
					className={cx(styles.peer_answer__corner, {
						[styles.peer_answer__hide]: isSecondaryType,
					})}
				>
					<IconCorner fill={isReply ? "transparent" : "var(--background-tile)"} />
				</div>
			</div>
			{isShowReply && (
				<CommentAdd
					alertEventBus={alertEventBus}
					onSave={onSave}
					className={cx(styles.peer_answer__comment_add, {
						[styles.peer_answer__comment_add__compact]: type === "compact",
					})}
					type={getTypeReply()}
					onCancel={() => setShowReply(false)}
					loading={peersStore.isPostLoading}
				/>
			)}
			{isShowReplies && (
				<div className={styles.peer_answer__wrapper}>
					<div
						className={cx(styles.peer_answer__expand, {
							[styles.peer_answer__expand__compact]: isCompactOrSecondaryType,
						})}
					>
						<Button
							label=""
							onClick={() => onExpand()}
							type="outlined"
							size="common"
							className={styles.peer_answer__btn_expand}
							icon={
								!isExpand ? (
									<IconExpand
										fill={isReply ? "transparent" : "var(--background-tile)"}
										className={cx(styles.peer_answer__expand_icon, styles.peer_answer__expand_icon__secondary)}
									/>
								) : (
									<CollapseIcon
										className={cx(styles.peer_answer__collapse_icon, styles.peer_answer__collapse_icon__secondary)}
									/>
								)
							}
						/>
						<div className={styles.peer_answer__text_expand}>
							{isExpand ? "Hide" : "Expand"} replies ({childrenCount})
						</div>
					</div>
					{!isEmpty(replies) && (
						<div
							className={cx(styles.peer_answer__replies_list, {
								[styles.peer_answer__replies_list__hide]: !isExpand,
							})}
						>
							{replies.map((reply) => (
								<PeerAnswer
									post={reply}
									level={level + 1}
									alertEventBus={alertEventBus}
									key={reply.id}
									type={getTypePeerAnswer()}
									sharePath={[sharePath, String(reply.id)].join(":")}
									location={location}
									shareIds={existId(reply.id)}
									isReply={true}
								/>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default observer(PeerAnswer);
