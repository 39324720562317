import { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "routes";
import styles from "./styles.module.scss";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import FilterMenu from "components/partial/filters/filter-menu/filter-menu";
import SelectFilter from "components/partial/filters/select-filter/select-filter";
import StatusFilter from "components/partial/filters/status-filter/status-filter";
import DateFilter from "components/partial/filters/date-filter/date-filter";
import SearchFilter from "components/partial/search-filter/search-filter";
import { IOptionsItem, getRolesOptions } from "helpers/select.helper";
import { useAppStore } from "store";
import UsersActionsSubmenu from "../users-actions-submenu/users-actions-submenu";
import { ReactComponent as IconAdd } from "assets/icons/ic_plus.svg";
import { getInitParams } from "helpers/filter.helper";
import BulkOperationsMenu from "components/partial/bulk-operations-menu/bulk-operations-menu";
import { SortDirection } from "models/dto/ZoomiLxp/Utilities/Enumerations/SortDirection";
import Alert from "components/base/alert/alert";
import { useSearchStore } from "hooks/useSearchStore";
import { SearchStateConstants } from "constants/constants";

export function CmsUsersFilter() {
	const history = useHistory();
	const { accountStore, commonStore } = useAppStore();
	const [rolesOptions, setRolesOptions] = useState<IOptionsItem[]>([]);

	const initParams: IQueryParams = {
		...getInitParams(),
		sortCriteria: [
			{
				order: 1,
				propertyName: "firstName",
				direction: SortDirection.Ascending,
			},
			{
				order: 2,
				propertyName: "lastName",
				direction: SortDirection.Ascending,
			},
		],
	};

	const { setParams } = useSearchStore(initParams, SearchStateConstants.users);

	useEffect(() => {
		accountStore.getRoles().then((response) => setRolesOptions(getRolesOptions(response)));
	}, [accountStore]);

	return (
		<>
			<Alert eventBus={commonStore.alertEventBus} />
			<div className={styles.cms_users__header}>
				<div className={styles.cms_users__title}>Users</div>
				<BulkOperationsMenu menuType="users" className={styles.cms_users__bulk} />
				<div className={styles.cms_users__filters_group}>
					<FilterMenu params={accountStore.usersSearchParams} setParams={setParams} initParams={initParams}>
						<SelectFilter
							params={accountStore.usersSearchParams}
							setParams={setParams}
							label="Role"
							options={rolesOptions ?? []}
							propertyName="RoleLinks.RoleId"
							useInFilterFunction={true}
						/>
						<StatusFilter params={accountStore.usersSearchParams} setParams={setParams} />
						<DateFilter
							params={accountStore.usersSearchParams}
							setParams={setParams}
							propertyName="createdUtc"
							label="Registration date"
						/>
					</FilterMenu>
					<SearchFilter
						params={accountStore.usersSearchParams}
						setParams={setParams}
						alertEventBus={commonStore.alertEventBus}
						classNameInput={styles.cms_users__search}
					/>
				</div>
				<UsersActionsSubmenu className={styles.cms_users__btn_add} alertEventBus={commonStore.alertEventBus} />
				<button
					className={styles.cms_users__btn_add_mobile}
					onClick={() => history.push(generatePath(Routes.CmsAddUser))}
				>
					<IconAdd className={styles.cms_users__icon} />
				</button>
			</div>
		</>
	);
}
