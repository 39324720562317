import styles from "./styles.module.scss";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Emitter } from "mitt";
import { AlertEventTypes } from "components/base/alert/alert";
import PeerAnswer from "../peer-answer/peer-answer";
import isEmpty from "lodash/isEmpty";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import { scrollType } from "pages/private/peers/peers-topic/peers-topic";
import InfiniteScroll from "react-infinite-scroll-component";

interface PeersListProps {
	alertEventBus: Emitter<AlertEventTypes>;
	type?: "compact";
	location: "my-desk" | "peers";
	className?: string;
	shareIds: number[];
	scroll: scrollType;
}

const PeersList = ({ alertEventBus, className, type, location, shareIds, scroll }: PeersListProps) => {
	const { items, hasMore, fetchMoreData } = scroll;
	const { peersStore } = useAppStore();

	if (isEmpty(items)) return <div className={styles.peers_list__nocomments}>No comments</div>;

	const existId = (id: number): number[] => {
		if (!!shareIds.length) if (id === shareIds[0]) return shareIds.slice(1);
		return [];
	};

	return (
		<LoadingIndicator loading={peersStore.isLoading} className={styles.comments_list__loading}>
			<div
				className={classNames(styles.peers_list, className, {
					[styles.peers_list__compact]: type === "compact",
				})}
				style={{
					maxHeight:
						type === "compact"
							? `calc(${peersStore.parentContainerHeight}px - ${peersStore.chartContainerHeight}px)`
							: "fit-content",
				}}
				id="scrollableDiv"
			>
				{!isEmpty(items) && (
					<InfiniteScroll
						dataLength={items.length}
						next={fetchMoreData}
						hasMore={hasMore}
						loader={<h4>Loading...</h4>}
						scrollableTarget="scrollableDiv"
					>
						{items.map((item, index) => (
							<PeerAnswer
								key={item.id}
								post={item}
								level={0}
								alertEventBus={alertEventBus}
								type={type === "compact" ? "compact" : undefined}
								sharePath={String(item.id)}
								location={location}
								shareIds={existId(item.id)}
							/>
						))}
					</InfiniteScroll>
				)}
			</div>
		</LoadingIndicator>
	);
};

export default observer(PeersList);
