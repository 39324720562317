import { useEffect } from "react";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { useAppStore } from "store";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";

export const useSendEventsBatch = (disableInterval?: boolean) => {
	const { settingsStore, contentStore } = useAppStore();
	const pagesSettings = settingsStore.publicSettings.Pages;
	const batchSize: number = Number(pagesSettings?.[SiteSettingsKeys.MyDeskSendEventsBatchSize]);
	const sendInterval: number = Number(pagesSettings?.[SiteSettingsKeys.MyDeskSendEventsInterval]) * 1000;

	const eventsBatchHandler = (event: ISaveEventCourseItemHistoryModel) =>
		new Promise<void>((resolve, reject) => {
			const eventTime = Date.now();
			const updatedEvent = { event, eventTime };
			contentStore.eventsBatch = [...contentStore.eventsBatch, updatedEvent];

			if (
				contentStore.eventsBatch.length >= batchSize ||
				event.eventType === EventTypes.End ||
				event.eventType === EventTypes.Complete
			) {
				const currentTimestamp = Date.now();
				contentStore.sendCourseItemEventsBatch(
					contentStore.eventsBatch.map(item => { 
						return {...item.event, millisecondsAgo: currentTimestamp - item.eventTime};
					})
				).catch((err) => reject(err));
				contentStore.eventsBatch = [];
			}
			resolve();
		});

	useEffect(() => {
		if (contentStore.sessionId && !disableInterval) {
			contentStore.startBatchInterval(sendInterval);
		}
	}, [contentStore, contentStore.sessionId, sendInterval, disableInterval]);

	useEffect(
		() => {
			return () => {
				contentStore.stopBatchInterval();
			};
		}, //eslint-disable-next-line
		[]
	);

	return { eventsBatchHandler };
};
