import { observer } from "mobx-react";
import PrivateLayout from "layouts/private/private";
import styles from "./styles.module.scss";
import { useAppStore } from "store";
import { useEffect, useState } from "react";
import Alert, { createAlertEventBus } from "components/base/alert/alert";
import { getOrderedCarousels } from "helpers/settings.helper";
import CarouselsArray from "components/partial/carousels-array/carousels-array";
import { showErrorMessage } from "helpers/error.handling.helper";
import Banner from "components/base/banner/banner";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";

export const HomePage = observer(function HomePage() {
	const { usersStore, coursesStore, settingsStore } = useAppStore();
	const [alertEventBus] = useState(() => createAlertEventBus());
	const sortedCarousels = getOrderedCarousels(settingsStore.publicSettings, "Home.Carousels.Order");
	const userId = usersStore.currentUserInfo!.id;

	useEffect(() => {
		coursesStore.getAllCoursesByGroups(userId).catch((err) => showErrorMessage(alertEventBus, err));
		return coursesStore.clearStore();
	}, [alertEventBus, coursesStore, usersStore, userId]);

	return (
		<PrivateLayout>
			<div className={styles.homepage}>
				<Alert eventBus={alertEventBus} className={styles.homepage__alert} />
				<Banner
					className={styles.home_banner}
					bannerContentType={settingsStore.publicSettings.Images?.[SiteSettingsKeys.HomeBannerContentType]}
					bannerSrc={settingsStore.publicSettings.Images?.[SiteSettingsKeys.HomeBanner]}
				/>
				<div className={styles.homepage__content}>
					<CarouselsArray carouselsKeys={sortedCarousels} />
				</div>
			</div>
		</PrivateLayout>
	);
});
