import { useMemo, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { Routes } from "routes";
import { NavLink } from "react-router-dom";
import { useDetectOutsideClick } from "hooks/useDetectOutsideClick";
import { observer } from "mobx-react";
import { mobileCheck } from "helpers/device.helper";
import { ReactComponent as IconAdd } from "assets/icons/ic_plus.svg";
import { useAppStore } from "store";
import UploadTarget from "components/base/upload-target/upload-target";
import { Emitter } from "mitt";
import { AlertEventTypes } from "components/base/alert/alert";
import { UploadFileTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/UploadFileTypes";
import { IImportUsersResult } from "models/dto/ZoomiLxp/Models/Authentication/IImportUsersResult";
import { ReactComponent as SpinnerIcon } from "assets/icons/ic_spinner.svg";
import { errorMessages } from "constants/messages";
import { acceptedMimeTypes } from "constants/constants";
import { showErrorMessage, showSuccessMessage } from "helpers/error.handling.helper";

interface Props {
	className?: string;
	alertEventBus: Emitter<AlertEventTypes>;
}

const UsersActionsSubmenu = (props: Props) => {
	const { className, alertEventBus } = props;
	const { usersStore } = useAppStore();
	const dropdownRef = useRef<HTMLElement>(null);
	const { isActive, setIsActive } = useDetectOutsideClick(dropdownRef, false);
	const openSubmenu = () => setIsActive(!isActive);
	const isMobile = useMemo(() => mobileCheck(), []);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	return (
		<div className={classNames(className)}>
			<div
				className={classNames(styles.users_actions_submenu, styles.users_actions_submenu__mobile)}
				onClick={openSubmenu}
			>
				{isMobile ? (
					<IconAdd className={styles.users_actions_submenu__icon} />
				) : isLoading ? (
					<div className={styles.spinner__spinner_box}>
						<SpinnerIcon className={styles.spinner__loading_spinner} />
					</div>
				) : (
					"Actions"
				)}
				<nav
					ref={dropdownRef}
					className={classNames(
						styles.users_actions_submenu__submenu,
						isActive ? styles.users_actions_submenu__submenu_active : styles.users_actions_submenu__submenu_inactive
					)}
				>
					<ul className={styles.users_actions_submenu__submenu__list}>
						<li className={styles.users_actions_submenu__submenu__item}>
							<NavLink className={styles.users_actions_submenu__submenu__item__btn} to={Routes.CmsAddUser}>
								Add user
							</NavLink>
						</li>
						<li className={styles.users_actions_submenu__submenu__item}>
							<div
								className={classNames(styles.users_actions_submenu__submenu__item__btn, {
									[styles.users_actions_submenu__disabled]: usersStore.isLoading,
								})}
								onClick={(e) => usersStore.isLoading && e.stopPropagation()}
							>
								<UploadTarget
									accept={acceptedMimeTypes.spreadsheetAndCSV}
									onLoading={() => setIsLoading(true)}
									onSuccess={(res) => {
										usersStore
											.usersBulkUpload(res.data.id.toString())
											.then((result: IImportUsersResult) => {
												if (!result.isValid && result.validationReportUrl) {
													alertEventBus.emit("show", {
														text: `${result.importedAccountsCount}/${result.totalRows} users successfully uploaded. Please make sure that all fields in file are correct.`,
														link: { prefix: "Validation report: ", link: result.validationReportUrl },
													});
												} else {
													showSuccessMessage(
														alertEventBus,
														`${result.importedAccountsCount}/${result.totalRows} users successfully uploaded.`
													);
												}
											})
											.then(() => setIsLoading(false));
									}}
									onError={(err) => {
										console.log(err);
									}}
									maxSize={3}
									onWrongFormat={(type) => {
										showErrorMessage(alertEventBus, errorMessages.unsupportedFileType(type, "a .CSV or Excel-format"));
									}}
									onMaxSizeExceeded={(size, maxSize) => {
										showErrorMessage(alertEventBus, errorMessages.exceedingAllowedFileSize(size, maxSize));
									}}
									fileType={UploadFileTypes.UsersImport}
								>
									Bulk upload
								</UploadTarget>
							</div>
						</li>
						<li className={styles.users_actions_submenu__submenu__item}>
							<div
								className={styles.users_actions_submenu__submenu__item__btn}
								onClick={() => usersStore.downloadBulkTemplate()}
							>
								Download template
							</div>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
};

export default observer(UsersActionsSubmenu);
