import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as FullScreenIcon } from "assets/icons/ic_player_fullscreen.svg";
import { ReactComponent as FullScreenCompressIcon } from "assets/icons/ic_player_fullscreen_compress.svg";
import { ReactComponent as LeftIcon } from "assets/icons/ic_player_left.svg";
import { ReactComponent as RightIcon } from "assets/icons/ic_player_right.svg";
import screenfull from "screenfull";
import { useDebounce } from "@react-hook/debounce";
import { mobileCheck } from "helpers/device.helper";
import debounce from "lodash/debounce";
import { ControlParams } from "../player-control/player-control";
import { toBoolean } from "components/partial/cms/cms-settings/settings-converter";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { useAppStore } from "store";
import Tooltip from "components/base/tooltip/tooltip";

interface PlayerControlProps {
	params: ControlParams;
	className?: string;
}

const cx = classNames.bind(styles);

const DELAY_HIDING_CONTROL = 3000;

function PlayerControlMini(props: PlayerControlProps) {
	const { params, className } = props;
	const { boxRef, handleNext, handlePrev, isPrev, isNext } = params;
	const { settingsStore, coursesStore } = useAppStore();
	const [isFullScreen, setFullScreen] = useDebounce<boolean>(false, 200);
	const [isControlHidden, setIsControlHidden] = useState<boolean>(false);
	const isMobile = useMemo(() => mobileCheck(), []);
	const [disableFullScreen, setDisableFullScreen] = useState(false);
	const isAlwaysShowNavArrows = toBoolean(
		settingsStore.publicSettings.Pages?.[SiteSettingsKeys.MyDeskShowAlwaysPrevNextButtons]
	);
	const navArrowsTooltipPostfix =
		settingsStore.publicSettings.Pages?.[SiteSettingsKeys.MyDeskCourseItemsNameTooltipForArrowButtons];

	const onChangeFullScreen = useCallback(() => {
		if (isFullScreen !== screenfull.isFullscreen) setFullScreen(screenfull.isFullscreen);
	}, [isFullScreen, setFullScreen]);

	useEffect(() => {
		if (!disableFullScreen) {
			try {
				screenfull.on("change", onChangeFullScreen);
				return () => {
					screenfull.off("change", onChangeFullScreen);
				};
			} catch (error) {
				setDisableFullScreen(true);
			}
		}
	}, [disableFullScreen, onChangeFullScreen]);

	const playerElement = document.getElementsByTagName("iframe").item(0);

	useEffect(() => {
		const controlShow = debounce(() => {
			if (isControlHidden) setIsControlHidden(false);
		}, 0);

		const controlHide = debounce(() => {
			if (!isControlHidden) setIsControlHidden(true);
		}, DELAY_HIDING_CONTROL);

		if (playerElement && playerElement.contentDocument) {
			playerElement.addEventListener("load", controlShow);
			playerElement.addEventListener("load", controlHide);
			playerElement.contentDocument.addEventListener("mousemove", controlShow);
			playerElement.contentDocument.addEventListener("mousemove", controlHide);
			playerElement.contentDocument.addEventListener("scroll", controlShow);
			playerElement.contentDocument.addEventListener("scroll", controlHide);
		}

		document.addEventListener("mousemove", controlShow);
		document.addEventListener("mousemove", controlHide);

		return () => {
			controlShow.cancel();
			controlHide.cancel();

			if (playerElement && playerElement.contentDocument) {
				playerElement.removeEventListener("load", controlShow);
				playerElement.removeEventListener("load", controlHide);
				playerElement.contentDocument.removeEventListener("mousemove", controlShow);
				playerElement.contentDocument.removeEventListener("mousemove", controlHide);
				playerElement.contentDocument.removeEventListener("scroll", controlShow);
				playerElement.contentDocument.removeEventListener("scroll", controlHide);
			}

			document.removeEventListener("mousemove", controlShow);
			document.removeEventListener("mousemove", controlHide);
		};
	}, [isControlHidden, playerElement]);

	const handleFullScreen = () => {
		if (screenfull.isEnabled) {
			screenfull.toggle(boxRef.current as Element);
			setFullScreen((state: boolean) => !state);
		}
	};

	return (
		<>
			<div
				className={cx(styles.player_control_mini__btn_box, styles.player_control_mini__btn_box__left, {
					[styles.player_control_mini__btn_box__left_hidden]: isControlHidden && !isAlwaysShowNavArrows,
					[styles.player_control_mini__disabled]: !isPrev,
				})}
			>
				<Tooltip
					label={`Previous ${navArrowsTooltipPostfix}`}
					className={styles.arrow_tooltip__left}
					classNameContent={styles.arrow_tooltip__content}
				>
					<button
						className={cx(styles.player_control_mini__btn, styles.player_control_mini__btn_skip)}
						onClick={(event) => {
							event.preventDefault();
							handlePrev?.(params);
						}}
					>
						<LeftIcon className={styles.player_control_mini__icon_nav} />
					</button>
				</Tooltip>
			</div>
			<div
				className={cx(styles.player_control_mini__btn_box, styles.player_control_mini__btn_box__right, {
					[styles.player_control_mini__btn_box__right_hidden]: isControlHidden && !isAlwaysShowNavArrows,
					[styles.player_control_mini__disabled]: !isNext,
				})}
			>
				<Tooltip
					label={`Next ${navArrowsTooltipPostfix}`}
					className={styles.arrow_tooltip__right}
					classNameContent={styles.arrow_tooltip__content}
				>
					<button
						className={cx(styles.player_control_mini__btn, styles.player_control_mini__btn_skip)}
						onClick={(event) => {
							event.preventDefault();
							coursesStore.updateActiveCourseItemToCompleted();
							handleNext?.(params);
						}}
					>
						<RightIcon className={styles.player_control_mini__icon_nav} />
					</button>
				</Tooltip>
			</div>
			<div
				className={cx(styles.player_control_mini, { [styles.player_control_mini__hidden]: isControlHidden }, className)}
			>
				<div className={styles.player_control_mini__progress_box}>
					<div className={styles.player_control_mini__progress_box_right}>
						{(!disableFullScreen || !isMobile) && (
							<button className={cx(styles.player_control_mini__btn, styles.player_control_mini__btn_fullscreen)}>
								{!isFullScreen ? (
									<FullScreenIcon className={styles.player_control_mini__icon} onClick={handleFullScreen} />
								) : (
									<FullScreenCompressIcon
										className={cx(styles.player_control_mini__icon, styles.player_control_mini__icon_compress)}
										onClick={handleFullScreen}
									/>
								)}
							</button>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default PlayerControlMini;
